/*eslint-disable eqeqeq*/
import React, { Component } from 'react'
import LocationSelectionComponent from '../component/location-selection';
import SocialLocationSelectionComponent from '../component/social-location-selection';
import * as APIController from "../../../api/APIController.js";
import Notification from '../../../shared/components/notification/notification';
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import { Grid } from '@material-ui/core';

import * as AppConstants from "../../../config/AppConstant";

class LocationSelectionController extends Component {
    constructor() {
        super();
        this.state = {
            // categories: category,
            categories: { locations: [] },
            selectedAdd: [],
            data: [],
            progress: true,
            practiceId: '',
            message: '',
            showNotification: false,
            isError: false,
            CampaignBusinessDetails: [],
            CampaignBusinessHours: [],
            OrderDetailsID: AppConstants.GetLocalStorage('localStorageOrderDetailsID'),
            DefaultOpenPanel: false,
            IsLoading: true,
            IsDatafound: true,
            PracticeIDs: [],
            isAutomation: AppConstants.GetLocalStorage('localStorageAutomation'),
            CampaignReach: []
        }
    }

    componentDidMount() {
        let PracticeIDs = [];
        PracticeIDs.push(AppConstants.GetLocalStorage("localStoragepracticeId"))
        this.setState({ PracticeIDs: PracticeIDs })

        this.wsGetLocationDetails(AppConstants.GetLocalStorage('localStorageAutomation'), AppConstants.GetLocalStorage('localStoragepracticeId'), AppConstants.GetLocalStorage('localStorageIsFromHeader'));
    }

    handleNotification = (message, isError) => {
        this.setState({ message: message, showNotification: true, isError: isError });
        setTimeout(() => { this.handleClearNotification(); }, 4000);
    };

    handleClearNotification = () => {
        this.setState({
            message: "",
            showNotification: false,
            isError: false,
        });
    };

    wsGetLocationDetails(isAutomation, PracticeId, IsFromHeader) {
        this.setState({ progress: true });
        APIController.GetLocationDetails(isAutomation, PracticeId, IsFromHeader).then((response) => {
            if (response.error == null && response.object.data.length != 0) {
                this.setState({
                    categories: response.object.data,
                    practiceId: response.object.data.id,
                    progress: false,
                });

                //this code for Default Selection when Only One Location Found
                if (response.object.data.locations.length == 1) {
                    // if (isAutomation == "YES") {
                    //     if (AppConstants.GetLocalStorage("IsDirectPageURL") == "3" || response.object.data.locations[0].IsSubscriptionCreated == 0) {
                    //         this.setState({ DefaultOpenPanel: true, selectedAdd: [] })
                    //         this.handleSelectAddress(response.object.data.locations[0])
                    //     }
                    // } else {
                    this.setState({ DefaultOpenPanel: true, selectedAdd: [] })
                    this.handleSelectAddress(response.object.data.locations[0])
                    // }
                }
                //this code for Default Selection when Edit Locations
                if (PracticeId != '') {
                    response.object.data.locations.map((obj, index) => {
                        if (obj.id == PracticeId) {
                            // this.handleSelectAddress(response.object.data.locations[index])
                        }
                    })
                    this.setState({ OrderDetailsID: AppConstants.GetLocalStorage('localStorageOrderDetailsID') })
                }
                this.setState({ IsLoading: false })
            } else if (response.object.message && response.object.message.length > 0) {
                this.handleNotification(response.object.message, true);
                this.setState({ IsLoading: false, IsDatafound: false })
            }
        });
    }

    SaveIsReviewNeededToLocalStorage = (PracticeID, FacebookAccessToken, FacebookID, FacebookUserName, InstagramID, InstagramUserName) => {
        const AllLocations = JSON.parse(localStorage.getItem("SocialAutomationAllLocations"));
        const AllLocationsWithoutCurrentPractice = AllLocations.filter((e) => e.PracticeID != PracticeID);
        AllLocationsWithoutCurrentPractice.push({
            'PracticeID': PracticeID,
            'FacebookAccessToken': FacebookAccessToken,
            'FacebookID': FacebookID,
            'FacebookUserName': FacebookUserName,
            'InstagramID': InstagramID,
            'InstagramUserName': InstagramUserName
        })
        localStorage.setItem("SocialAutomationAllLocations", JSON.stringify(AllLocationsWithoutCurrentPractice));
    }

    wsUpdateLocation(Params) {
        this.setState({ progress: true, });
        APIController.UpdateLocation(Params)
            .then((response) => {
                if (response.object.status == 1) {
                    this.handleNotification(response.object.message);
                } else {
                    this.handleNotification(response.object.message, true);
                }
                this.wsGetLocationDetails(this.state.isAutomation, AppConstants.GetLocalStorage('localStoragepracticeId'), AppConstants.GetLocalStorage('localStorageIsFromHeader'))
            });
    }

    /**
    * handleSelectAddress method is used to push selected address key and index in selectedAddress
    */
    handleSelectAddress = (values) => {
        const selectedAddress = this.state.selectedAdd;
        selectedAddress.push(values);
        if (this.state.selectedAdd[0].id !== values.id) {
            this.setState({ selectedAdd: selectedAddress })
        }
        this.SetApiData(this.state.selectedAdd)
    }

    SetApiData(data) {
        let CampaignBusinessDetails = [];
        let CampaignReachData = [];
        let CampaignBusinessHours = [];
        let PracticeIDs = [];
        for (var i = 0; i < data.length; i++) {
            PracticeIDs.push(data[i].id)
            CampaignReachData.push(
                {
                    "PracticeID": data[i].id,
                    "Location": data[i].location,
                    "WithinMileRange": data[i].locationDetail.campaignReach.radius,
                    "Postcode": data[i].locationDetail.campaignReach.zipCode
                }
            )
        }
        this.setState({ PracticeIDs: PracticeIDs, CampaignBusinessDetails: CampaignBusinessDetails, CampaignReach: CampaignReachData, CampaignBusinessHours: CampaignBusinessHours })
    }

    /**
     * handleDeSelectAddress method is used to remove selected key and index from state in selected address
     */
    handleDeSelectAddress = (values) => {
        const selectedAddress = this.state.selectedAdd;
        const index = selectedAddress.findIndex(ob => ob.id === values.id);
        selectedAddress.splice(index, 1);
        this.setState({
            selectedAdd: selectedAddress
        })
        this.SetApiData(this.state.selectedAdd)
    }
    SetPracticeId(id) {
        this.setState({ practiceId: id })
    }
    handleUpdateLocationDetail = (obj, key) => {
        let fbData = {}, igData = {}
        if (AppConstants.GetLocalStorage('localStorageFBPageData') != "" && AppConstants.GetLocalStorage('localStorageFBPageData') != undefined && AppConstants.GetLocalStorage('localStorageFBPageData') != null) {
            fbData = JSON.parse(AppConstants.GetLocalStorage('localStorageFBPageData'))
        }
        if (AppConstants.GetLocalStorage('localStorageInstaUserData') != "" && AppConstants.GetLocalStorage('localStorageInstaUserData') != undefined && AppConstants.GetLocalStorage('localStorageInstaUserData') != null) {
            igData = JSON.parse(AppConstants.GetLocalStorage('localStorageInstaUserData'))
        }

        let BusinessHours = [
            {
                "IsMondayClosed": obj.hoursOfOperation.Mon.closed,
                "IsTuesdayClosed": obj.hoursOfOperation.Tue.closed,
                "IsWednesdayClosed": obj.hoursOfOperation.Wed.closed,
                "IsThursdayClosed": obj.hoursOfOperation.Thu.closed,
                "IsFridayClosed": obj.hoursOfOperation.Fri.closed,
                "IsSaturdayClosed": obj.hoursOfOperation.Sat.closed,
                "IsSundayClosed": obj.hoursOfOperation.Sun.closed,
                "MondayStart": obj.hoursOfOperation.Mon.open,
                "MondayEnd": obj.hoursOfOperation.Mon.close,
                "TuesdayStart": obj.hoursOfOperation.Tue.open,
                "TuesdayEnd": obj.hoursOfOperation.Tue.close,
                "WednesdayStart": obj.hoursOfOperation.Wed.open,
                "WednesdayEnd": obj.hoursOfOperation.Wed.close,
                "ThursdayStart": obj.hoursOfOperation.Thu.open,
                "ThursdayEnd": obj.hoursOfOperation.Thu.close,
                "FridayStart": obj.hoursOfOperation.Fri.open,
                "FridayEnd": obj.hoursOfOperation.Fri.close,
                "SaturdayStart": obj.hoursOfOperation.Sat.open,
                "SaturdayEnd": obj.hoursOfOperation.Sat.close,
                "SundayStart": obj.hoursOfOperation.Sun.open,
                "SundayEnd": obj.hoursOfOperation.Sun.close
            }]

        let Params = JSON.stringify(AppConstants.GetLocalStorage('localStoragepracticeId') != 0 ? {

            ShowOnlySubscribedLocations: 1,
            AutomationSubscriptionID: AppConstants.GetLocalStorage("localStorageAutomationSubscriptionID"),

            FacebookUserID: AppConstants.GetLocalStorage('localStorageFacebookUserID'),
            FacebookID: fbData.id,
            Facebookusername: fbData.name,
            FacebookAccess_Token: fbData.access_token,

            InstagramID: igData.id,
            Instagramusername: igData.username,

            PracticeID: this.state.practiceId,
            BusinessName: obj.businessInfo.name,
            Address: obj.businessInfo.address1,
            Address2: obj.businessInfo.address2,
            StateID: "39cb45c4-b7f8-4bbc-9d3f-fe4d09a11a9c",
            CityID: "3a94bf2e-12fd-4d85-b149-f126db25c757",
            PostcodeID: "3a94bf2e-12fd-4d85-b149-f126db25c757",
            Postcode: obj.businessInfo.postCode,
            BusinessPhoneNo: obj.businessInfo.businessPhone,
            MobileNo: obj.businessInfo.phone,
            BusinessEmailAddress: obj.businessInfo.email,
            MarketingEmailAddress: obj.businessInfo.email2,
            BusinessWebsite: obj.businessInfo.website,
            AppointmentURL: obj.businessInfo.appointmenturl,
            FBLink: obj.socialMedia.facebookUrl,
            InstagramLink: obj.socialMedia.instagramUrl,
            SearchPostcode: obj.campaignReach.zipCode,
            SearchRadius: obj.campaignReach.radius,
            SearchSelection: JSON.stringify(obj.campaignReach.selectedLocations),
            EventDate: obj.businessInfo.eventdate,
            EventTime: obj.businessInfo.eventtime,
            RSVPDeadline: obj.businessInfo.rsvpdeadline,
            DoctorName: obj.businessInfo.doctorname,
            BusinessHours: BusinessHours,
            Latitude: obj.businessInfo.latitude,
            Longitude: obj.businessInfo.longitude,
            GoogleAddress: obj.businessInfo.googleaddress,
            Brands: obj.businessInfo.brands
        } : {
            PracticeID: this.state.practiceId,
            BusinessName: obj.businessInfo.name,
            Address: obj.businessInfo.address1,
            Address2: obj.businessInfo.address2,
            StateID: "39cb45c4-b7f8-4bbc-9d3f-fe4d09a11a9c",
            CityID: "3a94bf2e-12fd-4d85-b149-f126db25c757",
            PostcodeID: "3a94bf2e-12fd-4d85-b149-f126db25c757",
            Postcode: obj.businessInfo.postCode,
            BusinessPhoneNo: obj.businessInfo.businessPhone,
            MobileNo: obj.businessInfo.phone,
            BusinessEmailAddress: obj.businessInfo.email,
            MarketingEmailAddress: obj.businessInfo.email2,
            BusinessWebsite: obj.businessInfo.website,
            AppointmentURL: obj.businessInfo.appointmenturl,
            FBLink: obj.socialMedia.facebookUrl,
            InstagramLink: obj.socialMedia.instagramUrl,
            SearchPostcode: obj.campaignReach.zipCode,
            SearchRadius: obj.campaignReach.radius,
            SearchSelection: JSON.stringify(obj.campaignReach.selectedLocations),
            EventDate: obj.businessInfo.eventdate,
            EventTime: obj.businessInfo.eventtime,
            RSVPDeadline: obj.businessInfo.rsvpdeadline,
            DoctorName: obj.businessInfo.doctorname,
            BusinessHours: BusinessHours,
            Latitude: obj.businessInfo.latitude,
            Longitude: obj.businessInfo.longitude,
            GoogleAddress: obj.businessInfo.googleaddress,
            Brands: obj.businessInfo.brands
        });

        this.state.isAutomation == "YES" && Object.keys(fbData).length
            && this.SaveIsReviewNeededToLocalStorage(this.state.practiceId, fbData.access_token, fbData.id, fbData.name, igData.id, igData.username)
        this.wsUpdateLocation(Params)
    }

    render() {
        if (this.state.IsLoading) {
            return <div style={{ marginTop: '10%' }}> <CircularProgress /> </div>
        }
        else if (!this.state.IsDatafound) {
            return <Grid container direction='row' style={{ justifyContent: 'center', alignItems: 'center', marginTop: '10%' }}>
                <h3>Not Currently Available</h3>
            </Grid>
        }
        return (
            <>
                {this.state.isAutomation == "YES"
                    ? <SocialLocationSelectionComponent
                        progress={this.state.progress}
                        category={this.state.categories}
                        practiceId={this.state.practiceId}
                        onSelectAddress={this.handleSelectAddress}
                        selectAddress={this.state.selectedAdd}
                        DefaultOpenPanel={this.state.DefaultOpenPanel}
                        onDeSelectAddress={this.handleDeSelectAddress}
                        SetPracticeId={this.SetPracticeId.bind(this)}
                        budgetDetail={JSON.parse(AppConstants.GetLocalStorage('localStorageBudgetDetail'))}
                        fromCart={AppConstants.GetLocalStorage('localStorageFromCart')}
                        ShowAddToCart={AppConstants.GetLocalStorage('localStorageShowAddToCart')}
                        updateLocationDetails={this.handleUpdateLocationDetail.bind(this)}
                        // pathname: "/digital-product/selection/campaign",
                        nextForm={() => {
                            AppConstants.SetLocalStorage('localStoragepracticeId', this.state.PracticeIDs)

                            if (this.state.selectedAdd[0].IsSubscriptionCreated == 1) {
                                // this.handleNotification("Subscription for the Selected Location is already Created", true);
                            } else {
                            }
                            AppConstants.SetLocalStorage("localStorageCalenderCart", "1")
                            this.props.history.push({ pathname: "/calendar", state: { calendarBack: true } })

                        }}
                        back={() => this.props.history.goBack()}
                    />
                    : <LocationSelectionComponent
                        progress={this.state.progress}
                        category={this.state.categories}
                        practiceId={this.state.practiceId}
                        onSelectAddress={this.handleSelectAddress}
                        selectAddress={this.state.selectedAdd}
                        DefaultOpenPanel={this.state.DefaultOpenPanel}
                        onDeSelectAddress={this.handleDeSelectAddress}
                        SetPracticeId={this.SetPracticeId.bind(this)}
                        budgetDetail={JSON.parse(AppConstants.GetLocalStorage('localStorageBudgetDetail'))}
                        fromCart={AppConstants.GetLocalStorage('localStorageFromCart')}
                        isSocialAutomation={this.state.isAutomation}
                        ShowAddToCart={AppConstants.GetLocalStorage('localStorageShowAddToCart')}
                        updateLocationDetails={this.handleUpdateLocationDetail.bind(this)}
                        // pathname: "/digital-product/selection/campaign",
                        nextForm={() => {
                            AppConstants.SetLocalStorage('localStoragepracticeId', this.state.PracticeIDs)

                            AppConstants.SetLocalStorage('localStorageCampaignReach', JSON.stringify(this.state.CampaignReach))
                            AppConstants.SetLocalStorage('localStorageOrderDetailsID', this.state.OrderDetailsID)
                            AppConstants.SetLocalStorage('localStoragePracticeIDs', JSON.stringify(this.state.PracticeIDs))
                            AppConstants.SetLocalStorage('localStorageFromCart', 1)
                            this.props.history.push("/digital-product/selection/adbreak")

                        }}
                        back={() => this.props.history.goBack()}
                    />}
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    closeNotification={this.handleClearNotification}
                />
            </>
        )

    }
}

export default LocationSelectionController