/*eslint-disable eqeqeq*/
import React from "react";
import { Grid, SvgIcon, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from "@material-ui/core";
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import PageTitle from "../../../config/pageTitle";
import * as AppConsatnt from "../../../config/AppConstant";
import "./location.css";
import LocationValidationComponent from "./location-validation";
import MyLocationValidationComponent from "./mylocation-validation";
import * as AppConstants from "../../../config/AppConstant";

const LocationSelectionComponent = (props) => {
	if (props.scrollTop) {
		window.scrollTo(0, 0);
		props.setScrollTop();
	}
	const [expanded, setExpanded] = React.useState(null);
	const handleChange = (panel) => {
		setExpanded(panel);
	};
	const [Title, setTitle] = React.useState('');

	const [modalNumber, setModalNumber] = React.useState(null);

	React.useEffect(() => {
		//code for Default Select when One(1) Location found
		if (props.DefaultOpenPanel == true) {
			setExpanded('panel1')
			setModalNumber(0)
		}
	}, []);

	const CheckBoxComponent = ({
		locationDetail,
		index,
		onSelectAddress,
		selectedLocations,
		onDeSelectAddress,
		updateLocationDetails,
	}) => {
		return (
			<div className='location-inner-content'>
				<div className='location-name'>
					<div className={'custom-checkbox'} >
						{props.budgetDetail[0].budget != "" && AppConstants.GetLocalStorage('localStorageIsFromHeader') != 1 &&
							<input
								type={'checkbox'}
								name='location'
								id={`${locationDetail.id}`}
								checked={!!selectedLocations.length &&
									selectedLocations.find((ob) => ob.id === locationDetail.id)
								}
								onChange={(e) => {
									if (e.target.checked) {
										onSelectAddress(locationDetail);
									} else {
										onDeSelectAddress(locationDetail);
									}
								}}
							/>
						}
						<label htmlFor={`${locationDetail.id}`} style={props.budgetDetail[0].budget != "" && selectedLocations.find((ob) => ob.id === locationDetail.id) ? { fontWeight: 700, width: '100%' } : { width: '100%' }}>
							{locationDetail.name}, {locationDetail.location}
						</label>
					</div>
				</div>
				{/* <div className='location-info'>
					{props.budgetDetail[0].budget != ""
						&& <p>
							<img src={map_ic} alt='ic' />
							{locationDetail.unit} Radius
						</p>}
				</div> */}

				<div className="theme-option theme-option-location"
					onClick={(e) => {
						e.preventDefault();
						props.SetPracticeId(locationDetail.id)
						setModalNumber(index);
						// handleChange(expanded === `panel${index + 1}` ? null : `panel${index + 1}`);
					}} >
					{locationDetail.needsToReview || Title == 'inValid'
						? <div className="red-link" style={{ display: 'contents' }}>{"Needs Review!:"}&nbsp;</div>
						: <div style={{ display: 'contents' }}>{"Location Details:"}&nbsp;</div>}

					{locationDetail.needsToReview || Title == 'inValid'
						? <div className='location-red-main-toggle' style={{ minWidth: '150px' }}>
							<div className={expanded != null && index == modalNumber ? 'location-red-toggle-active' : 'location-red-toggle-deActive'}
								onClick={(e) => { handleChange(`panel${index + 1}`); }}>Show</div>
							<div className={expanded == null || index != modalNumber ? 'location-red-toggle-active' : 'location-red-toggle-deActive'}
								onClick={(e) => { handleChange(null); }}>Hide</div>
						</div>
						: <div className='calculator-main-tabView' style={{ minWidth: '150px' }}>
							<div className={expanded != null && index == modalNumber ? 'calculator-tabView-active' : 'calculator-tabView-deActive'}
								onClick={(e) => { handleChange(`panel${index + 1}`); }}>Show</div>
							<div className={expanded == null || index != modalNumber ? 'calculator-tabView-active' : 'calculator-tabView-deActive'}
								onClick={(e) => { handleChange(null); }}>Hide</div>
						</div>
					}
					{/* <ReviewComponent
						locationDetail={locationDetail}
						index={index}
					/> */}
				</div>
			</div>
		);
	};

	const ReviewComponent = ({ locationDetail, index }) => {
		if (locationDetail.needsToReview || Title == 'inValid') {
			return <div className="red-link">{"Needs Review!"}<SvgIcon fontSize="large"><ArrowDropDownIcon /></SvgIcon></div>
		} else {
			if (expanded != null && index == modalNumber) {
				return <div>{"Hide Location Details"} <SvgIcon fontSize="large"><ArrowDropUpIcon /></SvgIcon> </div>
			} else {
				return <div>{"View Location Details"} <SvgIcon fontSize="large"><ArrowDropDownIcon /></SvgIcon> </div>
			}
		}
	}

	const handalExpand = () => {
		setExpanded(null)
		setModalNumber(null)
	}

	const handleTitle = (data) => {
		props.category.locations.map((obj, index) => {
			if (modalNumber == index) {
				setTitle(data)
			}
		})
	}

	return (
		<section className='content-right-main'>
			{
				props.isSocialAutomation == "YES" ?
					AppConsatnt.GetLocalStorage("IsDirectPageURL") == "3" ?
						<PageTitle Title={AppConsatnt.ContactDetais.ConfirmLocationTitle} /> :
						<PageTitle Title={AppConsatnt.ContactDetais.SelectLocationTitle} /> :
					props.budgetDetail[0].budget == "" ?
						<PageTitle Title={AppConsatnt.ContactDetais.MyLocationTitle} /> :
						props.ShowAddToCart == 0 || props.budgetDetail[0].duration == "1" ?
							<PageTitle Title={AppConsatnt.ContactDetais.Step2Confirm} /> :
							props.category.locations.length != 1 && props.fromCart != '0' ?
								<PageTitle Title={AppConsatnt.ContactDetais.Step3Select} /> :
								<PageTitle Title={AppConsatnt.ContactDetais.CartLocationTitle} />}

			<div className='location-screen-sec'>
				{/* <div className='accordian_title'>
					<div className='title-left'>
						<h3>{props.category.name}</h3>
					</div>
				</div> */}
				<div className='loc-content-wrap'>
					{props.progress ?
						<div style={{ padding: '3%' }}> <CircularProgress /> </div>
						: <div className='loc-content-main'>
							{props.category.locations != undefined && props.category.locations.map((obj, index) => {
								return (
									<ExpansionPanel
										expanded={expanded === `panel${index + 1}`}
										key={index}>

										<ExpansionPanelSummary>
											<CheckBoxComponent
												locationDetail={obj}
												key={index}
												index={index}
												onSelectAddress={props.onSelectAddress}
												updateLocationDetails={props.updateLocationDetails}
												selectedLocations={props.selectAddress}
												onDeSelectAddress={props.onDeSelectAddress}
											/>
										</ExpansionPanelSummary>
										<ExpansionPanelDetails>
											{modalNumber === index && (
												props.budgetDetail[0].budget == "" && AppConstants.IsGoForLive
													? <MyLocationValidationComponent
														locationDetail={obj.locationDetail}
														islocationverified={obj.islocationverified}
														needsToReview={obj.needsToReview}
														practiceId={props.practiceId}
														budgetDetail={props.budgetDetail}
														cancel={() => handalExpand()}
														handleTitle={handleTitle.bind(this)}
														updateLocationDetails={(values) => {
															props.updateLocationDetails(values, obj.id);
															handleChange(null);
															setModalNumber(null);
														}}
													/>
													: <LocationValidationComponent
														locationDetail={obj.locationDetail}
														tokenvalid={obj.tokenvalid}
														needsToReview={obj.needsToReview}
														practiceId={props.practiceId}
														budgetDetail={props.budgetDetail}
														isSocialAutomation={props.isSocialAutomation}
														cancel={() => handalExpand()}
														handleTitle={handleTitle.bind(this)}
														updateLocationDetails={(values) => {
															props.updateLocationDetails(values, obj.id);
															handleChange(null);
															setModalNumber(null);
														}}
													/>
											)}
										</ExpansionPanelDetails>
									</ExpansionPanel>
								);
							})}
						</div>
					}
				</div>
			</div>

			{props.budgetDetail[0].budget != "" && AppConstants.GetLocalStorage('localStorageIsFromHeader') != 1 &&
				<Grid container flexDirection='row' justify='space-between' style={{ marginTop: '-25px' }}>
					<div className='button-field'>
						<input type='button' name='button' value={AppConsatnt.ButtonText.Back} onClick={props.back} />
					</div>

					{<div className={`button-field ${props.selectAddress.length === 0 || props.selectAddress.find((ob) => ob.needsToReview == 1) ? "disable-btn" : ""}`}>
						<input type='button' name='button' value={AppConsatnt.ButtonText.Continue} onClick={props.nextForm}
							disabled={Title == 'inValid' || props.selectAddress.length === 0 || props.selectAddress.find((ob) => ob.needsToReview === true)}
						/>
					</div>}

				</Grid>}
		</section>
	);
};

export default LocationSelectionComponent;