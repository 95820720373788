import { API, HTTPMethod } from "../api/API";
import { URLs } from "../api/URLs";

export function APIControllerError(code, message) {
	this.code = code;
	this.message = message;
}

export function APIControllerResponse(object, error) {
	this.object = object;
	this.error = error;
}

export function GetAccessToken(grant_type, username, password) {
	let params = JSON.stringify({
		grant_type: grant_type,
		username: username,
		password: password,
	});
	let url = URLs.base + URLs.Gettoken;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.get, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetNav() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetNav;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetFacebookPages(access_token) {
	return new Promise((resolve, reject) => {
		API.shared
			.request1(HTTPMethod.get, 'https://graph.facebook.com/v12.0/me/accounts?access_token=' + access_token)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetInstaAccount(access_token, page_id) {
	return new Promise((resolve, reject) => {
		API.shared
			.request1(HTTPMethod.get, 'https://graph.facebook.com/v13.0/' + page_id + '?fields=instagram_business_account&access_token=' + access_token)
			.then((response) => {
				if (response != null && response.instagram_business_account != null) {
					GetInstaAccountName(access_token, response.instagram_business_account.id).then((response_inner) => {
						resolve(new APIControllerResponse(response_inner, null))
					})
				} else {
					resolve(new APIControllerResponse(null, response))
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetInstaAccountName(access_token, page_id) {
	return new Promise((resolve, reject) => {
		API.shared
			.request1(HTTPMethod.get, 'https://graph.facebook.com/v13.0/' + page_id + '?fields=username&access_token=' + access_token)
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetInstagramAccessToken(client_id, client_secret, grant_type, redirect_uri, code) {
	let params = {
		client_id: client_id,
		client_secret: client_secret,
		grant_type: grant_type,
		redirect_uri: redirect_uri,
		code: code,
	};
	let url = "https://api.instagram.com/oauth/access_token";

	var formBody = [];
	for (var property in params) {
		var encodedKey = encodeURIComponent(property);
		var encodedValue = encodeURIComponent(params[property]);
		formBody.push(encodedKey + "=" + encodedValue);
	}
	formBody = formBody.join("&");

	return new Promise((resolve, reject) => {
		API.shared
			.request2(HTTPMethod.post, url, formBody)
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				resolve(error);
			});
		// jsonp(url , options).then((response) => {
		// 	resolve(new APIControllerResponse(response, null));
		// }).catch((error) => {
		// 	resolve(new APIControllerResponse(null, error));
		// });
	});


}

export function GetInstagramUserData(access_token) {
	return new Promise((resolve, reject) => {
		API.shared
			.request1(HTTPMethod.get, 'https://graph.instagram.com/me?fields=id,username&access_token=' + access_token)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				resolve(error);
			});
	});
}

export function AddEditModule(ID, Name, IsActive) {
	let params = JSON.stringify({
		ID: ID,
		Name: Name,
		IsActive: IsActive,
	});
	let url = URLs.base + URLs.AddEditModule;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteModule(ID) {
	let params = JSON.stringify({
		ID: ID,
	});
	let url = URLs.base + URLs.DeleteModule;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetModuleByID(ID) {
	let params = JSON.stringify({
		ID: ID,
	});

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, "ACS/GetModuleByID", params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetModules(ID, Name, IsActive) {
	let params = JSON.stringify({
		ID: ID,
		Name: Name,
		IsActive: IsActive,
	});
	let url = URLs.base + URLs.GetModules;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetModuleForDropdown() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetModuleForDropdown;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditRight(ID, Name, ShortCode, IsSpecialRight) {
	let params = JSON.stringify({
		ID: ID,
		Name: Name,
		ShortCode: ShortCode,
		IsSpecialRight: IsSpecialRight,
	});
	let url = URLs.base + URLs.AddEditRight;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteRight(ID) {
	let params = JSON.stringify({
		ID: ID,
	});
	let url = URLs.base + URLs.DeleteRight;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetRightByID(ID) {
	let params = JSON.stringify({
		ID: ID,
	});

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, "ACS/GetRightByID", params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetRights(ID, Name, ShortCode, IsSpecialRight) {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetRights;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetRightForDropdown() {
	let params = JSON.stringify({});

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, "ACS/GetRightForDropdown", params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditRole(ID, Name, IsActive) {
	let params = JSON.stringify({
		ID: ID,
		Name: Name,
		IsActive: IsActive,
	});
	let url = URLs.base + URLs.AddEditRole;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteRole(ID) {
	let params = JSON.stringify({
		ID: ID,
	});
	let url = URLs.base + URLs.DeleteRole;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetRoleByID(ID) {
	let params = JSON.stringify({
		ID: ID,
	});

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, "ACS/GetRoleByID", params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetRoles(ID, Name, IsActive) {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetRoles;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetRoleForDropdown(CompanyID) {
	let params = JSON.stringify({
		CompanyID: CompanyID
	});

	let url = URLs.base + URLs.GetRoleForDropdown;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditScreen(ID, ModuleID, Name, URL) {
	let params = JSON.stringify({
		ID: ID,
		ModuleID: ModuleID,
		Name: Name,
		URL: URL,
	});
	let url = URLs.base + URLs.AddEditScreen;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteScreen(ID) {
	let params = JSON.stringify({
		ID: ID,
	});
	let url = URLs.base + URLs.DeleteScreen;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetScreenByID(ID) {
	let params = JSON.stringify({
		ID: ID,
	});

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, "ACS/GetScreenByID", params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetScreens() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetScreens;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditCity(ID, CountryID, StateID, Name, IsActive) {
	let params = JSON.stringify({
		ID: ID,
		CountryID: CountryID,
		StateID: StateID,
		Name: Name,
		IsActive: IsActive,
	});
	let url = URLs.base + URLs.AddEditCity;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteCity(ID) {
	let params = JSON.stringify({
		ID: ID,
	});
	let url = URLs.base + URLs.DeleteCity;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetCityByID(ID) {
	let params = JSON.stringify({
		ID: ID,
	});
	let url = URLs.base + URLs.GetCityByID;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetCities(CurrentPage, RecordPerPage, CountryName, StateName, CityName, IsActive) {
	let params = IsActive == "" || IsActive == undefined ? JSON.stringify({
		"CountryName": CountryName,
		"StateName": StateName,
		"Name": CityName,
		"CurrentPage": CurrentPage,
		"RecordPerPage": RecordPerPage
	}) :
		JSON.stringify({
			"CountryName": CountryName,
			"StateName": StateName,
			"Name": CityName,
			"IsActive": IsActive == "YES" ? 1 : 0,
			"CurrentPage": CurrentPage,
			"RecordPerPage": RecordPerPage
		});
	let url = URLs.base + URLs.Getcity;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetCityForDropdown(StateID) {
	let params = JSON.stringify({
		StateID: StateID,
	});
	let url = URLs.base + URLs.GetcityForDropdown;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditcountry(ID, Name, IsActive) {
	let params = JSON.stringify({
		ID: ID,
		Name: Name,
		IsActive: IsActive,
	});
	let url = URLs.base + URLs.AddEditcountry;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteCountry(ID) {
	let params = JSON.stringify({
		'ID': ID
	})
	let url = URLs.base + URLs.DeleteCountry

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function GetCountryByID(ID) {
	let params = JSON.stringify({
		ID: ID,
	});
	let url = URLs.base + URLs.Getcountrybyid;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetCountries(ID, Name, IsActive) {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.Getcountry;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetCountryForDropdown(ID) {
	let params = JSON.stringify({ ID: ID });
	let url = URLs.base + URLs.GetcountryForDropdown;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditPostcode(ID, CountryID, StateID, CityID, Postcode, IsActive) {
	let params = JSON.stringify({
		ID: ID,
		CountryID: CountryID,
		StateID: StateID,
		CityID: CityID,
		Postcode: Postcode,
		IsActive: IsActive
	});
	let url = URLs.base + URLs.AddEditPostcode;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeletePostcode(ID) {
	let params = JSON.stringify({
		ID: ID,
	});
	let url = URLs.base + URLs.DeletePostcode;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetPostcodeByID(ID) {
	let params = JSON.stringify({
		ID: ID,
	});
	let url = URLs.base + URLs.GetPostcodeByID;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetPostcodes(CurrentPage, RecordPerPage, CountryName, StateName, CityName, Postcode) {
	let params = JSON.stringify({
		"CountryName": CountryName,
		"StateName": StateName,
		"CityName": CityName,
		"Postcode": Postcode,
		"CurrentPage": CurrentPage,
		"RecordPerPage": RecordPerPage,
	});
	let url = URLs.base + URLs.Getpostcodes;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetPostcodeForDropdown(CityID) {
	let params = JSON.stringify({
		CityID: CityID,
	});

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, "Master/GetPostcodeForDropdown", params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditState(ID, CountryID, Name, IsActive) {
	let params = JSON.stringify({
		ID: ID,
		CountryID: CountryID,
		Name: Name,
		IsActive: IsActive,
	});
	let url = URLs.base + URLs.AddEditState;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteState(ID) {
	let params = JSON.stringify({
		ID: ID,
	});
	let url = URLs.base + URLs.DeleteState;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetStateByID(ID) {
	let params = JSON.stringify({
		ID: ID,
	});
	let url = URLs.base + URLs.GetStateByID;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetStates(ID, CountryID, Name, IsActive) {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.Getstate;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetStateForDropdown(CountryID) {
	let params = JSON.stringify({
		CountryID: CountryID,
	});
	let url = URLs.base + URLs.GetstateForDropdown;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetBrand(ShowInAutomation, SortExpression, SortDirection, AutomationSubscriptionID) {
	let params = AutomationSubscriptionID == 0
		? JSON.stringify({
			"ShowInAutomation": ShowInAutomation,
			"SortExpression": SortExpression,
			"SortDirection": SortDirection
		}) : JSON.stringify({
			"ShowInAutomation": ShowInAutomation,
			"SortExpression": SortExpression,
			"SortDirection": SortDirection,
			"AutomationSubscriptionID": AutomationSubscriptionID
		});
	let url = URLs.base + URLs.Getbrand;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetBrandByID(ID) {
	let params = JSON.stringify({
		ID: ID,
	});
	let url = URLs.base + URLs.GetBrandByID;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetBrandForDropdown() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetBrandForDropdown;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditBrand(ID, Name, Description, ImageName, ImageData, OrderNo, IsActive) {
	let params = JSON.stringify({
		ID: ID,
		Name: Name,
		Description: Description,
		ImageURL: "",
		ImageName: ImageName,
		ImageData: ImageData,
		OrderNo: OrderNo,
		IsActive: IsActive,
	});
	let url = URLs.base + URLs.AddEditBrand;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteBrand(ID) {
	let params = JSON.stringify({
		ID: ID,
	});
	let url = URLs.base + URLs.DeleteBrand;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetProducts() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetProducts;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetProductByID(ID) {
	let params = JSON.stringify({ ID: ID });
	let url = URLs.base + URLs.GetProductByID;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetProductImages(ID, BrandID) {
	let params = JSON.stringify({ ProductID: ID, BrandID: BrandID });
	let url = URLs.base + URLs.GetProductImages;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditProductImage(ID, SequenceNo, ProductID, BrandID, ImageName, ImageData, IsForUpdate) {
	let params = JSON.stringify({
		ID: ID,
		SequenceNo: SequenceNo,
		ProductID: ProductID,
		BrandID: BrandID,
		ImageName: ImageName,
		ImageURL: "",
		ImageData: ImageData,
		IsForUpdate: IsForUpdate
	});
	let url = URLs.base + URLs.AddEditProductImage;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteProductImage(ID) {
	let params = JSON.stringify({
		ID: ID,
	});
	let url = URLs.base + URLs.DeleteProductImage;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetProductForDropdown() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetProductForDropdown;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditProduct(ID, Name, TagLine, Description, EditDescription, ImageName, ImageData, HoverImageName, HoverImageData,
	OrderNo, IsActive, Inclusions, CoOpPercentage, ProductAssetTypes, DisplayImageName, DisplayImageData, IsParent, IsTile, TileName) {
	let params = JSON.stringify({
		ID: ID,
		Name: Name,
		TagLine: TagLine,
		Description: Description,
		EditDescription: EditDescription,
		ImageName: ImageName,
		ImageURL: "",
		ImageData: ImageData,
		HoverImageName: HoverImageName,
		HoverImageURL: "",
		HoverImageData: HoverImageData,
		OrderNo: OrderNo,
		IsActive: IsActive,
		Inclusions: Inclusions,
		CoOpPercentage: CoOpPercentage,
		ProductAssetTypes: ProductAssetTypes,
		DisplayImageName: DisplayImageName,
		DisplayImageData: DisplayImageData,
		DisplayImageURL: "",
		IsParent: IsParent,
		IsTile: IsTile,
		TileName: TileName
	});

	let url = URLs.base + URLs.AddEditProduct;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function ConfigureProduct(ID, PageURL, MenuPath, CustomizePath, ThemeButtons, ShowAddToCart, ShowCopyText, ShowAdBuilder, ShowDownload, IsPrintProduct,
	PreviewImageName, PreviewImageData, PreviewImageURL, BypassPayPalFlow, FreeUntil, StartSubscribeFrom, IsBundleProduct, BundleProductName, ShowAssetsInSocialAutomation) {
	let params = JSON.stringify({
		ID: ID,
		PageURL: PageURL,
		MenuPath: MenuPath,
		CustomizePath: CustomizePath,
		ThemeButtons: ThemeButtons,
		ShowAddToCart: ShowAddToCart,
		ShowCopyText: ShowCopyText,
		ShowAdBuilder: ShowAdBuilder,
		ShowDownload: ShowDownload,
		IsPrintProduct: IsPrintProduct,
		PreviewImageName: PreviewImageName,
		PreviewImageData: PreviewImageData,
		PreviewImageURL: PreviewImageURL,
		BypassPayPalFlow: BypassPayPalFlow,
		FreeUntil: FreeUntil,
		StartSubscribeFrom: StartSubscribeFrom,
		IsBundleProduct: IsBundleProduct,
		BundleProductName: BundleProductName,
		ShowAssetsInSocialAutomation: ShowAssetsInSocialAutomation
	});

	let url = URLs.base + URLs.ConfigureProduct;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteProduct(ID) {
	let params = JSON.stringify({
		ID: ID,
	});
	let url = URLs.base + URLs.DeleteProduct;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetProductChildren(ProductID) {
	let params = JSON.stringify({
		ProductID: ProductID,
	});
	let url = URLs.base + URLs.GetProductChildren;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditProductChildren(ID, ParentProductID, TabName, TabSequence, ProductID) {
	let params = JSON.stringify({
		ID: ID,
		ParentProductID: ParentProductID,
		TabName: TabName,
		TabSequence: TabSequence,
		ProductID: ProductID
	});
	let url = URLs.base + URLs.AddEditProductChildren;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteProductChildren(ID) {
	let params = JSON.stringify({ ID: ID });
	let url = URLs.base + URLs.DeleteProductChildren;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function VerifyClinic(ShipToNo, ZipCode) {
	let params = JSON.stringify({
		ShipToNo: ShipToNo,
		Postcode: ZipCode,
	});
	let url = URLs.base + URLs.VerifyClinic;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function RegisterPractice(Website, AppointmentURL, ImageName, ImageData, FirstName, LastName, EmailAddress, PhoneNo, PracticeData) {
	let params = JSON.stringify({
		'Website': Website,
		'AppointmentURL': AppointmentURL,
		'ImageName': ImageName,
		'ImageURL': '',
		'ImageData': ImageData,
		'FirstName': FirstName,
		'LastName': LastName,
		'EmailAddress': EmailAddress,
		'PhoneNo': PhoneNo,
		'PracticeData': PracticeData
	})

	let url = URLs.base + URLs.RegisterPractice
	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function GetCompanyBranches() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetCompanyBranches;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditCompanyBranch(ID, CompanyID, Name, IsActive) {
	let params = JSON.stringify({
		ID: ID,
		CompanyID: CompanyID,
		Name: Name,
		IsActive: IsActive,
	});
	let url = URLs.base + URLs.AddEditCompanyBranch;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteCompanyBranch(ID) {
	let params = JSON.stringify({
		ID: ID,
	});
	let url = URLs.base + URLs.DeleteCompanyBranch;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetCompanyBranchForDropdown(RegionID) {
	let params = JSON.stringify({ CompanyID: RegionID });
	let url = URLs.base + URLs.GetCompanyBranchForDropdown;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetPositions() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetPositions;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditPosition(ID, Name, CompanyID, IsActive) {
	let params = JSON.stringify({
		ID: ID,
		Name: Name,
		CompanyID: CompanyID,
		IsActive: IsActive,
	});
	let url = URLs.base + URLs.AddEditPosition;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeletePosition(ID) {
	let params = JSON.stringify({
		ID: ID,
	});
	let url = URLs.base + URLs.DeletePosition;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetPositionForDropdown(RegionID) {
	let params = JSON.stringify({ CompanyID: RegionID });
	let url = URLs.base + URLs.GetPositionForDropdown;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetDepartments() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetDepartments;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditDepartment(ID, CompanyID, Name, IsActive) {
	let params = JSON.stringify({
		ID: ID,
		CompanyID: CompanyID,
		Name: Name,
		IsActive: IsActive,
	});
	let url = URLs.base + URLs.AddEditDepartment;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function DeleteDepartment(ID) {
	let params = JSON.stringify({
		ID: ID,
	});
	let url = URLs.base + URLs.DeleteDepartment;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetDepartmentForDropdown(RegionID) {
	let params = JSON.stringify({ CompanyID: RegionID });
	let url = URLs.base + URLs.GetDepartmentForDropdown;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetUsers(CompanyID, FullName, EmailAddress, PhoneNo, Role, Area, Region, Territory, CurrentPage, RecordPerPage, SortExpression, SortDirection) {
	let params = JSON.stringify({
		CompanyID: CompanyID,
		FullName: FullName,
		EmailAddress: EmailAddress,
		PhoneNo: PhoneNo,
		Role: Role,
		Area: Area,
		Region: Region,
		Territory: Territory,
		CurrentPage: CurrentPage,
		RecordPerPage: RecordPerPage,
		SortExpression: SortExpression,
		SortDirection: SortDirection
	});
	let url = URLs.base + URLs.GetUsers;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetNotifications(Title, NotificationAt, TypeModule, RecordsPerPage, CurrentPage) {
	let params = JSON.stringify({
		"Title": Title,
		"NotificationAt": NotificationAt,
		"TypeModule": TypeModule,
		"RecordsPerPage": RecordsPerPage,
		"CurrentPage": CurrentPage,
		"SortExpression": "Title",
		"SortDirection": "DESC"
	});
	let url = URLs.base + URLs.GetNotifications;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {

				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AdminAddEditUser(ID, CompanyID, FirstName, LastName, EmailAddress, PhoneNo, Role, IsActive, UserType) {
	let params = JSON.stringify({
		ID: ID,
		CompanyID: CompanyID,
		FirstName: FirstName,
		LastName: LastName,
		EmailAddress: EmailAddress,
		PhoneNo: PhoneNo,
		RoleID: Role,
		IsActive: IsActive,
		UserType: UserType
	});
	let url = URLs.base + URLs.AddEditUser;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditUser(ID, CompanyID, PracticeID, FirstName, LastName, EmailAddress, PhoneNo, IsActive, UserType) {
	let params = JSON.stringify({
		ID: ID,
		CompanyID: CompanyID,
		PracticeID: PracticeID,
		FirstName: FirstName,
		LastName: LastName,
		EmailAddress: EmailAddress,
		PhoneNo: PhoneNo,
		IsActive: IsActive,
		UserType: UserType
	});
	let url = URLs.base + URLs.AddEditUser;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function BLAddEditUser(ID, CompanyID, FirstName, LastName, EmailAddress, PhoneNo, Territory, RoleID, Branch, PositionID,
	DepartmentID, AreaID, RegionID, TerritoryID, IsActive, UserType, UserLevelID) {
	let params = JSON.stringify({
		ID: ID,
		CompanyID: CompanyID,
		FirstName: FirstName,
		LastName: LastName,
		EmailAddress: EmailAddress,
		PhoneNo: PhoneNo,
		Territory: Territory,
		RoleID: RoleID,
		CompanyBranchID: Branch,
		PositionID: PositionID,
		DepartmentID: DepartmentID,
		AreaID: AreaID,
		RegionID: RegionID,
		TerritoryID: TerritoryID,
		IsActive: IsActive,
		UserType: UserType,
		UserLevelID: UserLevelID
	});
	let url = URLs.base + URLs.AddEditUser;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteUser(ID) {
	let params = JSON.stringify({
		ID: ID,
	});
	let url = URLs.base + URLs.DeleteUser;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetUserByID(ID) {
	let params = JSON.stringify({
		ID: ID,
	});
	let url = URLs.base + URLs.GetUserByID;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function SetPracticePassword(PracticeID, UserID, Password) {
	let params = JSON.stringify({
		'PracticeID': PracticeID,
		'UserID': UserID,
		'Password': Password
	})
	let url = URLs.base + URLs.SetPracticePassword
	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function ForgotPassword(EmailAddress) {
	let params = JSON.stringify({
		'EmailAddress': EmailAddress
	})
	let url = URLs.base + URLs.ForgotPassword
	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function ChangePassword(Password, NewPassword) {
	let params = JSON.stringify({
		Password: Password,
		NewPassword: NewPassword,
	});
	let url = URLs.base + URLs.ChangePassword;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function VerifyOTP(OTP) {
	let params = JSON.stringify({
		'OTP': OTP
	})
	let url = URLs.base + URLs.VerifyOTP
	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function ResendOTP() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.ResendOTP;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function ImportPractices(ExcelData) {
	let params = JSON.stringify({ ExcelData: ExcelData });
	let url = URLs.base + URLs.ImportPractices;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function ImportPracticeAndUsers(PracticeData, UserData) {
	let params = JSON.stringify({
		"PracticeData": PracticeData,
		"UserData": UserData
	});
	let url = URLs.base + URLs.ImportPracticeAndUsers;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function ImportPostcodes(ExcelData) {
	let params = JSON.stringify({ JSON: JSON.stringify(ExcelData) });
	let url = URLs.base + URLs.ImportPostcodes;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetImportedPractices(CurrentPage, RecordPerPage, PracticeName, Address1, Address2, City, State, ZIPCode, BillTo, VTM, Territory, ShipTo, VisionSource, VSP) {
	let params = JSON.stringify({
		"PracticeName": PracticeName,
		"Address1": Address1,
		"Address2": Address2,
		"City": City,
		"State": State,
		"ZIPCode": ZIPCode,
		"BillTo": BillTo,
		"VTM": VTM,
		"Territory": Territory,
		"ShipTo": ShipTo,
		"VisionSource": VisionSource,
		"VSP": VSP,
		"CurrentPage": CurrentPage,
		"RecordPerPage": RecordPerPage,
	});
	let url = URLs.base + URLs.GetImportedPractices;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteImportedPractice(ID) {
	let params = JSON.stringify({
		ID: ID,
	});
	let url = URLs.base + URLs.DeleteImportedPractice;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetImportedPracticeByID(ID) {
	let params = JSON.stringify({
		ID: ID,
	});
	let url = URLs.base + URLs.GetImportedPracticeByID;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function SearchRepresentatives(Name) {
	let params = JSON.stringify({
		Name: Name,
	});
	let url = URLs.base + URLs.SearchRepresentatives;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {

				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function SearchPostcodes(Postcode) {
	let params = JSON.stringify({
		Postcode: Postcode,
	});
	let url = URLs.base + URLs.SearchPostcodes;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {

				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetAreas(CurrentPage, RecordPerPage, Name, ShortCode, IsActive) {
	let params = IsActive == "" || IsActive == undefined ? JSON.stringify({
		"Name": Name,
		"ShortCode": ShortCode,
		"CurrentPage": CurrentPage,
		"RecordPerPage": RecordPerPage
	}) :
		JSON.stringify({
			"Name": Name,
			"ShortCode": ShortCode,
			"IsActive": IsActive == "YES" ? 1 : 0,
			"CurrentPage": CurrentPage,
			"RecordPerPage": RecordPerPage
		});

	let url = URLs.base + URLs.GetAreas;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditArea(ID, Name, ShortCode, IsActive) {
	let params = JSON.stringify({
		ID: ID,
		Name: Name,
		ShortCode: ShortCode,
		IsActive: IsActive,
	});
	let url = URLs.base + URLs.AddEditArea;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteArea(ID) {
	let params = JSON.stringify({
		'ID': ID
	})
	let url = URLs.base + URLs.DeleteArea

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function GetRegions() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetRegions;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditRegion(ID, AreaID, Name, ShortCode, IsActive) {
	let params = JSON.stringify({
		ID: ID,
		AreaID: AreaID,
		Name: Name,
		ShortCode: ShortCode,
		IsActive: IsActive,
	});
	let url = URLs.base + URLs.AddEditRegion;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteRegion(ID) {
	let params = JSON.stringify({
		'ID': ID
	})
	let url = URLs.base + URLs.DeleteRegion

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function GetRegionByID(ID) {
	let params = JSON.stringify({
		ID: ID,
	});
	let url = URLs.base + URLs.GetRegionByID;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetTerritories() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetTerritories;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditTerritory(ID, AreaID, RegionID, Name, ShortCode, IsActive) {
	let params = JSON.stringify({
		ID: ID,
		AreaID: AreaID,
		RegionID: RegionID,
		Name: Name,
		ShortCode: ShortCode,
		IsActive: IsActive,
	});
	let url = URLs.base + URLs.AddEditTerritory;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteTerritory(ID) {
	let params = JSON.stringify({
		'ID': ID
	})
	let url = URLs.base + URLs.DeleteTerritory

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function GetTerritoryByID(ID) {
	let params = JSON.stringify({
		ID: ID,
	});
	let url = URLs.base + URLs.GetTerritoryByID;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function GetAreaForDropdown(ID) {
	let params = JSON.stringify({ ID: ID });
	let url = URLs.base + URLs.GetAreaForDropdown;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetRegionForDropdown(AreaID) {
	let params = JSON.stringify({ AreaID: AreaID });
	let url = URLs.base + URLs.GetRegionForDropdown;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetTerritoryForDropdown(RegionID) {
	let params = JSON.stringify({ RegionID: RegionID });
	let url = URLs.base + URLs.GetTerritoryForDropdown;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetAssetTypes() {
	let params = JSON.stringify({
		RecordPerPage: "50"
	});
	let url = URLs.base + URLs.GetAssetTypes;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetSegments() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetSegments;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditSegment(ID, Name, SequenceNo, IsDefault, IsActive) {
	let params = JSON.stringify({
		"ID": ID,
		"Name": Name,
		"SequenceNo": SequenceNo,
		"IsDefault": IsDefault,
		"IsActive": IsActive,
	});
	let url = URLs.base + URLs.AddEditSegment;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteSegment(ID) {
	let params = JSON.stringify({ "ID": ID });
	let url = URLs.base + URLs.DeleteSegment;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetSegmentByID(ID) {
	let params = JSON.stringify({ "ID": ID });
	let url = URLs.base + URLs.GetSegmentByID;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditAssetType(ID, Name, Description, IsFreeAssetType, IsDirectMailAssetType, IsActive) {
	let params = JSON.stringify({
		ID: ID,
		Name: Name,
		Description: Description,
		IsFreeAssetType: IsFreeAssetType,
		IsDirectMailAssetType: IsDirectMailAssetType,
		IsActive: IsActive,
	});
	let url = URLs.base + URLs.AddEditAssetType;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetAssetTypeByID(ID) {
	let params = JSON.stringify({
		'ID': ID
	})
	let url = URLs.base + URLs.GetAssetTypeByID

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function GetAssetSizeByID(ID) {
	let params = JSON.stringify({
		'ID': ID
	})
	let url = URLs.base + URLs.GetAssetSizeByID

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function DeleteAssetType(ID) {
	let params = JSON.stringify({
		'ID': ID
	})
	let url = URLs.base + URLs.DeleteAssetType

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function GetAssetTypeForDropdown(ProductID, CampaignID) {
	let params = JSON.stringify({
		ProductID: ProductID,
		CampaignID: CampaignID
	});
	let url = URLs.base + URLs.GetAssetTypeForDropdown;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetSegmentForDropdown() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetSegmentForDropdown;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function GetDirectMailAssetTypeForDropdown(DirectMailAssetTypeId) {
	let params = JSON.stringify({ 'IsDirectMailAssetType': DirectMailAssetTypeId });
	let url = URLs.base + URLs.GetAssetTypeForDropdown;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetAssetSizes() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetAssetSizes;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditAssetSize(ID, AssetTypeID, Height, Width, IsActive) {
	let params = JSON.stringify({
		ID: ID,
		AssetTypeID: AssetTypeID,
		Height: Height,
		Width: Width,
		IsActive: IsActive,
	});
	let url = URLs.base + URLs.AddEditAssetSize;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteAssetSize(ID) {
	let params = JSON.stringify({
		'ID': ID
	})
	let url = URLs.base + URLs.DeleteAssetSize

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function GetMyProfile() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetMyProfile;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetSupplyToolPracticePricingForDropdown() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetSupplyToolPracticePricingForDropdown;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditSupplyToolPracticePricing(ID, JSONData, PracticeName, Address1, Address2, City, State, Postcode, PhoneNo) {
	let params = JSON.stringify({
		"ID": ID,
		"JSON": JSONData,
		"PracticeName": PracticeName,
		"Address1": Address1,
		"Address2": Address2,
		"City": City,
		"State": State,
		"Postcode": Postcode,
		"PhoneNo": PhoneNo
	});
	let url = URLs.base + URLs.AddEditSupplyToolPracticePricing;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function EditProfile(FirstName, LastName, PhoneNo, EmailAddress) {
	let params = JSON.stringify({
		FirstName: FirstName,
		LastName: LastName,
		PhoneNo: PhoneNo,
		EmailAddress: EmailAddress
	});
	let url = URLs.base + URLs.EditProfile;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetPractices(CurrentPage, RecordPerPage, RegistrationDate, PracticeName, ShipToNo, Representative, ContactName, Address1, Address2, City, State, Postcode, EmailAddress, PhoneNo, VisionSource, VSP, IsCoOp, Status) {
	let params = JSON.stringify({
		"CreatedAt": RegistrationDate,
		"PracticeName": PracticeName,
		"ShipToNo": ShipToNo,
		"Representative": Representative,
		"ContactName": ContactName,
		"Address1": Address1,
		"Address2": Address2,
		"City": City,
		"State": State,
		"Postcode": Postcode,
		"EmailAddress": EmailAddress,
		"PhoneNo": PhoneNo,
		"VisionSource": VisionSource == "YES" ? 1 : VisionSource == "NO" ? 0 : "",
		"VSP": VSP == "YES" ? 1 : VSP == "NO" ? 0 : "",
		"Status": Status,
		"CurrentPage": CurrentPage,
		"RecordPerPage": RecordPerPage
	});

	let url = URLs.base + URLs.GetPractices;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetUsersByPracticeID(ID) {
	let params = JSON.stringify({
		PracticeID: ID,
	});
	let url = URLs.base + URLs.GetUsers;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetPracticeByCompanyID(ID) {
	let params = JSON.stringify({ ParentCompanyID: ID, });
	let url = URLs.base + URLs.GetPractices;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}


export function GetPracticeByID(ID) {
	let params = JSON.stringify({ ID: ID });
	let url = URLs.base + URLs.GetPractices;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function ApprovePractice(PracticeID, RepresentativeID) {
	let params = JSON.stringify({
		PracticeID: PracticeID,
		RepresentativeID: RepresentativeID,
	});
	let url = URLs.base + URLs.ApprovePractice;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {

				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function RejectPractice(PracticeID, RejectionReason) {
	let params = JSON.stringify({
		PracticeID: PracticeID,
		RejectionReason: RejectionReason,
	});
	let url = URLs.base + URLs.RejectPractice;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {

				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetCampaigns() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetCampaigns;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetCampaignForDropdown() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetCampaignForDropdown;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetCampaignByID(ID) {
	let params = JSON.stringify({ ID: ID });
	let url = URLs.base + URLs.GetCampaignByID;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditCampaign(ID, Name, BrandID, ImageName, ImageData, OrderNo, RawAssetURL, Products, SendRawURLLink, IsLive, InstructionsURL) {
	let params = JSON.stringify({
		ID: ID,
		Name: Name,
		BrandID: BrandID,
		ImageName: ImageName,
		ImageData: ImageData,
		ImageURL: "",
		OrderNo: OrderNo,
		RawAssetURL: RawAssetURL,
		Products: Products,
		SendRawURLLink: SendRawURLLink,
		IsLive: IsLive,
		InstructionsURL: InstructionsURL
	});
	let url = URLs.base + URLs.AddEditCampaign;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}


export function DeleteCampaign(ID) {
	let params = JSON.stringify({ ID: ID });
	let url = URLs.base + URLs.DeleteCampaign;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetCampaignThemes(CampaignID, ProductID) {
	let params = JSON.stringify({
		CampaignID: CampaignID,
		ProductID: ProductID
	});
	let url = URLs.base + URLs.GetCampaignThemes;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetMonthlyBudgetByProductID(ProductID) {
	let params = JSON.stringify({ ProductID: ProductID });
	let url = URLs.base + URLs.GetMonthlyBudgetByProductID;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetMonthlyBudget() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetMonthlyBudget;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteMonthlyBudget(ID) {
	let params = JSON.stringify({ "ID": ID });
	let url = URLs.base + URLs.DeleteMonthlyBudget;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditMonthlyBudget(ID, ProductID, Budget) {
	let params = JSON.stringify({
		"ID": ID,
		"ProductID": ProductID,
		"Budget": Budget
	});
	let url = URLs.base + URLs.AddEditMonthlyBudget;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function ReportEvent(CampaignAssetID, PracticeID, ReportTypeID) {
	let params = JSON.stringify({
		CampaignAssetID: CampaignAssetID,
		PracticeID: PracticeID,
		ReportTypeID: ReportTypeID,
	});
	let url = URLs.base + URLs.ReportEvent;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetCampaignThemesForDropdown(CampaignID) {
	let params = JSON.stringify({
		CampaignID: CampaignID
	});
	let url = URLs.base + URLs.GetCampaignThemesForDropdown;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditCampaignTheme(ID, CampaignID, Name, Description, OrderNo, ImageName, ImageURL, ImageData, IsActive) {
	let params = JSON.stringify({
		ID: ID,
		CampaignID: CampaignID,
		Name: Name,
		Description: Description,
		OrderNo: OrderNo,
		ImageName: ImageName,
		ImageURL: ImageURL,
		ImageData: ImageData,
		IsActive: IsActive
	});
	let url = URLs.base + URLs.AddEditCampaignTheme;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteCampaignTheme(ID) {
	let params = JSON.stringify({ ID: ID });
	let url = URLs.base + URLs.DeleteCampaignTheme;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function GetCampaignMessages(CampaignID) {
	let params = JSON.stringify({
		CampaignID: CampaignID
	});
	let url = URLs.base + URLs.GetCampaignMessages;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditCampaignMessage(ID, CampaignID, Name, OrderNo, ImageName, ImageURL, ImageData) {
	let params = JSON.stringify({
		ID: ID,
		CampaignID: CampaignID,
		Name: Name,
		OrderNo: OrderNo,
		ImageName: ImageName,
		ImageURL: ImageURL,
		ImageData: ImageData
	});
	let url = URLs.base + URLs.AddEditCampaignMessage;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteCampaignMessage(ID) {
	let params = JSON.stringify({ ID: ID });
	let url = URLs.base + URLs.DeleteCampaignMessage;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetCampaignAssets(CampaignID, CampaignThemeID, PracticeID, ProductID, TotalLocations, FreeAssetID) {
	let params = FreeAssetID == "" || FreeAssetID == undefined || FreeAssetID == null
		? JSON.stringify({ CampaignID: CampaignID, CampaignThemeID: CampaignThemeID, ProductID: ProductID })
		: JSON.stringify({ CampaignID: CampaignID, PracticeID: PracticeID, FreeAssetID: FreeAssetID, TotalLocations: TotalLocations, ProductID: ProductID })

	let url = URLs.base + URLs.GetCampaignAssets;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetCampaignAssetsTypeByID(CampaignID, CampaignThemeID, AssetTypeID, PracticeID, TotalLocations) {
	let params = JSON.stringify({ CampaignID: CampaignID, CampaignThemeID: CampaignThemeID, AssetTypeID: AssetTypeID, PracticeID: PracticeID, TotalLocations: TotalLocations });
	let url = URLs.base + URLs.GetCampaignAssets;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditCampaignAsset(ID, CampaignID, AssetTypeID, AdContent, AssetSizeID, ImageName, ImageURL, ImageData, CampaignThemeID, isPrintProductEdit) {
	let params = !isPrintProductEdit ? JSON.stringify({
		ID: ID,
		CampaignID: CampaignID,
		AssetTypeID: AssetTypeID,
		AdContent: AdContent,
		AssetSizeID: AssetSizeID,
		ImageName: ImageName,
		ImageURL: ImageURL,
		ImageData: ImageData,
		CampaignThemeID: CampaignThemeID
	}) : JSON.stringify({
		ID: ID,
		CampaignID: CampaignID,
		AssetTypeID: AssetTypeID,
		PrintAdContent: AdContent,
		AssetSizeID: AssetSizeID,
		ImageName: ImageName,
		ImageURL: ImageURL,
		ImageData: ImageData,
		CampaignThemeID: CampaignThemeID
	});
	let url = URLs.base + URLs.AddEditCampaignAsset;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetCampaignAssetByID(ID) {
	let params = JSON.stringify({ ID: ID });
	let url = URLs.base + URLs.GetCampaignAssetByID;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteCampaignAsset(ID) {
	let params = JSON.stringify({ ID: ID });
	let url = URLs.base + URLs.DeleteCampaignAsset;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetCampaignProducts(ID, ShowBundleProducts) {
	let params = JSON.stringify({
		CampaignID: ID,
		ShowBundleProducts: ShowBundleProducts,
	});
	let url = URLs.base + URLs.GetCampaignProducts;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function GetProductsForHome() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetProductsForHome;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function GetBrandsForHome(ProductID) {
	let params = JSON.stringify({ ProductID: ProductID });
	let url = URLs.base + URLs.GetBrandsForHome;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddRevokeCoOp(PracticeID, Amount, Remarks, Type) {
	let params = JSON.stringify({
		PracticeID: PracticeID,
		Amount: Amount,
		Remarks: Remarks,
		Type: Type
	});
	let url = URLs.base + URLs.AddRevokeCoOp;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetPracticeCoOp(ID) {
	let params = JSON.stringify({
		PracticeID: ID,
	});
	let url = URLs.base + URLs.GetPracticeCoOp;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditCampaignFreeAsset(ID, ProductID, CampaignThemeID, CampaignID, VeevaCode, ExpirationDate, Name, OrderNo, ImageName, ImageData,
	AssetTypeID, Description, Segment, ExternalURL, Customizable, DownloadType, ThumbnailImageName, ThumbnailImageData, OriginalImageName,
	AdContent, PrintAdContent, ShowAssetsInSocialAutomation, isNewAsset, IsNewStartDate, IsNewEndDate) {
	let params = JSON.stringify({
		ID: ID,
		ProductID: ProductID,
		CampaignThemeID: CampaignThemeID,
		CampaignID: CampaignID,
		VeevaCode: VeevaCode,
		ExpirationDate: ExpirationDate,
		Name: Name,
		OrderNo: OrderNo,
		ImageName: ImageName,
		ImageURL: '',
		ImageData: ImageData,
		AssetTypeID: AssetTypeID,
		Description: Description,
		Segment: Segment,
		ExternalURL: ExternalURL,
		IsCustomizable: Customizable,
		DownloadType: DownloadType,
		ThumbnailImageURL: '',
		ThumbnailImageName: ThumbnailImageName,
		ThumbnailImageData: ThumbnailImageData,
		OriginalImageName: OriginalImageName,
		AdContent: AdContent,
		PrintAdContent: PrintAdContent,
		ShowAssetsInSocialAutomation: ShowAssetsInSocialAutomation,
		IsNew: isNewAsset,
		IsNewStartDate: IsNewStartDate,
		IsNewEndDate: IsNewEndDate
	});

	let url = URLs.base + URLs.AddEditCampaignFreeAsset;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetCampaignFreeAssetByID(ID) {
	let params = JSON.stringify({ ID: ID });

	let url = URLs.base + URLs.GetCampaignFreeAssetByID;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditCampaignDirectMailAsset(ID, CampaignID, Name, OrderNo, ImageName, ImageURL, ImageData, AssetTypeID, Height, Width, HTMLContent) {
	let params = JSON.stringify({
		ID: ID,
		CampaignID: CampaignID,
		Name: Name,
		OrderNo: OrderNo,
		ImageName: ImageName,
		ImageURL: ImageURL,
		ImageData: ImageData,
		AssetTypeID: AssetTypeID,
		Height: Height,
		Width: Width,
		HTMLContent: HTMLContent
	});
	let url = URLs.base + URLs.AddEditCampaignDirectMailAsset;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetCampaignFreeAssets(CampaignID, AssetTypeID, ProductID) {
	let params = JSON.stringify({
		CampaignID: CampaignID,
		AssetTypeID: AssetTypeID,
		ProductID: ProductID
	});
	let url = URLs.base + URLs.GetCampaignFreeAssets;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetCampaignFreeAssets1(CampaignID, RecordPerPage) {
	let params = JSON.stringify({
		CampaignID: CampaignID,
		RecordPerPage: RecordPerPage,
	});
	let url = URLs.base + URLs.GetCampaignFreeAssets;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function GetCampaignFreeAssets2(ProductID, AssetTypeID, RecordPerPage) {
	let params = JSON.stringify({
		ProductID: ProductID,
		AssetTypeID: AssetTypeID,
		RecordPerPage: RecordPerPage,
	});
	let url = URLs.base + URLs.GetCampaignFreeAssets;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetCampaignDirectMailAssets(AssetTypeID) {
	let params = JSON.stringify({ "AssetTypeID": AssetTypeID });
	let url = URLs.base + URLs.GetCampaignDirectMailAssets;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteCampaignFreeAsset(ID) {
	let params = JSON.stringify({
		ID: ID,
	});
	let url = URLs.base + URLs.DeleteCampaignFreeAsset;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteCampaignDirectMailAsset(ID) {
	let params = JSON.stringify({
		ID: ID,
	});
	let url = URLs.base + URLs.DeleteCampaignDirectMailAsset;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetAllLocations() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetAllLocations;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function VerifyToken() {
	let params = JSON.stringify({

	});
	let url = URLs.base + URLs.VerifyToken;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function GetLocationDetails(isAutomation, PracticeId, IsFromHeader) {
	let params = isAutomation == "NO" || PracticeId == "0" || IsFromHeader == 3 ? JSON.stringify({}) : JSON.stringify({ PracticeID: PracticeId, ShowOnlySubscribedLocations: 1 });
	let url = URLs.base + URLs.GetLocationDetails;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function UpdateLocation(Params) {
	let url = URLs.base + URLs.UpdateLocation;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, Params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function EnableDisableCoOp(PracticeID, IsCoOpEnabled) {
	let params = JSON.stringify({
		PracticeID: PracticeID,
		IsCoOpEnabled: IsCoOpEnabled
	});
	let url = URLs.base + URLs.EnableDisableCoOp;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function EditPractice(PracticeID, IsCoOpEnabled, VTMID, WebSite) {
	let params = JSON.stringify({
		"PracticeID": PracticeID,
		"IsCoOpEnabled": IsCoOpEnabled,
		"VTMID": VTMID,
		"WebSite": WebSite
	});
	let url = URLs.base + URLs.EditPractice;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditPracticeAddress(PracticeID, ParentCompanyID, ShipToNo, PracticeName, PhoneNo, Address1, Address2, Postcode, VisionSource, VSP) {
	let params = JSON.stringify({
		"ID": PracticeID,
		"ParentCompanyID": ParentCompanyID,
		"ShipToNo": ShipToNo,
		"PracticeName": PracticeName,
		"PhoneNo": PhoneNo,
		"Address1": Address1,
		"Address2": Address2,
		"Postcode": Postcode,
		"VisionSource": VisionSource,
		"VSP": VSP,
		"IsActive": 1
	});
	let url = URLs.base + URLs.AddEditPracticeAddress;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function UploadPracticeLogo(PracticeID, ImageName, ImageData, isPracticeLogo) {
	let params = JSON.stringify({
		PracticeID: PracticeID,
		ImageName: ImageName,
		ImageData: ImageData,
		ImageURL: ''
	});
	let url = isPracticeLogo ? URLs.base + URLs.UploadPracticeLogo : URLs.base + URLs.UploadDoctorLogo;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteDoctorLogo(PracticeID) {
	let params = JSON.stringify({ PracticeID: PracticeID });
	let url = URLs.base + URLs.DeleteDoctorLogo;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DownloadPostcard(CampaignID, PracticeID, CampaignThemeID, TotalLocations, AssetTypeID, FreeAssetID) {
	let params = FreeAssetID == "" || FreeAssetID == undefined || FreeAssetID == null
		? JSON.stringify({
			"CampaignID": CampaignID,
			"PracticeID": PracticeID,
			"CampaignThemeID": CampaignThemeID,
			"TotalLocations": TotalLocations,
			"AssetTypeID": AssetTypeID,
			"FreeAssetID": ""
		}) : JSON.stringify({
			"CampaignID": CampaignID,
			"PracticeID": PracticeID,
			"TotalLocations": TotalLocations,
			"FreeAssetID": FreeAssetID
		});
	let url = URLs.base + URLs.DownloadPostcard;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {

				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response.headers.get('content-disposition') != null && response.headers.get('content-disposition').toString().indexOf('filename')) {
					response.blob().then((data) => {
						DownloadFileClientside(response.headers.get('content-disposition').split(' filename=')[1].trim(), data);
					})
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DownloadCampaignAssetFile(ID, TypeID, IsFreeAsset) {
	let params = JSON.stringify({
		ID: ID,
		TypeID: TypeID,
		IsFreeAsset: IsFreeAsset
	});
	let url = URLs.base + URLs.DownloadCampaignAsset;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				}
				else {
					var FileName = response.headers.get('content-disposition').split('=').pop().replace('"', '').replace('"', '');
					response.blob().then((data) => {
						DownloadFileClientside(FileName, data);
					})
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DownloadFileClientside(Name, response) {
	const href = window.URL.createObjectURL(response);
	const link = document.createElement('a');
	link.href = href;
	link.setAttribute('download', Name.replaceAll('"', '')); //or any other extension
	document.body.appendChild(link);
	link.click();
}


export function DownloadFile(FileURL, Name, CampaignID) {
	let params = JSON.stringify({
		FileURL: FileURL,
		CampaignFreeAssetID: CampaignID,
	});
	let url = URLs.base + URLs.DownloadFile;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else {
					// Name = Name + '.' + FileURL.split('.').pop();
					// DownloadFileResctSide(Name, FileURL);
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				}
			}).catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export async function DownloadFileResctSide(Name, FileURL) {
	const image = await fetch(FileURL);

	const imageBlog = await image.blob()
	const href = window.URL.createObjectURL(imageBlog);
	const link = document.createElement('a');
	link.href = href;
	link.setAttribute('download', Name); //or any other extension
	document.body.appendChild(link);
	link.click();
}

export function GetCart() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetCart;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function GetOrderList() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetOrderList;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetReportData(OrderID) {
	let params = JSON.stringify({ "OrderID": OrderID });
	let url = URLs.base + URLs.GetReportData;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function ImportReportData(PracticeID, OrderID, OrderDetailID, ReportType, LocationID, JSONData) {
	let params = JSON.stringify({
		"PracticeID": PracticeID,
		"OrderID": OrderID,
		"OrderDetailID": OrderDetailID,
		"ReportType": ReportType,
		"LocationID": LocationID,
		"JSON": JSONData
	});
	let url = URLs.base + URLs.ImportReportData;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteReportData(ID) {
	let params = JSON.stringify({ "ID": ID });
	let url = URLs.base + URLs.DeleteReportData;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

//CampaignOrders =  DownloadedAt, OrderNo, ShipToNo, CampaignName, BrandName, Budget, Duration, TotalBudget, CoOp, NetPracticeCost, PracticeName, Address1, Address2, City, State, Postcode, PhoneNo, VS, VSP, Website, AppointmentURL, ContactName, EmailAddress, VTM_Territory, VTM
//EmailMarketing = DownloadedAt, ShipToNo, CampaignName, BrandName, CampaignTheme, PracticeName,  EmailAddress, VeevaCode, Version, VTM_Territory, VTM, VS, VSP
//Registrations =  DownloadedAt, ShipToNo, CampaignName, BrandName, PracticeName, Address1, Address2, City, State, Postcode, PhoneNo, VS, VSP, Website, AppointmentURL, ContactName, EmailAddress, VTM_Territory, VTM
//SocialAutomation =  DownloadedAt, SubscriptionNo, ShipToNo, Subscription, StartDate, TotalBudget, CoOp, NetPracticeCost, PracticeName, Address1, Address2, City, State, Postcode, PhoneNo, VS, VSP, Website, AppointmentURL, ContactName, EmailAddress, VTM_Territory, VTM
//AssetDownload = DownloadedAt, FullName, PracticeName, BrandName, ShipToNo, AssetCategory, AssetName, CSRFirstName, CSRLastName, VeevaCode, VTM_Territory

// Budget, Duration, TotalBudget, CoOp, NetPracticeCost
// StartDate,EndDate

export function AssetDownloadReport(ReportTypeID, DownloadedAt, OrderNo, ShipToNo, CampaignName, BrandName, CampaignTheme, PracticeName,
	Address1, Address2, City, State, Postcode, PhoneNo, VS, VSP, Website, AppointmentURL, ContactName, EmailAddress, VTM_Territory, VTM,
	VeevaCode, Version, SubscriptionNo, Subscription, StartDate, EndDate, FullName, AssetCategory, AssetName, CSRFirstName, CSRLastName, CurrentPage, RecordPerPage) {

	let params = JSON.stringify({
		"ReportTypeID": ReportTypeID,
		"DownloadedAt": DownloadedAt,
		"OrderNo": OrderNo,
		"ShipToNo": ShipToNo,
		"CampaignName": CampaignName,
		"BrandName": BrandName,
		"CampaignTheme": CampaignTheme,
		"PracticeName": PracticeName,
		"Address1": Address1,
		"Address2": Address2,
		"City": City,
		"State": State,
		"Postcode": Postcode,
		"PhoneNo": PhoneNo,
		"VS": VS,
		"VSP": VSP,
		"Website": Website,
		"AppointmentURL": AppointmentURL,
		"ContactName": ContactName,
		"EmailAddress": EmailAddress,
		"VTM_Territory": VTM_Territory,
		"VTM": VTM,

		"VeevaCode": VeevaCode,
		"Version": Version,
		"SubscriptionNo": SubscriptionNo,

		"Subscription": Subscription,
		"StartDate": StartDate,
		"EndDate": EndDate,

		"FullName": FullName,
		"AssetCategory": AssetCategory,
		"AssetName": AssetName,
		"CSRFirstName": CSRFirstName,
		"CSRLastName": CSRLastName,

		"CurrentPage": CurrentPage,
		"RecordPerPage": RecordPerPage,
		"SortExpression": "DownloadedAt",
		"SortDirection": "DESC"
	});
	let url = URLs.base + URLs.AssetDownloadReport;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AssetSummaryReport(FromDate, ToDate) {
	let params = JSON.stringify({
		"FromDate": FromDate,
		"ToDate": ToDate,
	});
	let url = URLs.base + URLs.AssetSummaryReport;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function FreeAssetsReport(Name, CampaignName, BrandName, VeevaCode, AssetType, ExpirationDate, Segment, CurrentPage, RecordPerPage) {
	let params = JSON.stringify({
		"Name": Name,
		"CampaignName": CampaignName,
		"BrandName": BrandName,
		"VeevaCode": VeevaCode,
		"AssetType": AssetType,
		"ExpirationDate": ExpirationDate,
		"Segment": Segment,
		"CurrentPage": CurrentPage,
		"RecordPerPage": RecordPerPage,
		"SortExpression": "Brand",
		"SortDirection": "ASC"
	});
	let url = URLs.base + URLs.FreeAssetsReport;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}


export function RegistrationReport() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.RegistrationReport;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function GetLoginLogs(StartDate, EndDate, PracticeName, FullName, EmailAddress, Address1, Address2, City, State, Postcode, ShipToNo, VS, VSP, RecordPerPage, CurrentPage) {
	let params = JSON.stringify({
		"StartDate": StartDate,
		"EndDate": EndDate,
		"PracticeName": PracticeName,
		"FullName": FullName,
		"EmailAddress": EmailAddress,
		"Address1": Address1,
		"Address2": Address2,
		"City": City,
		"State": State,
		"Postcode": Postcode,
		"ShipToNo": ShipToNo,
		"VS": VS,
		"VSP": VSP,
		"RecordPerPage": RecordPerPage,
		"CurrentPage": CurrentPage,
		"SortExpression": "PracticeName",
		"SortDirection": "DESC"
	});
	let url = URLs.base + URLs.GetLoginLogs;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteCart(ID) {
	let params = JSON.stringify({
		OrderDetailsID: ID
	});
	let url = URLs.base + URLs.DeleteCart;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetOrderDetails(ID) {
	let params = JSON.stringify({
		OrderID: ID
	});
	let url = URLs.base + URLs.GetOrderDetails;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditCart(OrderDetailsID, Budget, CampaignDurationInMonths, CampaignStartDate, CampaignEndDate, CampaignID, ProductID, BrandID, CampaignThemeID, PracticeIDs, CampaignReach, MonthlyBudget) {
	let params = JSON.stringify({
		OrderDetailsID: OrderDetailsID,
		Budget: Budget,
		CampaignDurationInMonths: CampaignDurationInMonths,
		CampaignStartDate: CampaignStartDate,
		CampaignEndDate: CampaignEndDate,
		CampaignID: CampaignID,
		ProductID: ProductID,
		BrandID: BrandID,
		CampaignThemeID: CampaignThemeID,
		PracticeIDs: PracticeIDs,
		CampaignReach: CampaignReach,
		MonthlyBudget: MonthlyBudget,
	});


	let url = URLs.base + URLs.AddEditCart;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function PlaceOrder(UseCoOp) {
	let params = JSON.stringify({
		UseCoOp: UseCoOp
	});
	let url = URLs.base + URLs.PlaceOrder;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function OrderPayment(PaymentStatus, LocalPaymentID, PayPalID, PaymentAmount, UseCoOp) {
	let params = JSON.stringify({
		PaymentStatus: PaymentStatus,
		LocalPaymentID: LocalPaymentID,
		PayPalID: PayPalID,
		PaymentAmount: parseFloat(PaymentAmount.substring(1).replace(/,/g, '')),
		UseCoOp: UseCoOp
	});
	let url = URLs.base + URLs.OrderPayment;
	sessionStorage.setItem("PaymentAmount", parseFloat(PaymentAmount.substring(1).replace(/,/g, '')));
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function InitiateTransaction(PaymentAmount, OrderID) {
	let params = JSON.stringify({
		PaymentAmount: parseFloat(PaymentAmount.substring(1).replace(/,/g, '')),
		OrderID: OrderID
	});
	let url = URLs.base + URLs.InitiateTransaction;
	sessionStorage.setItem("PaymentAmount", parseFloat(PaymentAmount.substring(1).replace(/,/g, '')));
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function FrontEnd(details) {
	let params = JSON.stringify({
		details
	});
	let url = URLs.base + URLs.FrontEnd;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function EditCart(OrderDetailsID) {
	let params = JSON.stringify({
		OrderDetailsID: OrderDetailsID,
	});
	let url = URLs.base + URLs.EditCart;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}


export function ActivateSubscriptionViaBypassingPayPalFlow(ID) {
	let params = JSON.stringify({ "ID": ID });

	let url = URLs.base + URLs.ActivateSubscriptionViaBypassingPayPalFlow;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetPostageCharges() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetPostageCharges;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function CalculatePostageCharges(TotalAddresses) {
	let params = JSON.stringify({
		TotalAddresses: TotalAddresses
	});
	let url = URLs.base + URLs.CalculatePostageCharges;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetCampaignDirectMailAssetByID(ID) {
	let params = JSON.stringify({ ID: ID });
	let url = URLs.base + URLs.GetCampaignDirectMailAssetByID;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function SetPassword(RequestID, Password) {
	let params = JSON.stringify({ RequestID: RequestID, Password: Password });
	let url = URLs.base + URLs.SetPassword;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function VerifySSO(JWT) {
	let params = JSON.stringify({ JWT: JWT });
	let url = URLs.base + URLs.VerifySSO;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetMappingData(Postcode, Distance) {
	let params = JSON.stringify({
		"Postcode": Postcode,
		"Distance": Distance
	});
	let url = URLs.base + URLs.GetMappingData;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function CampaignReachLocations(Address, Key) {
	// let params = JSON.stringify({ address: Address, key: Address });
	let url = URLs.maplatlng + "address=" + Address + "&key=" + Key;
	// https://maps.googleapis.com/maps/api/geocode/json?address=380051&key=AIzaSyAKzBchXrRhstwGQIaB6oXZ9BuE6Izukzs
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.get, url)
			.then((response) => {
				resolve(new APIControllerResponse(response, null));
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetGeometryLocations(lat, lng, radius, Key) {
	let url = URLs.mapaddress + "query=post+office&location=" + lat + "," + lng + "&radius=" + radius + "&key=" + Key;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.get, url)
			.then((response) => {
				resolve(new APIControllerResponse(response, null));
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetGeometryLocationsByToken(Key, token) {
	let url = URLs.mapaddresstoken + "&key=" + Key + "&pagetoken=" + token;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.get, url)
			.then((response) => {
				resolve(new APIControllerResponse(response, null));
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetReports(StartDate, EndDate, ReportTypeID, OrderID, ShowWeekwise) {
	let params = JSON.stringify({
		StartDate: StartDate,
		EndDate: EndDate,
		ReportTypeID: ReportTypeID,
		OrderID: OrderID,
		ShowWeekwise: ShowWeekwise
	});
	let url = URLs.base + URLs.GetReports;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetOrderReportIDs(OrderID) {
	let params = JSON.stringify({
		"OrderID": OrderID,
	});
	let url = URLs.base + URLs.GetOrderReportIDs;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditOrderReportIDs(OrderID, ID, OrderDetailsID, ReportTypeID, dsUser, dsAccounts, CampaignID, CampaignID_1, CampaignID_2) {
	let params = JSON.stringify({
		"OrderID": OrderID,
		"ID": ID,
		"OrderDetailsID": OrderDetailsID,
		"ReportTypeID": ReportTypeID,
		"dsUser": dsUser,
		"dsAccounts": dsAccounts,
		"CampaignID": CampaignID,
		"CampaignID_1": CampaignID_1,
		"CampaignID_2": CampaignID_2,

	});
	let url = URLs.base + URLs.AddEditOrderReportIDs;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}


export function DeleteOrderReportIDs(ID) {
	let params = JSON.stringify({
		"ID": ID,
	});
	let url = URLs.base + URLs.DeleteOrderReportIDs;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetOrderListForDashboard(OrderID, ShowAllOrders) {
	let params = JSON.stringify({
		"OrderID": OrderID,
		"ShowAllOrders": ShowAllOrders
	});
	let url = URLs.base + URLs.GetOrderListForDashboard;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetDashboardData(StartDate, EndDate, ReportTypeID, OrderDetailsID, LocationID, GetWeeklyData) {
	let params = JSON.stringify({
		"StartDate": StartDate,
		"EndDate": EndDate,
		"ReportTypeID": ReportTypeID,
		"OrderDetailsID": OrderDetailsID,
		"LocationID": LocationID,
		"GetWeeklyData": GetWeeklyData
	});
	let url = URLs.base + URLs.GetDashboard;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}


export function GetLandingPageDashboard(BrandID, StartDate, EndDate) {
	let params = JSON.stringify({
		"BrandID": BrandID,
		"StartDate": StartDate,
		"EndDate": EndDate
	});
	let url = URLs.base + URLs.GetLandingPageDashboard;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function ReSequenceCampaignFreeAssets(AssetTypeID, ProductID, TotalNewAssets, IDs) {
	let params = JSON.stringify({
		"AssetTypeID": AssetTypeID,
		"ProductID": ProductID,
		"TotalNewAssets": TotalNewAssets,
		"IDs": IDs.toString()
	});
	let url = URLs.base + URLs.ReSequenceCampaignFreeAssets;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditAutomationCalendar(ID, CalendarMonth, CalendarYear, PaypalSubscriptionPlanID, IsActive) {
	let params = JSON.stringify({
		"ID": ID,
		"CalendarMonth": CalendarMonth,
		"CalendarYear": CalendarYear,
		"PaypalSubscriptionPlanID": PaypalSubscriptionPlanID,
		"IsActive": IsActive
	});

	let url = URLs.base + URLs.AddEditAutomationCalendar;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetAutomationCalendar() {
	let params = JSON.stringify({
		"SortExpression": "CalendarYear",
		"SortDirection": "DESC"
	});

	let url = URLs.base + URLs.GetAutomationCalendar;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteAutomationCalendar(ID) {
	let params = JSON.stringify({
		"ID": ID,
	});

	let url = URLs.base + URLs.DeleteAutomationCalendar;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteAutomationCalendarAsset(ID) {
	let params = JSON.stringify({ "ID": ID });

	let url = URLs.base + URLs.DeleteAutomationCalendarAsset;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteMyCustomPost(ID, AutomationSubscriptionID) {
	let params = JSON.stringify({
		"ID": ID,
		"AutomationSubscriptionID": AutomationSubscriptionID
	});

	let url = URLs.base + URLs.DeleteMyCustomPost;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetAutomationCalendarByID(ID) {
	let params = JSON.stringify({
		"ID": ID,
	});

	let url = URLs.base + URLs.GetAutomationCalendarByID;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditAutomationCalendarAsset(ID, AutomationCalendarID, BrandID,
	AssetDate, SchedulerDate, Description,
	ImageName, ImageData, ImageURL,
	PreviewName, PreviewData, PreviewURL,
	VeevaCode, AssetName) {
	let params = JSON.stringify({
		"ID": ID,
		"AutomationCalendarID": AutomationCalendarID,
		"BrandID": BrandID,
		"AssetDate": AssetDate,
		"SchedulerDate": SchedulerDate,
		"Description": Description,
		"ImageName": ImageName,
		"ImageData": ImageData,
		"ImageURL": ImageURL,
		"PreviewName": PreviewName,
		"PreviewData": PreviewData,
		"PreviewURL": PreviewURL,
		"VeevaCode": VeevaCode,
		"AssetName": AssetName,
	});

	let url = URLs.base + URLs.AddEditAutomationCalendarAsset;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetAutomationCalendarAssets(AutomationCalendarID) {
	let params = JSON.stringify({
		"AutomationCalendarID": AutomationCalendarID,
	});

	let url = URLs.base + URLs.GetAutomationCalendarAssets;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetAutomationCalendarAssetByID(ID) {
	let params = JSON.stringify({
		"ID": ID,
	});
	let url = URLs.base + URLs.GetAutomationCalendarAssetByID;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditAutomationSubscription(ID, AutomationCalendarID, CampaignID, PracticeID, FacebookUserID, FacebookID, Facebookusername, FacebookAccess_Token,
	InstagramID, Instagramusername, InstagramAccess_Token, BrandIDs, Assets) {
	let params = JSON.stringify({
		"ID": ID,
		"AutomationCalendarID": AutomationCalendarID,
		"CampaignID": CampaignID,
		"PracticeID": PracticeID,
		"FacebookUserID": FacebookUserID,
		"FacebookID": FacebookID,
		"Facebookusername": Facebookusername,
		"FacebookAccess_Token": FacebookAccess_Token != null && FacebookAccess_Token != '' && FacebookAccess_Token != undefined ? FacebookAccess_Token : null,
		"InstagramID": InstagramID,
		"Instagramusername": Instagramusername,
		"InstagramAccess_Token": InstagramAccess_Token != null && InstagramAccess_Token != '' && InstagramAccess_Token != undefined ? InstagramAccess_Token : null,
		"BrandIDs": BrandIDs,
		"Assets": Assets
	});

	let url = URLs.base + URLs.AddEditAutomationSubscription;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetAutomationSubscriptions(PracticeID) {
	let params = JSON.stringify({
		"PracticeID": PracticeID
	});

	let url = URLs.base + URLs.GetAutomationSubscriptions;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function GetScheduledPosts(ScheduledDate) {
	let params = JSON.stringify({ "ScheduledDate": ScheduledDate });

	let url = URLs.base + URLs.GetScheduledPosts;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function PostScheduledPost(ID, PostTypeID, ScheduledDate) {
	let params = JSON.stringify({
		"ID": ID,
		"PostTypeID": PostTypeID,
		"ScheduledDate": ScheduledDate
	});

	let url = URLs.base + URLs.PostScheduledPost;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function GetAutomationSubscriptionOrders(ID) {
	let params = JSON.stringify({ "ID": ID });

	let url = URLs.base + URLs.GetAutomationSubscriptionOrders;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function GetAutomationSubscriptionOrderCalendar(ID, Month, Year) {
	let params = JSON.stringify({
		"ID": ID,
		"Month": Month,
		"Year": Year
	});

	let url = URLs.base + URLs.GetAutomationSubscriptionOrderCalendar;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetAutomationSubscriptionByID(ID) {
	let params = JSON.stringify({ "ID": ID });

	let url = URLs.base + URLs.GetAutomationSubscriptionByID;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function GetAutomationCalendarForPractice(PracticeID, ID) {
	let params = JSON.stringify({
		'PracticeID': PracticeID.substring(PracticeID.lastIndexOf(',') + 1),
		'ID': ID
	});

	let url = URLs.base + URLs.GetAutomationCalendarForPractice;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function DeleteAutomationSubscription(ID) {
	let params = JSON.stringify({ "ID": ID });

	let url = URLs.base + URLs.DeleteAutomationSubscription;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditPaypalSubscriptionPlan(ID, Name, ProductID, PlanID, Cost, IsActive) {
	let params = JSON.stringify({
		"ID": ID,
		"Name": Name,
		"ProductID": ProductID,
		"PlanID": PlanID,
		"Cost": Cost,
		"IsActive": IsActive,
	});

	let url = URLs.base + URLs.AddEditPaypalSubscriptionPlan;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function GetPaypalSubscriptionPlans() {
	let params = JSON.stringify({});

	let url = URLs.base + URLs.GetPaypalSubscriptionPlans;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function DeletePaypalSubscriptionPlan(ID) {
	let params = JSON.stringify({
		"ID": ID
	});

	let url = URLs.base + URLs.DeletePaypalSubscriptionPlan;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function GetPaypalSubscriptionPlanForDropdown() {
	let params = JSON.stringify({});

	let url = URLs.base + URLs.GetPaypalSubscriptionPlanForDropdown;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetScreenBanners() {
	let params = JSON.stringify({});

	let url = URLs.base + URLs.GetScreenBanners;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function GetScreenForDropdown() {
	let params = JSON.stringify({});

	let url = URLs.base + URLs.GetScreenForDropdown;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function AddEditScreenBanner(ID, ScreenID, SequenceNo, ImageName, ImageData) {
	let params = JSON.stringify({
		"ID": ID,
		"ScreenID": ScreenID,
		"SequenceNo": SequenceNo,
		"ImageName": ImageName,
		"ImageData": ImageData,
		"ImageURL": ""
	});

	let url = URLs.base + URLs.AddEditScreenBanner;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetScreenBannerByID(ID) {
	let params = JSON.stringify({ "ID": ID });

	let url = URLs.base + URLs.GetScreenBannerByID;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function DeleteScreenBanner(ID) {
	let params = JSON.stringify({ "ID": ID });

	let url = URLs.base + URLs.DeleteScreenBanner;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function GetScreenBannerForDropdown(ScreenTypeID) {
	let params = JSON.stringify({ "ScreenTypeID": ScreenTypeID });

	let url = URLs.base + URLs.GetScreenBannerForDropdown;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditMyCustomPost(IsReschedule, ID, AutomationSubscriptionID, ScheduledAt, Description, ImageName, ImageData, PostToFacebook, PostToInstagram, IsFromLibrary) {
	let params = IsReschedule ? JSON.stringify({
		"ID": ID,
		"AutomationSubscriptionID": AutomationSubscriptionID,
		"ScheduledAt": ScheduledAt
	}) : JSON.stringify({
		"ID": ID,
		"AutomationSubscriptionID": AutomationSubscriptionID,
		"ScheduledAt": ScheduledAt,
		"Description": Description,
		"ImageName": ImageName,
		"ImageData": ImageData,
		"ImageURL": "",
		"PostToFacebook": PostToFacebook,
		"PostToInstagram": PostToInstagram,
		"IsFromLibrary": IsFromLibrary ? 1 : 0
	});

	let url = IsReschedule ? URLs.base + URLs.RescheduleMyCommonPost : URLs.base + URLs.AddEditMyCustomPost;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetCustomPostAdminFeed(StartDate, EndDate, IsFlagged, SortDirection, PracticeName) {
	let params = JSON.stringify({
		"StartDate": StartDate,
		"EndDate": EndDate,
		"IsFlagged": IsFlagged,
		"SortDirection": SortDirection,
		"PracticeName": PracticeName
	});

	let url = URLs.base + URLs.GetCustomPostAdminFeed;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function GetCampaignFreeAssetsForSocialAutomation(SegmentName, Name, CurrentPage, RecordPerPage, SortDirection) {
	let params = JSON.stringify({
		"SegmentName": SegmentName,
		"Name": Name,
		"CurrentPage": CurrentPage,
		"RecordPerPage": RecordPerPage,
		"SortDirection": SortDirection,
	});

	let url = URLs.base + URLs.GetCampaignFreeAssetsForSocialAutomation;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function FlagCustomPost(ID, IsFlagged) {
	let params = JSON.stringify({ "ID": ID, "IsFlagged": IsFlagged, });

	let url = URLs.base + URLs.FlagCustomPost;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function AddEditLandingPageTemplate(ID, Name, BrandID, HTMLContent, IsActive, IsDocFinder) {
	let params = JSON.stringify({
		"ID": ID,
		"Name": Name,
		"BrandID": BrandID,
		"HTMLContent": HTMLContent,
		"IsActive": IsActive,
		"IsDocFinder": IsDocFinder
	});

	let url = URLs.base + URLs.AddEditLandingPageAutomationTemplate;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function GetLandingPageAutomationTemplates() {
	let params = JSON.stringify({});

	let url = URLs.base + URLs.GetLandingPageAutomationTemplates;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function DeleteLandingPageAutomationTemplate(ID) {
	let params = JSON.stringify({ "ID": ID });

	let url = URLs.base + URLs.DeleteLandingPageAutomationTemplate;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function GetLandingPageAutomationTemplateByID(ID) {
	let params = JSON.stringify({ "ID": ID });

	let url = URLs.base + URLs.GetLandingPageAutomationTemplateByID;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function GetLandingPageUsers(CurrentPage, RecordPerPage, PracticeName, FullName, EmailAddress, ShipToNo, Postcode, Address, City) {
	let params = JSON.stringify({
		"PracticeName": PracticeName,
		"FullName": FullName,
		"EmailAddress": EmailAddress,
		"ShipToNo": ShipToNo,
		"Postcode": Postcode,
		"Address": Address,
		"City": City,
		"CurrentPage": CurrentPage,
		"RecordPerPage": RecordPerPage,
	});

	let url = URLs.base + URLs.GetLandingPageUsers;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function GenerateLandingPagesForTemplate(ID) {
	let params = JSON.stringify({ "ID": ID });

	let url = URLs.base + URLs.GenerateLandingPagesForTemplate;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function GenerateLandingPageForPractice(ID) {
	let params = JSON.stringify({ "ID": ID });

	let url = URLs.base + URLs.GenerateLandingPageForPractice;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function GetLandingPageTemplateLogs(TemplateID, PracticeID) {
	let params = JSON.stringify(PracticeID == 0 ? { "TemplateID": TemplateID } : { "PracticeID": PracticeID });

	let url = URLs.base + URLs.GetLandingPageTemplateLogs;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetMyLandingPageURLs() {
	let params = JSON.stringify({});

	let url = URLs.base + URLs.GetMyLandingPageURLs;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetLeads(CurrentPage, RecordPerPage, PracticeName, Location, Brand, LeadGeneratedAt, Type, EmailAddress) {
	let params = JSON.stringify(Type == undefined || Type == null || Type == "" ? {
		PracticeName: PracticeName,
		Location: Location,
		Brand: Brand,
		LeadGeneratedAt: LeadGeneratedAt,
		EmailAddress: EmailAddress,
		CurrentPage: CurrentPage,
		RecordPerPage: RecordPerPage,
		SortExpression: "PracticeName",
		SortDirection: "DESC"
	} : {
		PracticeName: PracticeName,
		Location: Location,
		Brand: Brand,
		LeadGeneratedAt: LeadGeneratedAt,
		Type: Type,
		EmailAddress: EmailAddress,
		CurrentPage: CurrentPage,
		RecordPerPage: RecordPerPage,
		SortExpression: "PracticeName",
		SortDirection: "DESC"
	});

	let url = URLs.base + URLs.GetLeads;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}


export function AddEditBanner(ID, SegmentID, ImageName, ImageData, ImageURL, IsFooter, IsActive) {
	let params = JSON.stringify({
		"ID": ID,
		"SegmentID": SegmentID,
		"ImageName": ImageName,
		"ImageData": ImageData,
		"ImageURL": ImageURL,
		"IsFooter": IsFooter,
		"IsActive": IsActive,
	})
	let URL = URLs.base + URLs.AddEditBanner;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, URL, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}


export function DeleteBanner(ID) {
	let params = JSON.stringify({ 'ID': ID })
	let URL = URLs.base + URLs.DeleteBanner;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, URL, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}


export function GetBannerByID(ID) {
	let params = JSON.stringify({ 'ID': ID })
	let URL = URLs.base + URLs.GetBannerByID;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, URL, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}


export function GetBanners(Segment, IsFooter, IsActive, CurrentPage, RecordPerPage, SortExpression, SortDirection) {
	let paramsInner = {};

	paramsInner.Segment = Segment;
	paramsInner.CurrentPage = CurrentPage;
	paramsInner.RecordPerPage = RecordPerPage;
	paramsInner.SortExpression = SortExpression;
	paramsInner.SortDirection = SortDirection;

	if (IsFooter != '') {
		paramsInner.IsFooter = IsFooter;
	}
	if (IsActive != '') {
		paramsInner.IsActive = IsActive;
	}

	let params = JSON.stringify(paramsInner);
	let URL = URLs.base + URLs.GetBanners;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, URL, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}


export function GetBannerForDropdown() {
	let params = JSON.stringify({

	})
	let URL = URLs.base + 'SupplyTools/GetBannerForDropdown';

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, URL, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}


export function AddEditConfiguration(ID, BrandID, SegmentID, LenseTypeID, PurchaseType, MinimumQuantityForAnnualSupply, Price) {
	let params = JSON.stringify({
		'ID': ID,
		'BrandID': BrandID,
		'SegmentID': SegmentID,
		'LenseTypeID': LenseTypeID,
		'PurchaseType': PurchaseType,
		'MinimumQuantityForAnnualSupply': MinimumQuantityForAnnualSupply,
		'Price': Price
	})
	let URL = URLs.base + URLs.AddEditConfiguration;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, URL, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}


export function DeleteConfiguration(ID) {
	let params = JSON.stringify({ 'ID': ID })
	let URL = URLs.base + URLs.DeleteConfiguration;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, URL, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}


export function GetConfigurationByID(ID) {
	let params = JSON.stringify({ 'ID': ID })
	let URL = URLs.base + URLs.GetConfigurationByID;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, URL, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}


export function GetConfigurations(MinimumQuantityForAnnualSupply, PurchaseType, Segment, Brand, LenseType, CurrentPage, RecordPerPage, SortExpression, SortDirection) {
	let params = JSON.stringify({
		'MinimumQuantityForAnnualSupply': MinimumQuantityForAnnualSupply,
		'PurchaseType': PurchaseType,
		'Segment': Segment,
		'Brand': Brand,
		'LenseType': LenseType,
		'CurrentPage': CurrentPage,
		'RecordPerPage': RecordPerPage,
		'SortExpression ': SortExpression,
		'SortDirection': SortDirection
	})
	let URL = URLs.base + URLs.GetConfigurations;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, URL, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}


export function AddEditSupplyCostCalculatorLead(ID, LeadType, PatientName, PatientEmailAddress, PurchaseType, IsDailyLense, ImageName, ImageData,
	L_LenseTypeID, L_TotalBoxes, L_RetailPrice, L_RebatePrice, L_AdditionalDiscount, L_InsuranceCoverage, L_PricePerBox, L_R_PricePerBox, L_YouPay,
	R_LenseTypeID, R_TotalBoxes, R_RetailPrice, R_RebatePrice, R_AdditionalDiscount, R_InsuranceCoverage, R_L_PricePerBox, R_PricePerBox, R_YouPay, LeadAt) {
	let params = JSON.stringify({
		"ID": ID,
		"LeadType": LeadType,
		"PatientName": PatientName,
		"PatientEmailAddress": PatientEmailAddress,
		"PurchaseType": PurchaseType,
		"IsDailyLense": IsDailyLense,
		"ImageName": ImageName,
		"ImageData": ImageData,
		"ImageURL": "",

		"L_LenseTypeID": L_LenseTypeID,
		"L_TotalBoxes": L_TotalBoxes,
		"L_RetailPrice": L_RetailPrice,
		"L_RebatePrice": L_RebatePrice,
		"L_AdditionalDiscount": L_AdditionalDiscount,
		"L_InsuranceCoverage": L_InsuranceCoverage,
		"L_PricePerBox": L_PricePerBox,
		"L_R_PricePerBox": L_R_PricePerBox,
		"L_YouPay": L_YouPay,

		"R_LenseTypeID": R_LenseTypeID,
		"R_TotalBoxes": R_TotalBoxes,
		"R_RetailPrice": R_RetailPrice,
		"R_RebatePrice": R_RebatePrice,
		"R_AdditionalDiscount": R_AdditionalDiscount,
		"R_InsuranceCoverage": R_InsuranceCoverage,
		"R_L_PricePerBox": R_L_PricePerBox,
		"R_PricePerBox": R_PricePerBox,
		"R_YouPay": R_YouPay,
		"LeadAt": LeadAt
	})
	let URL = URLs.base + URLs.AddEditSupplyCostCalculatorLead;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, URL, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}


export function AddEditLead(ID, LeadType, LenseTypeID, PurchaseType, PricePerBox, TotalBoxes, TotalPrice, RebateValue, FinalCostofLenses,
	AdditionalDiscount, InsuranceCoverage, YouPay, FinalPricePerBox, PatientName, PatientEmailAddress, ImageName, ImageData) {
	let params = JSON.stringify({
		"ID": ID,
		"LeadType": LeadType,
		"LenseTypeID": LenseTypeID,
		"PurchaseType": PurchaseType,
		"PricePerBox": PricePerBox,
		"TotalBoxes": TotalBoxes,
		"TotalPrice": TotalPrice,
		"RebateValue": RebateValue,
		"FinalCostofLenses": FinalCostofLenses,
		"AdditionalDiscount": AdditionalDiscount,
		"InsuranceCoverage": InsuranceCoverage,
		"YouPay": YouPay,
		"FinalPricePerBox": FinalPricePerBox,
		"PatientName": PatientName,
		"PatientEmailAddress": PatientEmailAddress,
		"ImageName": ImageName,
		"ImageData": ImageData,
		"ImageURL": ""
	})
	let URL = URLs.base + URLs.AddEditLead;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, URL, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}


export function DeleteLead(ID) {
	let params = JSON.stringify({
		'ID': ID
	})
	let URL = URLs.base + 'SupplyTools/DeleteLead';

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, URL, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}


export function GetLeadByID(ID) {
	let params = JSON.stringify({
		'ID': ID
	})
	let URL = URLs.base + 'SupplyTools/GetLeadByID';

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, URL, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function GetSupplyLeads(LeadAt, PracticeName, PurchaseType, LeftLenseType, RightLenseType,
	L_RetailPrice, L_RebatePrice, L_AdditionalDiscount, L_InsuranceCoverage, L_PricePerBox, L_R_PricePerBox, L_YouPay,
	R_RetailPrice, R_RebatePrice, R_AdditionalDiscount, R_InsuranceCoverage, R_L_PricePerBox, R_PricePerBox, R_YouPay,
	CurrentPage, RecordPerPage, SortExpression, SortDirection) {
	let params = JSON.stringify({
		"LeadAt": LeadAt,
		"PracticeName": PracticeName,
		"PurchaseType": PurchaseType == "" ? null : PurchaseType,
		"LeftLenseType": LeftLenseType,
		"RightLenseType": RightLenseType,

		"L_RetailPrice": L_RetailPrice,
		"L_RebatePrice": L_RebatePrice,
		"L_AdditionalDiscount": L_AdditionalDiscount,
		"L_InsuranceCoverage": L_InsuranceCoverage,
		"L_PricePerBox": L_PricePerBox,
		"L_R_PricePerBox": L_R_PricePerBox,
		"L_YouPay": L_YouPay,

		"R_RetailPrice": R_RetailPrice,
		"R_RebatePrice": R_RebatePrice,
		"R_AdditionalDiscount": R_AdditionalDiscount,
		"R_InsuranceCoverage": R_InsuranceCoverage,
		"R_L_PricePerBox": R_L_PricePerBox,
		"R_PricePerBox": R_PricePerBox,
		"R_YouPay": R_YouPay,

		'CurrentPage': CurrentPage,
		'RecordPerPage': RecordPerPage,
		'SortExpression': SortExpression,
		'SortDirection': SortDirection
	})
	let URL = URLs.base + URLs.GetSupplyCostCalculatorLead;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, URL, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}


export function AddEditLensesType(ID, BrandID, Name, SequenceNo, IsActive, IsDailyLense) {
	let params = JSON.stringify({
		'ID': ID,
		'BrandID': BrandID,
		'Name': Name,
		'SequenceNo': SequenceNo,
		'IsActive': IsActive,
		'IsDailyLense': IsDailyLense
	})
	let URL = URLs.base + URLs.AddEditLensesType;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, URL, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}


export function DeleteLensesType(ID) {
	let params = JSON.stringify({
		'ID': ID
	})
	let URL = URLs.base + URLs.DeleteLensesType;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, URL, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}


export function GetLensesTypeByID(ID) {
	let params = JSON.stringify({ 'ID': ID })
	let URL = URLs.base + URLs.GetLensesTypeByID;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, URL, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function GetLensesTypes(BrandName, Name, SequenceNo, IsActive, IsDailyLense, CurrentPage, RecordPerPage, SortExpression, SortDirection) {
	let params = JSON.stringify(IsActive != "" ? {
		'BrandName': BrandName,
		'Name': Name,
		'SequenceNo': SequenceNo,
		'IsActive': IsActive,
		'IsDailyLense': IsDailyLense,
		'CurrentPage': CurrentPage,
		'RecordPerPage': RecordPerPage,
		'SortExpression ': SortExpression,
		'SortDirection': SortDirection
	} : {
		'BrandName': BrandName,
		'Name': Name,
		'SequenceNo': SequenceNo,
		'IsDailyLense': IsDailyLense,
		'CurrentPage': CurrentPage,
		'RecordPerPage': RecordPerPage,
		'SortExpression ': SortExpression,
		'SortDirection': SortDirection
	})

	let url = URLs.base + URLs.GetLensesTypes;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}


export function GetLensesTypeForDropdown() {
	let params = JSON.stringify({})
	let URL = URLs.base + URLs.GetLensesTypeForDropdown;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, URL, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function GetScreenConfigurations() {
	let params = JSON.stringify({})
	let URL = URLs.base + URLs.GetScreenConfigurations;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, URL, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function GetSupplyCostCalculatorLead(PatientName, PatientEmailAddress, PurchaseType, LeftLenseType, RightLenseType, LeadAt, CurrentPage, RecordPerPage) {
	let params = JSON.stringify({
		"PatientName": PatientName,
		"PatientEmailAddress": PatientEmailAddress,
		"PurchaseType": PurchaseType,
		"LeftLenseType": LeftLenseType,
		"RightLenseType": RightLenseType,
		"LeadAt": LeadAt,
		"CurrentPage": CurrentPage,
		"RecordPerPage": RecordPerPage,
		"SortExpression": "LeadAt",
		"SortDirection": "DESC",
	})
	let URL = URLs.base + URLs.GetSupplyCostCalculatorLead;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, URL, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}


export function GetSupplyToolReportSummary(StartDate, EndDate) {
	let params = JSON.stringify({
		"StartDate": StartDate,
		"EndDate": EndDate
	})
	let URL = URLs.base + URLs.GetSupplyToolReportSummary;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, URL, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}



export function ImportVTMContactList(EmailsArray) {
	let params = JSON.stringify({ JSON: EmailsArray })
	let URL = URLs.base + URLs.ImportVTMContactList;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, URL, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function GetVTMContactList(FullName, EmailAddress, VTMEmailAddress, ImportedAt, CurrentPage, RecordPerPage) {
	let params = JSON.stringify({
		"FullName": FullName,
		"EmailAddress": EmailAddress,
		"VTMEmailAddress": VTMEmailAddress,
		"ImportedAt": ImportedAt,
		"CurrentPage": CurrentPage,
		"RecordPerPage": RecordPerPage,
		"SortExpression": "FullName",
		"SortDirection": "ASC"
	})
	let URL = URLs.base + URLs.GetVTMContactList;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, URL, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response && response.data) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function GetNegativeKeywordByID(ID) {
	let params = JSON.stringify({ "ID": ID });
	let url = URLs.base + URLs.GetNegativeKeywordByID;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function GetNegativeKeywords(Name, CurrentPage, RecordPerPage) {
	let params = JSON.stringify({
		"Name": Name,
		"CurrentPage": CurrentPage,
		"RecordPerPage": RecordPerPage,
		"SortExpression": "Name",
		"SortDirection": "DESC"
	});
	let url = URLs.base + URLs.GetNegativeKeywords;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}
export function DeleteNegativeKeyword(ID) {
	let params = JSON.stringify({ "ID": ID });
	let url = URLs.base + URLs.DeleteNegativeKeyword;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditNegativeKeyword(ID, Name) {
	let params = JSON.stringify({
		"ID": ID,
		"Name": Name
	});
	let url = URLs.base + URLs.AddEditNegativeKeyword;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function EmailFlaggedSocialPost(AutomationSubscriptionID, AutomationSubscriptionDetailsID, EmailTo, EmailCC, EmailBCC, EmailSubject, EmailBody,
	Attachment1_ImageName, Attachment1_ImageData, Attachment2_ImageName, Attachment2_ImageData, Attachment3_ImageName, Attachment3_ImageData) {
	let params = JSON.stringify({
		"AutomationSubscriptionID": AutomationSubscriptionID,
		"AutomationSubscriptionDetailsID": AutomationSubscriptionDetailsID,
		"EmailTo": EmailTo,
		"EmailCC": EmailCC,
		"EmailBCC": EmailBCC,
		"EmailSubject": EmailSubject,
		"EmailBody": EmailBody,
		"Attachment1_ImageURL": "",
		"Attachment1_ImageName": Attachment1_ImageName,
		"Attachment1_ImageData": Attachment1_ImageData,
		"Attachment2_ImageURL": "",
		"Attachment2_ImageName": Attachment2_ImageName,
		"Attachment2_ImageData": Attachment2_ImageData,
		"Attachment3_ImageURL": "",
		"Attachment3_ImageName": Attachment3_ImageName,
		"Attachment3_ImageData": Attachment3_ImageData
	});
	let url = URLs.base + URLs.EmailFlaggedSocialPost;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetSupplyToolReportByPricePerBrand(PracticeName, LenseType, ReportYear) {
	let params = JSON.stringify({
		"PracticeName": PracticeName,
		"LenseType": LenseType,
		"ReportYear": ReportYear
	});
	let url = URLs.base + URLs.GetSupplyToolReportByPricePerBrand;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function AddEditWelcomePopup(ID, Name, SegmentID, Description, ImageName, ImageData) {
	let params = JSON.stringify({
		"ID": ID,
		"Name": Name,
		"SegmentID": SegmentID,
		"Description": Description,
		"ImageName": ImageName,
		"ImageData": ImageData,
		"ImageURL": "",
	});
	let url = URLs.base + URLs.AddEditWelcomePopup;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetWelcomePopup() {
	let params = JSON.stringify({});
	let url = URLs.base + URLs.GetWelcomePopup;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function DeleteWelcomePopup(ID) {
	let params = JSON.stringify({ "ID": ID });
	let url = URLs.base + URLs.DeleteWelcomePopup;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetWelcomePopupByID(ID) {
	let params = JSON.stringify({ "ID": ID });
	let url = URLs.base + URLs.GetWelcomePopupByID;

	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}

export function GetWelcomePopupSegmentForDropdown() {
	let params = JSON.stringify({ "GetAllSegments": 1 });
	let url = URLs.base + URLs.GetSegmentForDropdown;
	return new Promise((resolve, reject) => {
		API.shared
			.request(HTTPMethod.post, url, params)
			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(
						response.StatusCode,
						response.Message
					);
					resolve(new APIControllerResponse(null, error));
				} else if (response && response.data) {
					resolve(new APIControllerResponse(response, null));
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error));
			});
	});
}