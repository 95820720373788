/*eslint-disable eqeqeq*/
import React from "react";
import { NavLink } from "react-router-dom";
import authService from "../../../shared/services/auth-service";
import PopoverComponent from "../../../shared/components/popover/popover";
import icon_cart from "../../../assets/images/shopping-cart.png";
import icon_order from "../../../assets/images/ic_orders_grey.svg";
import profile_generic from "../../../assets/images/profile_generic1.png";
import ic_notification from "../../../assets/images/notification-icon.png";
import ic_edit from "../../../assets/images/ic_edit.svg";
import ic_right_arrow from "../../../assets/images/right-arrow.png";
import ic_down_arrow from "../../../assets/images/down.png";
import ic_setting from "../../../assets/images/settings.svg";
import ic_contactus from "../../../assets/images/ic_Contactus.svg";
import ic_location from "../../../assets/images/ic_Location.svg";
import ic_landing_page from "../../../assets/images/ic_Landing_Pages.svg";
import ic_calculator_grey from '../../../assets/images/ic_calculator_grey.svg'
import ic_power_btn from "../../../assets/images/power-button-ic.svg";
import ic_miscellenous from "../../../assets/images/ic_miscellenous.svg";
import ic_subscription_grey from '../../../assets/images/ic_subscription_grey.svg';

import { ReactComponent as Ic_dashboard } from '../../../assets/images/analytics-icon-small-grey.svg';
import { ReactComponent as Ic_order } from '../../../assets/images/ic_orders.svg';
import { ReactComponent as Ic_coin } from "../../../assets/images/coin.svg";
import { ReactComponent as Ic_admin } from '../../../assets/images/ic_admin.svg';
import { ReactComponent as Ic_subscription } from '../../../assets/images/ic_subscription.svg';
import { ReactComponent as Ic_calculator } from '../../../assets/images/ic_calculator.svg';
import { ReactComponent as IcDropdown } from '../../../assets/images/dropdown-arrow.svg';
import { ReactComponent as IcDown } from '../../../assets/images/caret-down-fill.svg'

import Modal from "../../../shared/components/modal/modal";
import ChangePassword from "../../ChangePassword/component/change-password";
import EditProfile from "../../EditProfile/component/edit-profile";
import * as APIController from '../../../api/APIController.js';
import * as AppConstants from "../../../config/AppConstant";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const Header = (props) => {
	let history = useHistory();
	const [dropdown, setDropdown] = React.useState("");
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [IsModalOpen, setIsModalOpen] = React.useState(false)
	const [IsProfile, setIsProfile] = React.useState(false)
	const [notificationDataSource, setNotificationDataSource] = React.useState([]);
	const [notificationCount, setNotificationCount] = React.useState("0");
	const [openSubmenu, setOpenSubmenu] = React.useState(false);
	const [openPraticeSubmenu, setopenPraticeSubmenu] = React.useState(false);

	const [subscriptionsData, setSubscriptionsData] = React.useState([]);

	const handleClick = (event, tag) => {
		setDropdown(tag);
		setAnchorEl(event.currentTarget);
	};
	const handleClickCoOp = (event, tag) => {
		handleClick(event, tag)
		localStorage.setItem("sideBarMenuAdmin", 1)
	};
	const handleClose = () => {
		setAnchorEl(null);
		setOpenSubmenu(false)
	};
	const handleOpenModal = (value) => {
		setIsProfile(value)
		setIsModalOpen(true)
		handleClose()
	};
	const handleCloseModal = () => {
		setIsModalOpen(false)
	};

	React.useEffect(() => {
		wsGetNotifications();
		if (AppConstants.GetLocalStorage("localStorageSubscriptions") != undefined
			&& AppConstants.GetLocalStorage("localStorageSubscriptions") != null
			&& AppConstants.GetLocalStorage("localStorageSubscriptions") != "") {
			setSubscriptionsData(JSON.parse(AppConstants.GetLocalStorage("localStorageSubscriptions")))
		}
	}, [])

	const wsGetNotifications = () => {
		APIController.GetNotifications("", "", "", 5, 1)
			.then((response) => {
				if (response.object.data.length) {
					setNotificationDataSource(response.object.data);
					setNotificationCount(response.object.data[0].MaxRows);
				}
			})
	}

	const wsGetAutomationSubscriptionByID = (ID) => {
		APIController.GetAutomationSubscriptionByID(ID).then((response) => {
			if (response.object.data != null && response.object.data != undefined && response.object.data != "") {
				if (response.object.data.SubscriptionDetails[0].FacebookUserID != 'null') {

					if (response.object.data.SubscriptionDetails[0].FacebookID != null
						&& response.object.data.SubscriptionDetails[0].FacebookID != ''
						&& response.object.data.SubscriptionDetails[0].FacebookID != undefined) {
						AppConstants.SetLocalStorage("localStorageFBPageData", JSON.stringify({ "practiceID": response.object.data.SubscriptionDetails[0].PracticeID, 'id': response.object.data.SubscriptionDetails[0].FacebookID, 'name': response.object.data.SubscriptionDetails[0].FacebookUsername }));
					} else { AppConstants.SetLocalStorage("localStorageFBPageData", ''); }

					if (response.object.data.SubscriptionDetails[0].InstagramID != null
						&& response.object.data.SubscriptionDetails[0].InstagramID != ''
						&& response.object.data.SubscriptionDetails[0].InstagramID != undefined) {
						AppConstants.SetLocalStorage("localStorageInstaUserData", JSON.stringify({ "practiceID": response.object.data.SubscriptionDetails[0].PracticeID, 'id': response.object.data.SubscriptionDetails[0].InstagramID, 'username': response.object.data.SubscriptionDetails[0].InstagramUsername }));
					} else { AppConstants.SetLocalStorage("localStorageInstaUserData", ''); }

					SaveIsReviewNeededToLocalStorage(response.object.data.SubscriptionDetails[0].PracticeID, "",
						response.object.data.SubscriptionDetails[0].FacebookID, response.object.data.SubscriptionDetails[0].FacebookUsername,
						response.object.data.SubscriptionDetails[0].InstagramID, response.object.data.SubscriptionDetails[0].InstagramUsername)

				} else { AppConstants.SetLocalStorage("localStorageFBPageData", ''); AppConstants.SetLocalStorage("localStorageInstaUserData", ''); }

				AppConstants.SetLocalStorage("localStorageAutomationSubscriptionID", response.object.data.SubscriptionDetails[0].ID)
				AppConstants.SetLocalStorage("localStoragepracticeId", response.object.data.SubscriptionDetails[0].PracticeID)
				AppConstants.SetLocalStorage("localStorageAutomation", "YES")
				AppConstants.SetLocalStorage("localStorageBudgetDetail", JSON.stringify([{ "budget": 1, "duration": 1 }]))
				AppConstants.SetLocalStorage("localStorageMultiSelectPractice", 0);
				AppConstants.SetLocalStorage('IsDirectPageURL', "3");
				AppConstants.SetLocalStorage("localStorageCalenderCart", "1")
				if (localStorage.getItem("localStorageIsFromHeader") == 0) {
					// history.push('/calendar');
					let TempBrandIDs = response.object.data.SubscriptionDetails[0].BrandIDs != '' && response.object.data.SubscriptionDetails[0].BrandIDs.split(',')
					let BrandIDs = []

					if (TempBrandIDs != '' && TempBrandIDs != undefined && TempBrandIDs != null) {
						TempBrandIDs.map((item) => {
							BrandIDs.push({ 'Checked': true, ID: item })
						})
					}
					AppConstants.SetLocalStorage("localStorageBrandsData", JSON.stringify(BrandIDs))
					history.push({ pathname: "/subcription/calendar", state: { calendarBack: false } })
				} else {
					history.push('/subcription/digital-product/selection/location-selection');
				}
			}
			setOpenSubmenu(false)
		});
	}

	const SaveIsReviewNeededToLocalStorage = (PracticeID, FacebookAccessToken, FacebookID, FacebookUserName, InstagramID, InstagramUserName) => {

		const AllLocationsWithoutCurrentPractice = []

		AllLocationsWithoutCurrentPractice.push({
			'PracticeID': PracticeID,
			'FacebookAccessToken': FacebookAccessToken,
			'FacebookID': FacebookID,
			'FacebookUserName': FacebookUserName,
			'InstagramID': InstagramID,
			'InstagramUserName': InstagramUserName
		})
		localStorage.setItem("SocialAutomationAllLocations", JSON.stringify(AllLocationsWithoutCurrentPractice));
	}


	const handlesubcrition = () => {
		if (!openSubmenu) {
			setOpenSubmenu(true);
		} else {
			setOpenSubmenu(false);
		}
	}

	const handlePratice = () => {
		if (!openPraticeSubmenu) {
			setopenPraticeSubmenu(true);
		} else {
			setopenPraticeSubmenu(false);
		}
	}

	return (
		<header className='site-header' id='site-header'>
			<div className="head-top-data">
				Welcome {localStorage.getItem("UserName")}
			</div>

			<div className='header-left'>
				<div className='site-logo'>
					<NavLink to={AppConstants.HomepageURL} title='' onClick={props.handleMenu} >
						<img src={AppConstants.HeaderLogo} alt={AppConstants.HeaderLogo} />
					</NavLink>
				</div>
			</div>
			<div className='header-right'>
				{localStorage.getItem("headerMenuAdmin") != 1 &&
					<div className="header-nav">
						<ul>
							<li className='second-step'>
								<NavLink to='/annual-supply-calculator' style={{ cursor: 'pointer' }} activeClassName="active">
									<Ic_calculator style={{ marginBottom: "5px", marginRight: '0px' }} /><br />ANNUAL SUPPLY COST TOOL</NavLink>
							</li>
						</ul>
					</div>}

				{localStorage.getItem("headerMenuAdmin") != 1 &&
					<div className="header-nav">
						<ul>
							<li className='second-step' >
								<div className="active-dropdown" onClick={(e) => handleClick(e, "analyticsDashboard")}>
									<NavLink to='/analytics' style={{ cursor: 'pointer', pointerEvents: 'none', }}
										title='' onClick={(e) => { localStorage.setItem("sideBarMenuAdmin", 1); }} activeClassName="active">
										<Ic_dashboard style={{ marginBottom: "5px", marginRight: '0px' }} /><br />ANALYTICS <IcDown />
									</NavLink>
								</div>
							</li>
						</ul>
					</div>}

				{localStorage.getItem("headerMenuAdmin") == 1 &&
					<div className="header-nav">
						<ul>
							<li className="second-step" id='second-step'>
								<NavLink to='/assetdownloadreport' style={{ fontWeight: "bold" }} onClick={() => { localStorage.setItem("sideBarMenuAdmin", 1) }} activeClassName="active">
									<Ic_order style={{ marginBottom: "5px", marginRight: '0px' }} /><br />REPORTS</NavLink>
							</li>
						</ul>
					</div>}

				{subscriptionsData.length ? AppConstants.GetMainClassName() == "app-main" && localStorage.getItem("headerMenuAdmin") != 1 &&
					<div className="header-nav">
						<ul>
							<li className="second-step" id='second-step'>
								<div className="active-dropdown" onClick={(e) => { setopenPraticeSubmenu(false); handleClick(e, "subscription"); setOpenSubmenu(false); }} >
									<NavLink to='/subcription' style={{ cursor: 'pointer'}}
										title=''
										onClick={(e) => { localStorage.setItem("sideBarMenuAdmin", 1); }} activeClassName="active">
										<Ic_subscription style={{ marginBottom: "5px", marginRight: '0px' }} /><br />SUBSCRIPTIONS <IcDown />
									</NavLink>
								</div>
							</li>
						</ul>
					</div> : <></>}

				{/* {localStorage.getItem("headerMenuCoOp") == 1 &&
					<div className="header-nav header-nav-no second-step">
						<ul>
							<li>
								<a activeClassName="active">
									<Ic_coin style={{ marginBottom: "5px", marginRight: '0px' }} /> <br /> Co-Op: {localStorage.getItem("headerMenuCoOpAmount")}
								</a>
							</li>
						</ul>
					</div>} */}

				{localStorage.getItem("headerMenuAdmin") == 1 &&
					<div className="header-nav">
						<ul>
							<li className="second-step" >
								<NavLink to='/admin' style={{ fontWeight: "bold" }} {...props}
									onClick={() => { localStorage.setItem("sideBarMenuAdmin", 0) }}>
									<Ic_admin style={{ marginBottom: "5px", marginRight: '0px' }} /><br />admin</NavLink>
							</li>
						</ul>
					</div>}
				<div className='right-icons' >
					<ul>
						{localStorage.getItem("headerMenuAdmin") != 1 ?
							<li>
								<NavLink to='/cart' className="third-step">
									{AppConstants.GetLocalStorage("localStorageCartCount") != 0
										&& <span className='icon-status'>{AppConstants.GetLocalStorage("localStorageCartCount")}</span>}
									<img src={icon_cart} alt='icon' />
								</NavLink>
							</li> : ''}
						<li>
							<a style={{ cursor: 'pointer' }}
								title=''
								onClick={(e) => handleClick(e, "notification")}>
								{notificationCount != 0 && <span className='icon-status'>{notificationCount}</span>}
								<img src={ic_notification} alt='icon' />
							</a>
						</li>
					</ul>
				</div>
				<div className='right-icons' style={{ padding: '0px' }} />
				<div className='account-info fourth-step ' style={{ padding: '0px', marginRight: '15px', }}>
					<a title='' className='MuiButton-label' onClick={(e) => handleClick(e, "profile")}>
						<img src={profile_generic} alt='profile' />
					</a>
					<PopoverComponent
						anchorEl={anchorEl}
						className={`${dropdown === "profile" ? "profile-menu" : dropdown === "analyticsDashboard" ? "analyticsDashboard" : dropdown === "subscription" ? "subscription" : "notification"}`}
						handleClose={handleClose}
						id={"simple-popover"}>
						{dropdown === "profile"
							? <ul className='profile-ul'>
								<li>
									<NavLink to="/my-account" onClick={() => setAnchorEl(null)} >
										{<img src={ic_edit} alt='ic_edit' />}
										{"User Profile"}
									</NavLink>
								</li>
								{AppConstants.GetLocalStorage("localStorageIsVTM") == 1 ?
									<>
										<li>
											<NavLink to='/dashboard' onClick={() => { localStorage.setItem("sideBarMenuAdmin", 1) }}>
												<Ic_dashboard style={{ height: '16px', width: '16px' }} />Dashboard</NavLink>
										</li>
										<li onClick={() => { AppConstants.SetLocalStorage("localStorageIsVTM", 0); }}>
											<NavLink to='/home' >
												<Ic_dashboard style={{ height: '16px', width: '16px' }} />Practice Demo</NavLink>
										</li>
									</>
									: <>
										{AppConstants.IsGoForLive && <li onClick={() => {
											AppConstants.SetLocalStorage("localStorageBudgetDetail", JSON.stringify([{ budget: '', duration: '0' }]));
											AppConstants.SetLocalStorage("localStorageAutomation", "NO");
											AppConstants.SetLocalStorage("localStorageIsFromHeader", 0)
										}}>
											<NavLink to="/my-landing-page"
												onClick={() => setAnchorEl(null)} >
												{<img src={ic_landing_page} alt='icon' style={{ height: '16px', width: '16px' }} />}
												{'My Landing Pages'}
											</NavLink>
										</li>}
										<li onClick={() => {
											AppConstants.SetLocalStorage("localStorageBudgetDetail", JSON.stringify([{ budget: '', duration: '0' }]));
											AppConstants.SetLocalStorage("localStorageAutomation", "NO");
											AppConstants.SetLocalStorage("localStorageIsFromHeader", 1);
											AppConstants.SetLocalStorage("localStoragepracticeId", 0);
										}}>
											<NavLink to="/digital-product/selection/location-selection"
												onClick={() => setAnchorEl(null)} >
												{<img src={ic_location} alt='icon' style={{ height: '16px', width: '16px' }} />}
												{'My Locations'}
											</NavLink>
										</li>
										<li>
											<NavLink to="/my-account" onClick={() => setAnchorEl(null)} >
												{<img src={ic_calculator_grey} alt='ic_edit' style={{ height: '16px', width: '16px' }} />}
												{"Calculator Profile"}
											</NavLink>
										</li>
										<li>
											<NavLink to="/order" onClick={() => { setAnchorEl(null); localStorage.setItem("sideBarMenuAdmin", 1); }} >
												{<img src={icon_order} alt='ic_edit' style={{ height: '16px', width: '16px' }} />}
												{"Orders"}
											</NavLink>
										</li>
									</>}

								{AppConstants.GetMainClassName() == "app-main" && <li>
									<a onClick={() => handleOpenModal(false)} title='' >
										{<img src={ic_setting} alt='icon' />}
										{'Change Password'}
									</a>
								</li>}
								<li>
									<NavLink to="/contactus" onClick={() => setAnchorEl(null)} >
										{<img src={ic_contactus} alt='icon' style={{ height: '16px', width: '16px' }} />}
										{'Contact Us'}
									</NavLink>
								</li>
								<li>
									<a href='#!' title='' onClick={handleLogout}>
										{<img src={ic_power_btn} alt='icon' />}
										{'Logout'}
									</a>
								</li>
							</ul>
							: dropdown === "subscription" ?
								<ul className='profile-ul'>
									<li>
										{subscriptionsData.length == 1 ?
											<div onClick={() => { handleClose(); AppConstants.SetLocalStorage("localStorageIsFromHeader", 0); wsGetAutomationSubscriptionByID(subscriptionsData[0].ID); }}>
												<NavLink to='/subcription/calendar' title='' style={{ pointerEvents: 'none' }} activeClassName="submenu-active" >
													<div style={{ display: "flex", width: "100%" }} >
														<div style={{ width: "8%" }}>
															<img src={ic_edit} alt='icon' />
														</div>
														<div style={{ width: "85%" }}>
															{"View/Edit Social Calendar"}&nbsp;&nbsp;
														</div>
														<div style={{ width: "7%" }}>
															{openSubmenu ? <img src={ic_down_arrow} alt='icon' /> : <img src={ic_right_arrow} alt='icon' />}
														</div>
													</div>

													{/* <img src={ic_edit} alt='icon' /> {"View/Edit Social Calendar"} */}
												</NavLink></div> :

											<div onClick={() => { handlesubcrition(); setopenPraticeSubmenu(false); AppConstants.SetLocalStorage("localStorageIsFromHeader", 0); }}>
												<NavLink to='/subcription/calendar' title='' style={{ pointerEvents: 'none' }} activeClassName="submenu-active" >
													<div style={{ display: "flex", width: "100%" }} >
														<div style={{ width: "8%" }}>
															<img src={ic_edit} alt='icon' />
														</div>
														<div style={{ width: "85%" }}>
															{"View/Edit Social Calendar"}&nbsp;&nbsp;
														</div>
														<div style={{ width: "7%" }}>
															{openSubmenu ? <img src={ic_down_arrow} alt='icon' /> : <img src={ic_right_arrow} alt='icon' />}
														</div>
													</div>
												</NavLink></div>}
										<ul className={openSubmenu ? "subscription-submenu" : "display-none"} >
											{subscriptionsData.map((obj) =>
												<li className="subscription-li" >
													<NavLink to=""
														onClick={() => { handleClose(); wsGetAutomationSubscriptionByID(obj.ID); }} >
														&#x2022; &nbsp; {obj.Address}
													</NavLink>
												</li>
											)}
										</ul>
									</li>
									<li onClick={() => {
										if (subscriptionsData.length == 1) {
											AppConstants.SetLocalStorage("localStorageBudgetDetail", JSON.stringify([{ budget: 1, duration: '' }]));
											AppConstants.SetLocalStorage("localStorageMultiSelectPractice", 0);
											AppConstants.SetLocalStorage("localStorageAutomation", "YES");
											AppConstants.SetLocalStorage("localStoragepracticeId", 0);
											AppConstants.SetLocalStorage('localStorageOrderDetailsID', 0);
											AppConstants.SetLocalStorage("localStorageFromCart", 1)
											AppConstants.SetLocalStorage("localStorageFBPageData", '');
											AppConstants.SetLocalStorage("localStorageInstaUserData", '');
										}
									}}>
										{subscriptionsData.length == 1 ?
											<div onClick={() => { handleClose(); AppConstants.SetLocalStorage("localStorageIsFromHeader", 1); wsGetAutomationSubscriptionByID(subscriptionsData[0].ID); }}>
												<NavLink to='/subcription/pratice' style={{ pointerEvents: 'none' }} title='' activeClassName="pratice-submenu-active">
													<div style={{ display: "flex", width: "100%" }} >
														<div style={{ width: "8%" }}>
															<img src={ic_location} alt='location' style={{ height: '16px', width: '16px' }} />
														</div>
														<div style={{ width: "85%" }}>
															{"Update Practice Info/Credentials"}
														</div>
														<div style={{ width: "7%" }}>
															{openPraticeSubmenu ? <img src={ic_down_arrow} alt='icon' /> : <img src={ic_right_arrow} alt='icon' />}
														</div>
													</div>
												</NavLink></div>
											:
											<div onClick={() => { handlePratice(); setOpenSubmenu(false); AppConstants.SetLocalStorage("localStorageIsFromHeader", 1); }} >
												<NavLink to='/subcription/pratice' title='' style={{ pointerEvents: 'none' }} activeClassName="pratice-submenu-active">
													<div style={{ display: "flex", width: "100%" }} >
														<div style={{ width: "8%" }}>
															<img src={ic_location} alt='location' style={{ height: '16px', width: '16px' }} />
														</div>
														<div style={{ width: "85%" }}>
															{"Update Practice Info/Credentials"}
														</div>
														<div style={{ width: "7%" }}>
															{openPraticeSubmenu ? <img src={ic_down_arrow} alt='icon' /> : <img src={ic_right_arrow} alt='icon' />}
														</div>
													</div>
												</NavLink></div>}
										<ul className={openPraticeSubmenu ? "pratice-submenu" : "display-none"} >
											{subscriptionsData.map((obj) =>
												<li className="subscription-li" >
													<NavLink to=""
														onClick={() => { handleClose(); wsGetAutomationSubscriptionByID(obj.ID); }} >
														&#x2022; &nbsp; {obj.Address}
													</NavLink>
												</li>
											)}
										</ul>
									</li>
									<li>
										<NavLink to="/subcription/subscriptions" onClick={() => setAnchorEl(null)} activeClassName="submenu-active" >
											{<img src={ic_setting} alt="ic_edit" />}
											{"Subscription Details"}
										</NavLink>
									</li>
								</ul>
								: dropdown === "analyticsDashboard" ?
									<ul className='profile-ul'>
										<li>
											<NavLink to="/analytics/supply-calculator-leads" onClick={() => setAnchorEl(null)} activeClassName="supply-active">
												{"Cost Calculator"}
											</NavLink>
										</li>
										<li>
											<NavLink to="/landingpage/dashboard" onClick={() => setAnchorEl(null)} activeClassName="submenu-active" >
												{"Organic Traffic"}
											</NavLink>
										</li>
										<li>
											<NavLink to="/analytics/dashboard" onClick={() => setAnchorEl(null)} activeClassName="submenu-active">
												{"Paid Campaigns"}
											</NavLink>
										</li>
									</ul>
									: <>
										<h6>{notificationCount == 0 ? "No Notification Found" : "Notifications (" + notificationCount + ")"}</h6>
										<ul>
											{notificationDataSource.slice(0, 5).map((obj, index) => {
												return <NavLink to={'/notification'} onClick={() => setAnchorEl(null)} >
													<li>
														<a style={{ cursor: 'pointer' }} title=''>
															<div className='left-img'>
																<img src={ic_miscellenous} alt='icon' />
															</div>
															<div className='right-content'>
																<p style={{ width: '80%' }}>
																	<Typography noWrap>{obj.Title}</Typography>
																</p>
																<p>
																	<small>{obj.TypeModule}</small>
																</p>
																<span className='noti-time'>{obj.NotificationAt}</span>
															</div>
														</a>
													</li>
												</NavLink>
											})}
										</ul>
									</>}
					</PopoverComponent>
					{/* {subscriptionsData.length > 1 && openSubmenu &&
						<PopoverComponent
							anchorEl={openSubmenu}
							className={"submenu"}
							handleClose={handleClose}
							id={"subscription-popover"}>
							<ul>
								{subscriptionsData.map((obj) =>
									<li>
										<NavLink to=""
											onClick={() => { handleClose(); wsGetAutomationSubscriptionByID(obj.ID); }} >
											{obj.Address}
										</NavLink>
									</li>
								)}
							</ul>
						</PopoverComponent>} */}

					<Modal
						show={IsModalOpen}
						handleClose={handleCloseModal}>
						{IsProfile ? <EditProfile {...props} history={props.history} closeModal={handleCloseModal} />
							: <ChangePassword {...props} closeModal={handleCloseModal} />}
					</Modal>
				</div>
			</div>
		</header>
	);
};

/**
 *  handleLogout method is used to logout user and navigate to login
 * @param {*} props
 */
const handleLogout = (props) => {
	authService.removeAuthData();
	localStorage.clear()
	sessionStorage.clear()
};

export default Header;
