import React, { Component, createRef } from "react";
import { isMacOs } from "react-device-detect";

import Header from "../../features/header/component/header";
import NewHeader from "../../features/header/component/newHeader";
import Sidebar from "../../features/sidebar/component/sidebar";
import Sidebaryomi from "../../features/sidebar/component/sidebaryomi";
import { createMuiTheme } from '@material-ui/core/styles';
import { setRef } from "../../shared/services/reference-service";
import Footer from "../../features/footer/component/footer";
import ApplicationTheme from "../../theme/ApplicationTheme";
import * as AppConsatnt from "../../config/AppConstant";
import Tour from 'reactour';
import * as AppConstants from "../../config/AppConstant";
import dotsvertical from '../../assets/images/dotsvertical.png'
import dotshorizontal from '../../assets/images/dotshorizontal.png'
import homeicon from '../../assets/images/visualization.png'
import homeiconhover from '../../assets/images/home_icon_hover.png'

import { withRouter } from 'react-router-dom'

/**
 * Layout - Base layout of application
 */

const steps = [
	{
		selector: '.first-step',
		content: 'You can checkout Latest Brands & Campaigns and Place an Order',
	},
	{
		selector: '.second-step',
		content: 'View Historical Orders here',
	},
	{
		selector: '.third-step',
		content: 'Visit the Cart, Verify and Place the Order',
	},
	{
		selector: '.fourth-step',
		content: 'Get List of Locations and Manage Profile',
	}
]

class Layout extends Component {
	state = {
		innerWidth: window.innerWidth,
		isVTM: AppConstants.GetLocalStorage("localStorageIsVTM"),
		sidebarOpen: true,
		theme: createMuiTheme(ApplicationTheme(this.props.color, this.props.mode, this.props.direction)),
		isTourOpen: false,
		// isTourOpen: AppConsatnt.GetMainClassName() == "app-main" && Location.indexOf('test') != -1 ? true : false,
	};
	constructor(props) {
		super(props);
		this.containerRef = createRef();
	}

	componentDidMount = () => {
		if (this.containerRef.current) {
			setRef(this.containerRef);
		}
		if (isMacOs) {
			document.getElementsByTagName("body")[0].classList.add("mac_device");
		}
		window.addEventListener("resize", this.handleResize);
	};

	componentWillUnmount = () => {
		window.removeEventListener("resize", this.handleResize);
	};

	closeTour() {
		this.setState({ isTourOpen: false })
	}

	render() {
		const { location, history } = this.props
		window.scrollTo(0, 0);

		return (
			// <MuiThemeProvider theme={this.state.theme}>
			<div className='app-container'>
				<div className='app-main-container'>

					<div className='app-header'>
						<Header
							{...this.props}
							handleMenu={this.handleMenuClick}
						/>
						{/* <NewHeader /> */}
					</div>

					<main ref={this.containerRef} className='app-main-content-wrapper'>
						<div className='app-main-content'>
							{/* <div className={`app-wrapper main-sec-wrap`} style={{ paddingLeft: 0 }}> */}
							<div className={`app-wrapper main-sec-wrap ${localStorage.getItem("headerMenuAdmin") == 1 && this.state.sidebarOpen ? "active-main" : ""}`}
								style={localStorage.getItem("headerMenuAdmin") != 1 ? { paddingLeft: 0 } : {}}>
								{localStorage.getItem("headerMenuAdmin") == 1 &&
									<Sidebar
										innerWidth={this.state.innerWidth}
										isSidebarOpen={this.state.sidebarOpen}
										handleMenu={this.handleMenuClick}
										handleMenu1={this.handleMenuClick1}
										closeSidebar={this.closeSidebar} />}
								<div
									className='main-content'
									onClick={(e) => {
										e.stopPropagation();
										if (this.state.innerWidth <= 991) {
											this.handleMainClick();
										}
									}}>
									<div style={{ position: "relative" }}>
										{this.props.children}
									</div>
									<div style={{ textAlign: 'end', position: "fixed", top: 90, left: "-35px", backgroundColor: "#f5f7fa", padding: "10px", zIndex: 10 }}>
										<img src={homeicon} alt="icon" onClick={() => { history.push("/home") }}
											// onMouseOver={e => (e.currentTarget.src = homeiconhover)}
											// onMouseOut={e => (e.currentTarget.src = homeicon)}
											style={{ marginTop: "20px", width: "40%", cursor: 'pointer' }}
										/>
									</div>
									<div style={{ textAlign: 'end', position: "fixed", top: 90, right: 5, zIndex: -1 }}>
										<img src={dotshorizontal} alt="" style={{ marginTop: "20px", width: "40%" }} />
									</div>
									<div>
										<img src={dotsvertical} alt="" style={{ position: "absolute", bottom: 10, left: 5, width: "45px" }} />
									</div>
								</div>

								<section className='bootom-sec'>
									<Footer {...this.props} />
								</section>
							</div>
						</div>
					</main>
				</div>
				<Tour
					rounded={5}
					steps={steps}
					isOpen={this.state.isTourOpen}
					onRequestClose={this.closeTour.bind(this)} />
			</div>
			// </MuiThemeProvider>
		);
	}

	handleResize = () => {
		this.setState({
			innerWidth: window.innerWidth,
		});
		if (window.innerWidth <= 991) { this.closeSidebar() } else { this.handleMenuClick(); }
	};

	/**
	 * handleMenuClick method is used to toggle menu
	 */
	handleMenuClick = () => {
		this.setState(
			{
				sidebarOpen: !this.state.sidebarOpen,
			},
			() => {
				if (this.state.sidebarOpen) {
					document.querySelector("body").classList.add("sidebar");
				} else {
					document.querySelector("body").classList.remove("sidebar");
				}
			}
		);
	};

	closeSidebar = () => {
		this.setState({ sidebarOpen: false })
		document.querySelector("body").classList.remove("sidebar");
	};

	handleMenuClick1 = () => {
		this.setState(
			{
				sidebarOpen: true,
			}
		);
	};

	/**
	 * handleMainclick every time whenever user click somewhere in body
	 * it will verify that if sidebar is open than close it
	 */
	handleMainClick = () => {
		if (this.state.sidebarOpen) {
			this.setState({
				sidebarOpen: false,
			});
			document.querySelector("body").classList.remove("sidebar");
		}
	};
}

export default withRouter(Layout)
