import React from 'react';
import PageTitle from "../../../config/pageTitle";

import ModalComponent from "../../../shared/components/modal/modal";
import close_icon from "../../../assets/images/gray-close-ic.svg";

import Modal from '../../../shared/components/modal/modal';
import ConfirmtionDialog from '../../../features/ConfirmDialog/ConfirmationDialog';
// F:\VMobee\Cobrand\src\features\admin\container\calendar
import AddEditCalendarPopup from '../../../features/admin/container/calendar/addEditCalendarPopup';
import * as APIController from '../../../api/APIController.js';
import * as AppConstants from "../../../config/AppConstant";
import CircularProgress from "../../components/CircularProgress/CircularProgress"
import MyiFrame from "../../../features/campaign/components/myiFrame";
import moment from 'moment';


const CustomCalendar = props => {
    const DAY = new Date();
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [MonthData, setWeekDay] = React.useState([{ "ID": 1, "Name": "Sun" }, { "ID": 2, "Name": "Mon" }, { "ID": 3, "Name": "Tue" }, { "ID": 4, "Name": "Wed" }, { "ID": 5, "Name": "Thu" }, { "ID": 6, "Name": "Fri" }, { "ID": 7, "Name": "Sat" }]);

    const [IsLoading, setIsLoading] = React.useState(true);
    const [CurrentMonth, setCurrentMonth] = React.useState(DAY.getMonth() + 1);
    const [CurrentYear, setCurrentYear] = React.useState(DAY.getFullYear());
    const [CurrentMonthName, setCurrentMonthName] = React.useState(monthNames[DAY.getMonth()]);
    const [TotalDayOfMonth, setTotalDayOfMonth] = React.useState([]);
    const [TotalCards, setTotalCards] = React.useState([]);
    const [CalendarAssetsData, setCalendarAssetsData] = React.useState([]);
    const [PracticeMonthData, setPracticeMonthData] = React.useState([]);

    const [firstDateWeek, setfirstDateWeek] = React.useState(new Date(CurrentYear, CurrentMonth - 1, 1).getDay() + 1);

    const [PreviewModalData, setPreviewModalData] = React.useState(null);
    const [LoginDialog, setLoginDialog] = React.useState(false);
    const [DeleteId, setDeleteId] = React.useState('');
    const [DeleteStatus, setDeleteStatus] = React.useState('');
    const [EditID, setEditID] = React.useState(0);

    React.useEffect(() => {
        var pairs = [];
        if (props.CalendarMonth != "" || props.CalendarYear != '') {
            setCurrentYear(props.CalendarYear)
            setCurrentMonthName(monthNames[props.CalendarMonth - 1])
            setCurrentMonth(parseInt(props.CalendarMonth))
            setfirstDateWeek(new Date(props.CalendarYear, props.CalendarMonth - 1, 1).getDay() + 1)

            for (let i = 0; i < new Date(CurrentYear, props.CalendarMonth, 0).getDate(); i++) {
                pairs.push({ Date: i + 1 });
            }
        } else {
            for (let i = 0; i < new Date(CurrentYear, CurrentMonth + 1, 0).getDate(); i++) {
                pairs.push({ Date: i + 1 });
            }
        }
        setTotalDayOfMonth(pairs)

        var cards = [];
        for (let i = 0; i < 42; i++) {
            if (i < pairs.length + new Date(props.CalendarYear, props.CalendarMonth - 1, 1).getDay()) {
                cards.push({ ID: i + 1 });
            }

        }
        setTotalCards(cards)

        setPracticeMonthData(props.PMonthData)
        setCalendarAssetsData(props.CalendarAssetsData)

        setIsLoading(false)

    }, [props])

    const nextMonth = (date, currentMonth, currentYear, isAdmin) => {
        setIsLoading(true)
        setCurrentMonth(currentMonth + 1)
        var current = ''
        if (currentMonth == 11) {
            current = new Date(date.getFullYear() + 1, -1, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(monthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        } else {
            current = new Date(date.getFullYear(), currentMonth, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(monthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        }
        var daysInMonth = new Date(current.getFullYear(), current.getMonth() + 1, 0).getDate();
        var pairs = [];
        for (let i = 0; i < daysInMonth; i++) {
            pairs.push({ Date: i + 1 });
        }
        setTotalDayOfMonth(pairs)

        props.handlebind(props.AutomationCalendarID, CurrentYear, currentMonth + 1)

        setIsLoading(false)
    }
    const previousMonth = (date, currentMonth, currentYear, isAdmin) => {
        setIsLoading(true)
        setCurrentMonth(currentMonth - 1)
        var current = ''
        if (currentMonth == 1) {
            current = new Date(currentYear, -1, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(monthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        } else {
            current = new Date(date.getFullYear(), currentMonth - 2, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(monthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        }

        var daysInMonth = new Date(current.getFullYear(), current.getMonth() + 1, 0).getDate();

        var pairs = [];
        for (let i = 0; i < daysInMonth; i++) {
            pairs.push({ Date: i + 1 });
        }
        setTotalDayOfMonth(pairs)
        props.handlebind(props.AutomationCalendarID, CurrentYear, currentMonth - 1)


        setIsLoading(false)
    }

    const wsDeleteAutomationCalendarAsset = (ID, DeleteStatus) => {
        if (AppConstants.GetLocalStorage("headerMenuAdmin") == 1) {
            APIController.DeleteAutomationCalendarAsset(ID)
                .then((response) => {
                    if (response.error == null) {
                        if (response.object.status == 1) {
                            // props.handleNotification(response.object.message);
                        } else {
                            // props.handleNotification(response.object.message, true);
                        }
                        props.handlebind(props.AutomationCalendarID, props.CalendarYear, props.CalendarMonth)
                        setLoginDialog(false)
                    }
                    else if (response.error.message && response.error.message.length > 0) {
                        // props.handleNotification(response.error.message)
                    }
                })
        } else {
            // setdata1(data1.map((item, index) => item.ID !== ID ? item : { ...item, Checked: !item.Checked }))
            let finaldata = CalendarAssetsData.map((item) => item.ID !== ID ? item : { ...item, Status: 3 })
            // setCalendarAssetsData(finaldata)
            props.handleRefresh(finaldata)
            props.handleAssetsData(finaldata)
            setLoginDialog(false)

        }

    }

    const getBackgroundColor = () => {
        const Data = CalendarAssetsData.find((obj) => moment(obj.AssetDate).format('YYYY-MM-DD') > moment(new Date()).format('YYYY-MM-DD'))
        if (Data != undefined && Data.length > 0) {
            return '#fcfcfc'
        } else {
            return '#fcfcfc'
        }
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <PageTitle Title={CurrentMonthName + " " + CurrentYear} />
                <div style={{ display: 'flex', marginTop: '15px' }}>
                    {AppConstants.GetLocalStorage("headerMenuAdmin") != -1 ?
                        <>
                            {PracticeMonthData.length ? PracticeMonthData.map((item) => {
                                return (
                                    <>
                                        {CurrentMonth - 1 == item.MonthData &&
                                            <div className='form-field button-field' style={{ marginBottom: '20px' }}>
                                                <input type="button" name="edit-profile" value="Previous Month" onClick={() => { previousMonth(new Date(), CurrentMonth, CurrentYear, false); }} />
                                            </div>
                                        }
                                        {CurrentMonth + 1 == item.MonthData &&
                                            <div className='form-field button-field' style={{ marginBottom: '20px' }}>
                                                <input type="button" name="edit-profile" value="Next Month" onClick={() => nextMonth(new Date(), CurrentMonth, CurrentYear, false)} />
                                            </div>}
                                    </>
                                )
                            }) : <></>
                            }</>
                        :
                        <>
                            <div className='form-field button-field' style={{ marginBottom: '20px' }}>
                                <input type="button" name="edit-profile" value="Previous" onClick={() => { previousMonth(new Date(), CurrentMonth, CurrentYear, true); }} />
                            </div>
                            <div className='form-field button-field' style={{ marginBottom: '20px' }}>
                                <input type="button" name="edit-profile" value="Next" onClick={() => nextMonth(new Date(), CurrentMonth, CurrentYear, true)} />
                            </div>
                        </>
                    }
                </div>
            </div>
            <div style={{ border: '1px solid #D3D3D3', minWidth: "900px", overflow: 'auto' }}>
                <div style={{ display: 'flex', width: '100%', gridGap: '5px' }}>{MonthData.map((Item) => {
                    return (
                        <div style={{ width: '100%', textAlign: 'center', padding: '2px 4px', border: '1px solid #D3D3D3' }}>{Item.Name}</div>
                    )
                })}
                </div>

                <div style={{ display: 'grid', height: "1225px", gridTemplateColumns: '13.95% 13.93% 13.91% 13.89% 13.95% 13.95% 13.91%', gridTemplateRows: '200px 200px 200px 200px 200px 200px', gridGap: '5px' }}>

                    {!IsLoading && TotalCards.map((Item, Index) => {
                        return (
                            <div style={{
                                textAlign: 'center', padding: '2px 4px', border: '1px solid #D3D3D3',
                                backgroundColor: AppConstants.GetLocalStorage("headerMenuAdmin") != 1 && getBackgroundColor()

                            }}>
                                {TotalDayOfMonth.map((item, index) =>
                                    index == Index - firstDateWeek + 1 &&
                                    <>
                                        <div className="daygrid-day-top">{item.Date}</div>
                                        {CalendarAssetsData.map((itm, ind) => {
                                            return (<>
                                                {itm.Status != 3 &&
                                                    itm.AssetDate.split("T")[0].split("-")[0] == CurrentYear &&
                                                    itm.AssetDate.split("T")[0].split("-")[1] == CurrentMonth &&
                                                    itm.AssetDate.split("T")[0].split("-")[2] == item.Date &&
                                                    <div className="two-col-sec-calendar" key={ind}>
                                                        <div className="content-box-calendar">
                                                            <div className="box-image-calendar calendar-image-box" style={{ backgroundImage: `url(${itm.PreviewURL} )` }}>
                                                                {/* <img src={itm.ImageURL} alt="itm.ImageURL" /> */}
                                                                <div className="button-hover" style={AppConstants.GetLocalStorage("headerMenuAdmin") == 1 ? { padding: '15% 10px' } : { padding: '25% 10px' }}>
                                                                    {AppConstants.GetLocalStorage("headerMenuAdmin") == 1 &&
                                                                        <div className="button-field" >
                                                                            <button className="calendar-hover-button" onClick={() => {
                                                                                setCurrentYear(itm.AssetDate.split("T")[0].split("-")[0])
                                                                                setCurrentMonth(itm.AssetDate.split("T")[0].split("-")[1])
                                                                                setEditID(itm.ID)
                                                                                setLoginDialog(true)
                                                                            }}>{"Edit"}</button>
                                                                        </div>
                                                                    }
                                                                    <div className="button-field">
                                                                        <button className="calendar-hover-button" onClick={() => {
                                                                            setPreviewModalData({
                                                                                isModalOpen: true,
                                                                                content: {
                                                                                    image: itm.ImageURL,
                                                                                    contentText: itm.Description
                                                                                }
                                                                            });
                                                                        }}>{"Preview"}</button>
                                                                    </div>

                                                                    {moment(itm.AssetDate).format('YYYY-MM-DD') > moment(new Date()).format('YYYY-MM-DD')
                                                                        ? <div className="button-field">
                                                                            <button className="calendar-hover-button"
                                                                                onClick={() => {
                                                                                    setEditID(0)
                                                                                    setDeleteId(itm.ID)
                                                                                    setDeleteStatus(itm.Status)
                                                                                    setLoginDialog(true)
                                                                                }}>{"Delete"}</button>
                                                                        </div> : <></>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="box-content-info-calendar" >
                                                            <label htmlFor={"Test Dta text on biotrue  30-08-2021 Test Dta text on biotrue"} style={{ fontSize: '14px' }}>{"Test Dta text on biotrue  30-08-2021 Test Dta text on biotrue"}</label>
                                                                        </div> */}
                                                    </div>
                                                }
                                            </>)
                                        })}
                                    </>
                                    // :
                                    // <>{!index == Index - firstDateWeek + 1 && <div className="daygrid-day-top">{"2"}</div>}</>
                                )}
                            </div>

                        )
                    })}
                </div>

            </div>
            {PreviewModalData && PreviewModalData.isModalOpen && (
                <ModalComponent
                    show={PreviewModalData.isModalOpen}
                    handleClose={() => setPreviewModalData(null)}>
                    <div className="popup-wrap" style={{ justifyContent: 'space-between' }}>
                        <div className="two-col-sec digital-welcome" style={{ padding: '0px', marginTop: '0px', boxShadow: 'none' }}>
                            <div className="sec-left" style={{ marginTop: '0px' }} >
                                <div style={{ letterSpacing: '0px' }}>
                                    <div dangerouslySetInnerHTML={{ __html: PreviewModalData.content.contentText }} />
                                </div>
                            </div>
                            <div className="sec-right" style={{ padding: '0px', marginBottom: '10px' }}>
                                {PreviewModalData.content.image.split('.').pop().split('?')[0] == "mp4" ?
                                    <MyiFrame isFacbook={true} src={PreviewModalData.content.image + "?" + Math.floor(Math.random() * (999999 - 100000 + 1) + 100000)} />
                                    : <img src={PreviewModalData.content.image} alt="image" />}
                            </div>
                        </div>
                        <a title="" className="gray-close-ic">
                            <img src={close_icon} alt="icon" onClick={() => setPreviewModalData(null)} />
                        </a>
                    </div>
                </ModalComponent>
            )}

            {LoginDialog &&
                <Modal
                    show={LoginDialog}
                    handleClose={() => setLoginDialog(false)}>

                    {EditID != 0 ?
                        <AddEditCalendarPopup
                            closeModal={() => setLoginDialog(false)}
                            CalendarId={props.AutomationCalendarID}
                            EditID={EditID}
                            CalendarYear={CurrentYear}
                            CalendarMonth={CurrentMonth}
                            history={props.history}
                            titleName={"Calendar " + CurrentYear + ", " + monthNames[CurrentMonth - 1]}
                        />
                        : <ConfirmtionDialog
                            title={"Delete Asset"}
                            description={"Are you sure you want to delete this Calendar Asset?"}
                            deletePress={() => wsDeleteAutomationCalendarAsset(DeleteId, DeleteStatus)}
                            closeModal={() => setLoginDialog(false)}
                        />
                    }
                </Modal>
            }

        </>
    )
}

export default CustomCalendar;