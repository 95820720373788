/*eslint-disable eqeqeq*/
import React from "react";
import RegistrationsReportDownloadList from "./registrationsReportDownloadList";
import * as APIController from "../../../../../api/APIController.js";
import Notification from "../../../../../shared/components/notification/notification";
import CsvDownload from 'react-json-to-csv'
import moment from 'moment';

import PageTitle from "../../../../../config/pageTitle";
import * as AppConstants from "../../../../../config/AppConstant";
import Dropdown from "../../../../../shared/components/Dropdown/Dropdown";
import { MenuItem } from "@material-ui/core";

class RegistrationsReportDownload extends React.Component {
    state = {
        currentPage: 1,
        count: 0,
        rowsPerPage: 1000000,
        rowsPerPageOptions: ["All", 50, 100, 250, 500, 1000],
        tempList: [],
        list: [],
        from: 0,
        to: 1000000,
        downloadlist: [],
        progress: true,
        message: "",
        showNotification: false,
        isError: false,
        downloadDate: moment(new Date().toUTCString()).utcOffset(0, false).format('MMDDYY'),
        downloadType: " Old",
        showMessage:
            this.props.history.location.state != null &&
            this.props.history.location.state.showMessage,
        dropdownValue: "0"
    };

    componentDidMount() {
        this.wsRegistrationReport(this.state.currentPage, this.state.rowsPerPage, this.state.from, this.state.to);
        // this.setState({ tempList: this.state.list })
    }

    wsRegistrationReport(CurrentPage, RecordPerPage, from, to) {
        this.setState({ progress: true, });
        if (this.state.list.length) {
            var FilteredData = [];
            if (this.state.dropdownValue == '0') {
                FilteredData = this.state.list.filter((e) => { return e.HasLoggedIn == 0 && e.IsNewUser == 0 })
            }
            else if (this.state.dropdownValue == '1') {
                FilteredData = this.state.list.filter((e) => { return e.HasLoggedIn == 1 && e.IsNewUser == 0 })
            }
            else if (this.state.dropdownValue == '2') {
                FilteredData = this.state.list.filter((e) => { return e.IsNewUser == 1 })
            }

            let downloaddata = []
            FilteredData.map((item) =>
                downloaddata.push({
                    "RegistrationDate": item.RegistrationDate, "ShipToNo": item.ShipToNo, "PracticeName": item.PracticeName, "StreetAddress": item.Address1, "StreetAddressLine2": item.Address2,
                    "City": item.City, "State": item.State, "ZipCode": item.Postcode, "PhoneNo": item.PhoneNo, "VS": item.VS,
                    "VSP": item.VSP, "WebsiteURL": item.Website, "AppointmentURL": item.AppointmentURL, "ContactName": item.ContactName, "EmailAddress": item.EmailAddress,
                    "VTM_No": item.VTM_No, "VTM_Territory": item.VTM_Name
                })
            )

            this.setState({
                to: isNaN(to) ? 1000000 : to,
                // currentPage: CurrentPage,
                // rowsPerPage: RecordPerPage,
                from: from,
                count: FilteredData.length,
                downloadlist: downloaddata,
                tempList: FilteredData,
                progress: false,
            })
        } else {
            APIController.RegistrationReport().then((response) => {
                if (response.error == null && response.object.data.length != 0) {
                    let downloaddata = []
                    response.object.data.map((item) =>
                        item.HasLoggedIn == 0 &&
                        downloaddata.push({
                            "RegistrationDate": item.RegistrationDate, "ShipToNo": item.ShipToNo, "PracticeName": item.PracticeName, "StreetAddress": item.Address1, "StreetAddressLine2": item.Address2,
                            "City": item.City, "State": item.State, "ZipCode": item.Postcode, "PhoneNo": item.PhoneNo, "VS": item.VS,
                            "VSP": item.VSP, "WebsiteURL": item.Website, "AppointmentURL": item.AppointmentURL, "ContactName": item.ContactName, "EmailAddress": item.EmailAddress,
                            "VTM_No": item.VTM_No, "VTM_Territory": item.VTM_Name
                        })
                    )
                    this.setState({
                        tempList: response.object.data.filter((e) => { return e.HasLoggedIn == 0 && e.IsNewUser == 0 }),
                        downloadlist: downloaddata,
                        to: isNaN(to) ? 1000000 : to,
                        currentPage: CurrentPage,
                        rowsPerPage: RecordPerPage,
                        from: from,
                        count: response.object.data.filter((e) => { return e.HasLoggedIn == 0 && e.IsNewUser == 0 }).length,
                        list: response.object.data,
                        progress: false,
                    });
                } else { this.setState({ list: [], progress: false }) }
            });
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        return (
            <>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />
                <section className='content-right-main'>
                    <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                        <h1><PageTitle Title={AppConstants.ContactDetais.RegistrationsReport} /></h1>
                        <div style={{ display: 'flex' }}>
                            <Dropdown style={{ width: '50%' }}
                                // label='Select Radius'
                                id={'radius'}
                                name='radius'
                                // placeholder='Select Radius'
                                defaultValue={this.state.dropdownValue}
                                value={this.state.dropdownValue}
                                onChange={(e) => {
                                    this.setState({ dropdownValue: e.target.value })
                                    var FilteredData = [];
                                    if (e.target.value == '0') {
                                        this.setState({ downloadType: " OLD" })
                                        FilteredData = this.state.list.filter((e) => { return e.HasLoggedIn == 0 && e.IsNewUser == 0 })
                                    }
                                    else if (e.target.value == '1') {
                                        this.setState({ downloadType: " NEW OLD" })
                                        FilteredData = this.state.list.filter((e) => { return e.HasLoggedIn == 1 && e.IsNewUser == 0 })
                                    }
                                    else if (e.target.value == '2') {
                                        this.setState({ downloadType: " NEW" })
                                        FilteredData = this.state.list.filter((e) => { return e.IsNewUser == 1 })
                                    }

                                    let downloaddata = []
                                    FilteredData.map((item) =>
                                        downloaddata.push({
                                            "RegistrationDate": item.RegistrationDate, "ShipToNo": item.ShipToNo, "PracticeName": item.PracticeName, "StreetAddress": item.Address1, "StreetAddressLine2": item.Address2,
                                            "City": item.City, "State": item.State, "ZipCode": item.Postcode, "PhoneNo": item.PhoneNo, "VS": item.VS,
                                            "VSP": item.VSP, "WebsiteURL": item.Website, "AppointmentURL": item.AppointmentURL, "ContactName": item.ContactName, "EmailAddress": item.EmailAddress,
                                            "VTM_No": item.VTM_No, "VTM_Territory": item.VTM_Name
                                        })
                                    )

                                    this.setState({ downloadlist: downloaddata, tempList: FilteredData, count: FilteredData.length, rowsPerPage: this.state.rowsPerPage })
                                }}
                                select>
                                <MenuItem classes='' value={'0'}> {'Old'} </MenuItem>
                                <MenuItem classes='' value={'1'}> {'New + Old'} </MenuItem>
                                <MenuItem classes='' value={'2'}> {'New'} </MenuItem>
                            </Dropdown>
                            <div className='button-field' style={{ height: '40px', width: '300px', textAlign: 'center', marginLeft: '20px' }}>
                                <CsvDownload data={this.state.downloadlist} filename={"Practice Registration Report " + this.state.downloadDate + this.state.downloadType + ".csv"}>Download to CSV</CsvDownload>
                            </div>
                        </div>
                    </div>

                    <div className='button-field  button-field-country'>

                    </div>

                    <RegistrationsReportDownloadList
                        {...this.state}
                        history={this.props.history}
                        handleChangeRowsPerPage={this.handleRowsPerPageChange.bind(this)}
                        handleChangePage={this.handlePageChange}
                        handleNotification={this.handleNotification}
                    />
                </section>
            </>
        );
    }

    handleRowsPerPageChange = (value) => {
        let from = 0
        let to = value;
        this.setState({ progress: true, rowsPerPage: value, currentPage: 1, to: to, from: from })
        this.wsRegistrationReport(isNaN(value) ? '' : this.state.currentPage, isNaN(value) ? 1000000 : value, from, to);
    }

    handlePageChange = (values) => {
        let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage
        let to = values === 1 ? this.state.rowsPerPage : (from + this.state.rowsPerPage);
        this.setState({ currentPage: Number(values) })
        this.wsRegistrationReport(Number(values), this.state.rowsPerPage, from, to);
    }

    handleNotification = (message, isError) => {
        this.setState({
            message: message,
            showNotification: true,
            isError: isError,
        });

        setTimeout(() => {
            this.handleClearNotification();
        }, 4000);
    };

    handleClearNotification = () => {
        this.setState({
            message: "",
            showNotification: false,
            isError: false,
        });
    };
}

export default RegistrationsReportDownload;
