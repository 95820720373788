/*eslint-disable eqeqeq*/
import React from "react";
import Table from "../../../../shared/components/table/table";
import TableHead from "../../../../shared/components/table/tableHead";
import TableRow from "../../../../shared/components/table/tableRow";
import TableBody from "../../../../shared/components/table/tableBody";
import TableCell from "../../../../shared/components/table/tableCell";
import double_left_arrow from '../../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../../assets/images/right-arrow.svg';
import ic_orders from '../../../../assets/images/ic_orders_grey.svg';
import monthlycalendar from '../../../../assets/images/monthlycalendar.svg';
import paypalicon from '../../../../assets/images/icon/paypalicon.svg';
import filtericon from '../../../../assets/images/ic_feather_filter.svg';
import "react-perfect-scrollbar/dist/css/styles.css";
import PageTitle from "../../../../config/pageTitle";
import PerfectScrollbar from "react-perfect-scrollbar";
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";

const SubscriptionList = (props) => {
	const headerList = ['action', "Subscription #", "Practice Name", "Address", "Subscribed At", "Start Date", "Next Renewal", "Cost", "Status"]
	const { currentPage, count, rowsPerPage, rowsPerPageOptions, list, to, from } = props;
	let countryList = list;
	countryList = list.slice(from, to)
	const page = Math.floor(count / rowsPerPage) + 1;

	const handleChangeInCurrentPage = (value) => {
		if (value <= page && Number(parseInt(value))) {
			props.handleChangePage(Number(value));
		}
	}

	return (
		<>
			<h1><PageTitle Title="View Subscriptions" /></h1> <div className="table-main-wrap">
				<PerfectScrollbar component='div'>
					<Table>
						<TableHead>
							<TableRow> {headerList.map((obj, index) => { return <TableCell style={index == 0 ? { minWidth: "90px" } : index == headerList.length - 2 ? { minWidth: '100px', textAlign: 'right' } : { width: '10%', minWidth: '110px' }} key={index}>{obj}</TableCell>; })} </TableRow>
							<TableRow>
								<TableCell style={{ minWidth: "90px" }}> <span><img src={filtericon} alt="delete" /></span> </TableCell>
								<TableCell style={{ minWidth: "110px" }}> <input type="text" name="" placeholder="Subscription No" onChange={(e) => props.applyFilter('SubscriptionNo', e.target.value, 'input')} /> </TableCell>
								<TableCell> <input type="text" name="" placeholder="Practice Name" onChange={(e) => props.applyFilter('Practice', e.target.value, 'input')} /> </TableCell>
								<TableCell> <input type="text" name="" placeholder="Address" onChange={(e) => props.applyFilter('Address', e.target.value, 'input')} /> </TableCell>
								<TableCell> <input type="text" name="" placeholder="Subscribed At" onChange={(e) => props.applyFilter('SubscribedAt', e.target.value, 'input')} /> </TableCell>
								<TableCell style={{ minWidth: "100px" }}> <input type="text" name="" placeholder="Start Date" onChange={(e) => props.applyFilter('StartDate', e.target.value, 'input')} /> </TableCell>
								<TableCell style={{ minWidth: "130px" }}> <input type="text" name="" placeholder="Next Renewal Date" onChange={(e) => props.applyFilter('RenewalDate', e.target.value, 'input')} /> </TableCell>
								<TableCell style={{ minWidth: "100px" }}> <input type="text" name="" placeholder="Cost" onChange={(e) => props.applyFilter('PaidAmount', e.target.value, 'input')} /> </TableCell>
								{/* <TableCell> <input type="text" name="" placeholder="Status" onChange={(e) => props.applyFilter('SubscriptionStatus', e.target.value, 'input')} /> </TableCell> */}
								<TableCell>
									<select onChange={(e) => props.applyFilter('SubscriptionStatus', e.target.value, 'select')}>
										<option value={""}>All</option>
										<option value={"In Cart"}>In Cart</option>
										<option value={"Started"}>Started</option>
										<option value={"Cancelled"}>Cancelled</option>
										<option value={"On Hold"}>On Hold</option>
									</select>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!!countryList.length ? countryList.map((obj, idnex) => {
								return (
									<TableRow key={idnex}>
										<TableCell style={{ minWidth: "110px" }}>
											<span>
												<img src={ic_orders} alt="edit" style={{ cursor: 'pointer', marginRight: '10px', width: "17px" }}
													onClick={() => { props.handleShowList(true, obj.ID) }} />
												{obj.SubscriptionStatus !== "In Cart" && <img src={monthlycalendar} alt="edit" style={{ cursor: 'pointer', marginRight: '10px', width: "17px" }}
													onClick={() => { props.handleShowList(false, obj.ID, obj.StartDate.split("-")[0], obj.StartDate.split("-")[2]) }} />}
												{obj.IsPayPalSubscried && <img src={paypalicon} alt="edit" style={{ cursor: 'pointer', marginRight: '10px', width: "17px" }} />}
											</span>
										</TableCell>
										<TableCell style={{ minWidth: "135px" }}>{obj.SubscriptionNo}</TableCell>
										<TableCell style={{ minWidth: "230px" }}>{obj.Practice}</TableCell>
										<TableCell style={{ minWidth: "230px" }}>{obj.Address}</TableCell>
										<TableCell style={{ minWidth: "100px" }}>{obj.SubscribedAt}</TableCell>
										<TableCell style={{ minWidth: "100px" }}>{obj.StartDate}</TableCell>
										<TableCell style={{ minWidth: "130px" }}>{obj.RenewalDate}</TableCell>
										<TableCell style={{ minWidth: "100px", textAlign: 'right' }}>{obj.PaidAmount}</TableCell>
										<TableCell style={{ textAlign: 'center' }} className={obj.SubscriptionStatus === "Started" ? 'green' : obj.SubscriptionStatus === "Cancelled" ? 'red' : ''}>
											<span>{obj.SubscriptionStatus}</span></TableCell>
									</TableRow>
								);
							}) : <TableRow>
								<TableCell colSpan={headerList.length} className="not_found">
									{props.progress ?
										<CircularProgress /> :
										<label>Not Currently Available</label>}
								</TableCell>
							</TableRow>}
						</TableBody>
					</Table>
				</PerfectScrollbar>
			</div>

			<div className="table-pagination">
				<div className="pagination-info">
					<p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
				</div>
				<div className="pagination-control">
					<a href="#!" title="" className={`double-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(1)
						}}
					><img src={double_left_arrow} alt="left" /></a>
					<a href="#!" title="" className={`simple-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage - 1)
						}}
					><img src={left_arrow} alt="single_left" /></a>
					<span>Page</span>
					<input type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => handleChangeInCurrentPage(e.target.value)} />
					<span>of {page}</span>
					<a href="#!" title="" className={`simple-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage + 1)
						}}
					><img src={right_arrow} alt="right" /></a>
					<a href="#!" title="" className={`double-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(page)
						}}><img src={double_right_arrow} alt="single_right" /></a>
					<select onChange={(e) => props.handleChangeRowsPerPage(e.target.value)} defaultValue={rowsPerPage}>
						{
							rowsPerPageOptions.map((obj, index) => {
								return <option value={obj} key={index}>{obj}</option>
							})
						}
					</select>
				</div>
			</div>
		</>
	);
};

export default SubscriptionList;
