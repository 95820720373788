import React from 'react';
import { NavLink } from 'react-router-dom';
import * as AppConsatnt from "../../../config/AppConstant";
import { FaFacebookSquare, FaLinkedin } from 'react-icons/fa'
import { AiFillLinkedin } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import footerlogo from '../../../assets/images/footerlogo.svg'

const Footer = props => {

    return (
        <>
            <footer   >
                <div className='footer-link-main' style={{ display: 'flex', alignItems: 'center', color: 'white', justifyContent: "space-between", width: "100%", padding: '5px 0' }} >
                    <div className='footer-right' style={{ width: '80%' }}>
                        <ul>
                            <li><NavLink style={{ color: 'white', fontSize: '14px' }} to='/termsofuse'>{AppConsatnt.FooterText.LinkText1}</NavLink></li>
                            <li><NavLink style={{ color: 'white', fontSize: '14px' }} to='/privacypolicy'>{AppConsatnt.FooterText.LinkText2}</NavLink></li>
                            <NavLink style={{ color: 'white', fontSize: '14px' }} to='/contactus'>{AppConsatnt.FooterText.LinkText3}</NavLink>
                        </ul>
                    </div>

                    <div style={{ width: '20%', margin: "5px 0px" }}>
                        <img src={footerlogo} alt='' width="70%" />
                        <p style={{ marginBottom: "0px", fontSize: '11px' }}>@2023 Bausch & Lomb Incorporated or its affiliates.MTB.0454.USA,23</p>
                    </div>
                </div>
                {/* <div className="foot-links" style={{ display: 'flex', alignItems: 'center', backgroundColor: "#16416F", padding: '11px 40px' }} /> */}
            </footer>
        </>
    );
}
export default Footer;
{/* <div className='footer-left'>
    
    <p>{AppConsatnt.FooterText.TextMain1}{AppConsatnt.FooterText.FooterEmailText}{AppConsatnt.FooterText.TextMain2}</p>
</div>
<div className='footer-right'>
    <ul>
        <li><NavLink style={{ color: '#696969' }} to='/termsofuse'>{AppConsatnt.FooterText.LinkText1}</NavLink></li>
        <li><NavLink style={{ color: '#696969' }} to='/privacypolicy'>{AppConsatnt.FooterText.LinkText2}</NavLink></li>
        <NavLink style={{ color: '#696969' }} to='/contactus'>{AppConsatnt.FooterText.LinkText3}</NavLink>
    </ul>
</div> */}