import React from 'react';
import * as APIController from "../../../../api/APIController.js";
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";
import { Typography, Grid, Divider, } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { PayPalButton } from "react-paypal-button-v2";
import Modal from '../../../../shared/components/modal/modal';
import { ReactComponent as Ic_coin } from "../../../../assets/images/coin.svg";
import TermsAndConditionDialog from '../../../ConfirmDialog/TermsAndConditionDialog';
import ConfirmationDialog from '../../../ConfirmDialog/ConfirmationDialog';
import Notification from "../../../../shared/components/notification/notification";
import '../../adminStyle.css'
import PageTitle from "../../../../config/pageTitle";
import * as AppConstants from "../../../../config/AppConstant";

class Cart extends React.Component {
    state = {
        totalAmout: 0,
        ProductsList: [],
        SubscriptionList: [],
        SummaryList: [],
        SubscriptionsSummaryList: [],
        progress: false,
        UseCoOp: localStorage.getItem("headerMenuCoOp") == 1 ? 1 : 0,
        message: "",
        showNotification: false,
        isError: false,
        isModalOpen: false,
        isLocationExpanded: false,
        isBudgetExpanded: false,
        isLocationExpandedIndex: 0,
        isCheck: true,
        deleteDialog: false,
        isSubscriptionDelete: false,
        TermsCheckbox: true,
        deleteId: 0,
        PracticeIDs: [],
        PlanID: "",
        StartDate: "",
        custom_id: "",
        invoice_id: "",
        LocalPaymentID: '',
        OrderID: '',
        LocationsName: '',
        OrderByName: '',
        PracticeDetails: []
    };

    componentDidMount() {
        this.wsGetCart();
        AppConstants.LogEvent('View Cart', {})

        var locationName = [];
        if (AppConstants.GetLocalStorage('localStorageCampaignReach')) {
            JSON.parse(AppConstants.GetLocalStorage('localStorageCampaignReach')).map((obj, index) => {
                return (locationName.push(obj.Location))
            })
        }
        this.setState({ LocationsName: locationName })
    }

    wsGetCart() {
        this.setState({ progress: true, });
        APIController.GetCart().then((response) => {
            if (response.object.message == "") {
                this.setState({
                    ProductsList: response.object.data.Products,
                    SubscriptionList: response.object.data.Subscriptions,
                    SummaryList: response.object.data.Summary,
                    SubscriptionsSummaryList: response.object.data.Subscriptions_Summary,
                    count: response.object.data.length,
                    progress: false,
                });
                response.object.data.Subscriptions.length &&
                    this.wsGetPracticeByID(response.object.data.Subscriptions[0].PracticeID,
                        response.object.data.Subscriptions[0].PlanID,
                        response.object.data.Subscriptions[0].StartDate)

            } else {
                this.setState({ ProductsList: [], SubscriptionList: [], SummaryList: [], SubscriptionsSummaryList: [], progress: false })
            }
            AppConstants.SetLocalStorage("localStorageCartCount", response.object.data.Products.length || response.object.data.Subscriptions.length ? response.object.data.Products.length + response.object.data.Subscriptions.length : 0)
            // this.props.history.replace("/cart", null);
        });
    }

    wsDeleteCart(ID) {
        APIController.DeleteCart(ID).then((response) => {
            if (response.error == null) {
                if (response.object.status == 1) {
                    this.handleNotification(response.object.message);
                } else {
                    this.handleNotification(response.object.message, true);
                }
                this.wsGetCart();
                this.setState({ deleteDialog: false })
            } else if (response.error.message && response.error.message.length > 0) {
                this.handleNotification(response.error.message, true);
            }
        });
    }

    wsDeleteSubscriptionCart = (ID) => {
        APIController.DeleteAutomationSubscription(ID)
            .then((response) => {
                if (response.error == null) {
                    if (response.object.status == 1) {
                        this.handleNotification(response.object.message);
                    } else {
                        this.handleNotification(response.object.message, true);
                    }
                    this.wsGetCart();
                    this.setState({ deleteDialog: false })
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.handleNotification(response.error.message)
                }
                this.setState({ isSubscriptionDelete: false })
            })
    }

    wsEditCart(ID, topage, Locations) {
        this.setState({ progress: true, });
        APIController.EditCart(ID).then((response) => {
            if (response.error == null) {
                let PracticeIDs = [];
                let data = response.object.data
                for (var i = 0; i < data.length; i++) {
                    PracticeIDs.push(data[i].PracticeID)
                }
                let budgetDetail = [];
                budgetDetail.push({
                    budget: response.object.data[0].Budget,
                    duration: response.object.data[0].CampaignDurationInMonths
                })
                this.setState({ PracticeIDs: PracticeIDs, budgetDetail: budgetDetail })
                AppConstants.SetLocalStorage('localStorageFromCart', '0')
                AppConstants.SetLocalStorage('localStorageOrderDetailsID', response.object.data[0].OrderDetailsID)
                AppConstants.SetLocalStorage("localStorageShowAddToCart", 1)
                AppConstants.SetLocalStorage('localStoragepracticeId', 0)
                if (topage == '0') {
                    AppConstants.SetLocalStorage('localStoragePracticeIDs', JSON.stringify(PracticeIDs))
                    AppConstants.SetLocalStorage('localStorageCampaignID', response.object.data[0].CampaignID)
                    AppConstants.SetLocalStorage('localStorageProductID', response.object.data[0].ProductID)
                    AppConstants.SetLocalStorage('localStorageThemesID', response.object.data[0].CampaignThemeID)
                    AppConstants.SetLocalStorage("localStorageFreeAssetID", "")
                    AppConstants.SetLocalStorage('localStorageCampaignReach', Locations)
                    AppConstants.SetLocalStorage("localStorageBudgetDetail", JSON.stringify(this.state.budgetDetail))
                    this.props.history.push('/digital-product/selection/adbreak')
                }
                else {
                    AppConstants.SetLocalStorage('localStoragePracticeIDs', JSON.stringify(PracticeIDs))
                    AppConstants.SetLocalStorage('localStorageCampaignID', response.object.data[0].CampaignID)
                    AppConstants.SetLocalStorage('localStorageProductID', response.object.data[0].ProductID)
                    AppConstants.SetLocalStorage('localStorageThemesID', response.object.data[0].CampaignThemeID)
                    AppConstants.SetLocalStorage("localStorageFreeAssetID", "")
                    AppConstants.SetLocalStorage("localStorageBudgetDetail", JSON.stringify(this.state.budgetDetail))
                    AppConstants.SetLocalStorage("localStorageMultiSelectPractice", 1)
                    AppConstants.SetLocalStorage("localStorageCampaignProducts", response.object.data[0].MonthlyBudget)
                    AppConstants.SetLocalStorage("localStorageCampaignStartDate", response.object.data[0].StartDate)
                    AppConstants.SetLocalStorage("localStorageCampaignEndDate", response.object.data[0].EndDate)
                    this.props.history.push('/digital-product/selection/budget-duration')
                }
            }
            else if (response.error.message && response.error.message.length > 0) {
                this.handleNotification(response.error.message, true);
            }
        });
    }

    wsGetCampaignProducts(ID, MovePage, PracticeID, BrandIDs, ProductID, FacebookID, FacebookUsername, InstagramID, InstagramUsername) {
        APIController.GetCampaignProducts(ID)
            .then((response) => {
                if (response.object.status == '1') {
                    let Temp = []
                    JSON.parse(BrandIDs).map((item) => {
                        Temp.push({ 'Checked': true, ID: item.ID })
                    })
                    AppConstants.SetLocalStorage("localStorageBrandsData", JSON.stringify(Temp))
                    if (MovePage == "0") {
                        AppConstants.SetLocalStorage("localStorageCalenderCart", "0")

                        AppConstants.SetLocalStorage('localStoragepracticeId', PracticeID)
                        this.props.history.push({ pathname: "/calendar", state: { calendarBack: true } })
                        // obj.FacebookID, obj.FacebookUsername, obj.InstagramID, obj.InstagramUsername

                    } else {
                        // setdataSource(response.object.data)
                        response.object.data.map((obj, index) => {

                            if (obj.ID == ProductID) {

                                if (FacebookID != null && FacebookID != '' && FacebookID != undefined) {
                                    AppConstants.SetLocalStorage("localStorageFBPageData", JSON.stringify({ 'id': FacebookID, 'name': FacebookUsername }));
                                } else { AppConstants.SetLocalStorage("localStorageFBPageData", ''); }

                                if (InstagramID != null && InstagramID != '' && InstagramID != undefined) {
                                    AppConstants.SetLocalStorage("localStorageInstaUserData", JSON.stringify({ 'id': InstagramID, 'username': InstagramUsername }));
                                } else { AppConstants.SetLocalStorage("localStorageInstaUserData", ''); }

                                AppConstants.SetLocalStorage("localStorageProductID", obj.Children != null && obj.Children.length ? JSON.parse(obj.Children)[0].ID : obj.ID)
                                AppConstants.SetLocalStorage("localStorageMultiSelectPractice", "0")
                                AppConstants.SetLocalStorage("localStorageProductName", obj.Name)
                                AppConstants.SetLocalStorage("localStorageISDirectMail", obj.Name == "Direct Mail" ? 1 : 0)
                                AppConstants.SetLocalStorage("localStorageMenuPath", obj.MenuPath)
                                AppConstants.SetLocalStorage("localStorageShowAddToCart", obj.ShowAddToCart)
                                AppConstants.SetLocalStorage("localStorageShowCopyText", obj.ShowCopyText)
                                AppConstants.SetLocalStorage("localStorageThemeButtons", obj.ThemeButtons)
                                AppConstants.SetLocalStorage("localStorageFromCart", 1)
                                AppConstants.SetLocalStorage('localStorageOrderDetailsID', 0)
                                AppConstants.SetLocalStorage("localStorageBudgetDetail", obj.PageURL == "/digital-product/selection/location-selection" ? JSON.stringify([{ "budget": 1, "duration": 1 }]) : JSON.stringify([{ "budget": 1, "duration": "" }]))
                                AppConstants.SetLocalStorage("localStorageAutomation", "NO")
                                AppConstants.SetLocalStorage("localStorageChildren", obj.Children != null && obj.Children)
                                AppConstants.SetLocalStorage('localStoragepracticeId', PracticeID)
                                this.props.history.push('/digital-product/selection/social-automation');
                            }
                        })
                    }
                }
            })
    }

    wsPlaceOrder(data, name) {
        APIController.PlaceOrder(this.state.UseCoOp).then((response) => {
            this.setState({ progress: false });
            if (response.error == null) {
                if (response.object.status != 0) {
                    this.props.history.push({
                        pathname: '/ordersuccess', state: { name: name, OrderID: response.object.data[0] }
                    })
                }
            } else if (response.error.message && response.error.message.length > 0) {
                this.handleNotification(response.error.message, true);
            }
        });
    }

    wsOrderPayment(PaymentStatus, LocalPaymentID, PayPalID, PaymentAmount, UseCoOp, details) {
        APIController.OrderPayment(PaymentStatus, LocalPaymentID, PayPalID, PaymentAmount, UseCoOp)
            .then((response) => {
                if (response.error == null) {
                    if (response.object.status != 0) {
                        this.wsFrontEnd(details, response.object.data[0].OrderID, PaymentAmount)
                    }
                }
            }).catch((err) => { })
    }

    wsFrontEnd(details, OrderID, PaymentAmount) {
        APIController.FrontEnd(details).then((response) => {
            if (response.error == null) {
                if (response.object.status != 0) {
                    this.handleNotification(response.object.message);
                    setTimeout(() => {
                        this.props.history.push({
                            pathname: '/ordersuccess', state: {
                                name: details.payer.name.given_name + " " + details.payer.name.surname,
                                OrderID: this.state.OrderID
                            }
                        })
                        this.wsGetCart();
                    }, 4000);
                    this.handleFirebaseEvents(PaymentAmount)
                }
            } else if (response.error.message && response.error.message.length > 0) {
                this.handleNotification(response.error.message, true);
            }
        });
    }

    handleFirebaseEvents = (Amount) => {
        AppConstants.LogEvent('Purchase',
            AppConstants.CreateLogEventObject("",
                AppConstants.GetLocalStorage('localStorageBrandName'),
                AppConstants.GetLocalStorage('localStorageCampaignName'),
                AppConstants.GetLocalStorage('localStorageProductName'),
                this.state.LocationsName.toString(),
                AppConstants.GetLocalStorage('localStorageThemesName'), "", "", "", "", "", Amount))
    }

    handleNotification = (message, isError) => {
        this.setState({
            message: message,
            showNotification: true,
            isError: isError,
        });

        setTimeout(() => {
            this.handleClearNotification();
        }, 4000);
    };

    handleClearNotification = () => {
        this.setState({
            message: "",
            showNotification: false,
            isError: false,
        });
    };

    onLocationExpand(bool, index) {
        this.setState({
            isLocationExpanded: bool, isLocationExpandedIndex: index
        })
    }
    onBudgetExpand(bool, index) {
        this.setState({
            isBudgetExpanded: bool, isBudgetExpandedIndex: index
        })
    }

    onCheck(totalAmout) {
        this.setState({
            isCheck: true,
            totalAmout: totalAmout,
            UseCoOp: 1
        })
    }

    wsGetPracticeByID = (ID, planID, startDate) => {
        this.setState({ PlanID: planID, StartDate: startDate })
        let Data = []
        APIController.GetPracticeByID(ID)
            .then((response) => {
                if (response.object.message == "") {
                    Data = {
                        "name": {
                            "given_name": response.object.data[0].FirstName,
                            "surname": response.object.data[0].LastName
                        },
                        "email_address": response.object.data[0].EmailAddress,
                        "shipping_address": {
                            "name": {
                                "full_name": response.object.data[0].FirstName + " " + response.object.data[0].LastName
                            },
                            "address": {
                                "address_line_1": response.object.data[0].Address1,
                                "address_line_2": response.object.data[0].Address2,
                                "admin_area_2": response.object.data[0].City,
                                "admin_area_1": response.object.data[0].State,
                                "postal_code": response.object.data[0].Postcode,
                                "country_code": "US"
                            }
                        }
                    }
                    this.setState({ PracticeDetails: Data, OrderByName: response.object.data[0].FirstName + " " + response.object.data[0].LastName })
                    // setPayPalButtonShow(true)
                }
            })
    }

    paypalSubscribe = (data, actions) => {
        return actions.subscription.create({
            "plan_id": this.state.PlanID,
            "start_time": this.state.StartDate,
            "custom_id": AppConstants.GetLocalStorage("localStorageAutomationSubscriptionID"),
            "subscriber": this.state.PracticeDetails
        });
    };

    paypalOnError = (err) => {
        console.log("Error", err)
    }
    paypalOnApprove = (data, detail) => {
        // call the backend api to store transaction details
        this.props.history.push({
            pathname: '/paymentSuccess', state: { AutomationSubscriptionID: AppConstants.GetLocalStorage("localStorageAutomationSubscriptionID"), OrderByName: this.state.OrderByName }
        })
    };

    wsActivateSubscriptionViaBypassingPayPalFlow(ID) {
        APIController.ActivateSubscriptionViaBypassingPayPalFlow(ID).then((response) => {
            if (response.object.status == 1) {
                this.handleNotification(response.object.message);
                setTimeout(() => {
                    this.props.history.push({
                        pathname: '/paymentSuccess', state: { AutomationSubscriptionID: AppConstants.GetLocalStorage("localStorageAutomationSubscriptionID"), OrderByName: this.state.OrderByName }
                    })
                }, 4000);
                // this.handleFirebaseEvents(PaymentAmount)
            } else {
                this.handleNotification(response.object.message, true);
            }
        });
    }

    render() {
        const { navigate } = this.props;
        return (
            <>
                <section className='content-right-main'>
                    <PageTitle Title={AppConstants.ContactDetais.YourCart} />
                    {this.state.ProductsList.length || this.state.SubscriptionList.length ? <>
                        {this.state.ProductsList.length ?
                            <div className='two-col-sec'>

                                {AppConstants.GetMainClassName() == "app-main" && <h4>Products</h4>}

                                {this.state.ProductsList.map((obj, index) => {
                                    return (
                                        <div className='cart-list-content'>
                                            <div style={{ width: '5%', margin: '0px 3% 0px 1%' }}>
                                                <img src={obj.ProductImageURL} alt='icon' />
                                            </div>
                                            <div style={{ display: 'grid', width: '100%', marginBottom: '1%' }}>
                                                <label className='cart-label-heading'>{obj.BrandName}</label>
                                                {AppConstants.GetMainClassName() == "app-main" && <>
                                                    <label className='cart-label-subdata'>Campaign : {obj.CampaignName}</label>
                                                    <label className='cart-label-subdata'>Campaign Budget : {obj.CampaignBudget}</label>
                                                    <label className='cart-label-subdata'>Duration : {obj.Duration} months</label>
                                                </>}
                                                <div><label className='cart-label-subdata-link' onClick={() => this.wsEditCart(obj.OrderDetailsID, '0', obj.Locations)}>View Art Proof(s)</label></div>
                                                {this.state.isLocationExpanded == false ?
                                                    <div><label className='cart-label-subdata-link' onClick={() => this.onLocationExpand(true, index)}>Show Location Details </label></div>
                                                    : <div><label className='cart-label-subdata-link' onClick={() => this.onLocationExpand(false, index)}>Hide Location Details </label></div>}
                                                {this.state.isLocationExpanded == true && this.state.isLocationExpandedIndex == index &&
                                                    JSON.parse(obj.Locations) != null ? JSON.parse(obj.Locations).map((obj, index) => {
                                                        return (
                                                            <div style={{ marginTop: '5px', display: 'flex' }}>
                                                                <LocationOnIcon style={{ fontSize: '18px' }} />&nbsp;
                                                                <label className='cart-label-location'>{obj.Location}</label>
                                                            </div>
                                                        );
                                                    })
                                                    : <></>}

                                                {this.state.isBudgetExpanded == false ?
                                                    <div> <label className='cart-label-subdata-link' onClick={() => this.onBudgetExpand(true, index)}>Show Budget Details </label> </div>
                                                    : <div> <label className='cart-label-subdata-link' onClick={() => this.onBudgetExpand(false, index)}>Hide Budget Details </label></div>}
                                                {this.state.isBudgetExpanded == true && this.state.isBudgetExpandedIndex == index &&
                                                    JSON.parse(obj.MonthlyBudget) != null ? JSON.parse(obj.MonthlyBudget).map((obj, index) => {
                                                        return (
                                                            <div style={{ marginTop: '5px', display: 'flex' }}>
                                                                <label className='cart-label-location'>{obj.ProductName}</label>&nbsp;-&nbsp;
                                                                <label className='cart-label-location'>{obj.Budget}</label>
                                                            </div>
                                                        );
                                                    })
                                                    : <></>}

                                            </div>
                                            <div style={{ display: 'grid', justifyItems: 'end' }}>
                                                <label className='cart-label-heading'>{obj.TotalAmount}</label>
                                                <div style={{ display: 'flex' }}>
                                                    <label className='cart-label-subdata-link' onClick={() => this.wsEditCart(obj.OrderDetailsID, '1', obj.Locations)}>Edit&nbsp;&nbsp;|&nbsp;&nbsp;</label>
                                                    <label className='cart-label-subdata-link' onClick={() => this.setState({ deleteId: obj.OrderDetailsID, deleteDialog: true, isSubscriptionDelete: false })}>Remove</label>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                                {this.state.ProductsList.length && this.state.SummaryList.length ?
                                    this.state.SummaryList.map((obj, index) => {
                                        return (
                                            <div className='cart-price-main'>
                                                <div style={{ marginLeft: '45%' }}>
                                                    {obj.MaxCoOp != "$0.00" ? <>
                                                        <div style={{ marginBottom: '1%', display: 'flex', alignItems: 'center  ' }} >
                                                            <Ic_coin />&nbsp;&nbsp;&nbsp;
                                                            <label className='cart-fund-text'>Co-Op Funds Available</label>&nbsp;&nbsp;&nbsp;
                                                            <label className='cart-fund-amount'>{obj.CoOpAmount}</label>
                                                        </div>

                                                        <div style={{ margin: '2% 0', display: 'flex' }}>
                                                            <input class="check-input-coop"
                                                                type='checkbox'
                                                                name='checkCoop'
                                                                id='checkCoop'
                                                                checked={this.state.isCheck === true}
                                                                value={this.state.isCheck}
                                                                onChange={(e) => this.setState({ isCheck: e.target.checked })} />&nbsp;&nbsp;&nbsp;
                                                            <label className='cart-fund-text'>Apply my Co-Op balance to this order</label>
                                                        </div>
                                                        <Divider style={{ width: '100%', height: '1px', borderRadius: '100px', marginTop: '1%' }} orientation="vertical" />
                                                    </> : <></>}

                                                    <div className='cart-total-div'>
                                                        <label className='cart-subtotal-text'>Subtotal :</label>
                                                        <label className='cart-subtotal-text'>{obj.SubTotal}</label>
                                                    </div>

                                                    {obj.MaxCoOp != "$0.00" && this.state.isCheck == true ?
                                                        <div className='cart-total-div' >
                                                            <label className='cart-subtotal-text'>Less Co-Op :</label>
                                                            <label className='cart-subtotal-text'>- {obj.MaxCoOp}</label>
                                                        </div> : <></>}

                                                    <Divider style={{ width: '100%', height: '1px', borderRadius: '100px', marginTop: '1%' }} orientation="vertical" />

                                                    <div className='cart-total-div'>
                                                        <label className='cart-total-text'>Gross Total :</label>
                                                        <label className='cart-total-text'>{obj.MaxCoOp == "$0.00" ? obj.SubTotal : this.state.isCheck ? obj.GrossAmount : obj.SubTotal}</label>
                                                    </div>

                                                    <div style={{ marginTop: '2%', alignItems: 'center', textAlign: 'end' }}>
                                                        <input class="red-input"
                                                            type='checkbox'
                                                            name='checkMe'
                                                            id='checkMe'
                                                            checked={this.state.TermsCheckbox === true}
                                                            value={this.state.TermsCheckbox}
                                                            onChange={(e) => this.setState({ TermsCheckbox: e.target.checked })} />&nbsp;&nbsp;&nbsp;
                                                        <label className='cart-fund-text' style={{ fontSize: '12px' }}>I agree to the</label>&nbsp;
                                                        <label className='cart-terms-conditions' onClick={() => this.setState({ isModalOpen: true })}>terms & conditions.</label>
                                                    </div>
                                                </div>

                                                <div className='cart-total-div cart-total-div-button'>
                                                    {AppConstants.GetMainClassName() == "app-yomi" &&
                                                        <div className='button-field'>
                                                            <input type='submit' value='Back' name='Back' onClick={() => this.props.history.goBack()} />
                                                        </div>}

                                                    {sessionStorage.setItem("PaymentAmount", obj.MaxCoOp == "$0.00" ? parseFloat(obj.SubTotal.substring(1).replace(/,/g, ''))
                                                        : this.state.isCheck ? parseFloat(obj.GrossAmount.substring(1).replace(/,/g, ''))
                                                            : parseFloat(obj.SubTotal.substring(1).replace(/,/g, '')))}

                                                    <div className='paypal-button-container'>
                                                        <PayPalButton
                                                            style={{ layout: 'horizontal', color: 'blue', shape: 'pill', label: 'checkout', Size: 'medium', height: 43, tagline: false }}
                                                            createOrder={(data, actions) =>
                                                                APIController.InitiateTransaction(obj.MaxCoOp == "$0.00" ? obj.SubTotal : this.state.isCheck ? obj.GrossAmount : obj.SubTotal, obj.OrderID)
                                                                    .then((res) => {
                                                                        if (res.error == null) {
                                                                            this.setState({ LocalPaymentID: res.object.data[0].LocalPaymentID, OrderID: res.object.data[0].OrderID })
                                                                            if (res.object.status != 0) {
                                                                                return actions.order.create({
                                                                                    purchase_units: [{
                                                                                        custom_id: res.object.data[0].OrderID,
                                                                                        reference_id: res.object.data[0].LocalPaymentID,
                                                                                        amount: {
                                                                                            currency_code: "USD",
                                                                                            value: sessionStorage.getItem("PaymentAmount")
                                                                                            // value: "0.01"
                                                                                        }
                                                                                    }],
                                                                                    application_context: {
                                                                                        shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
                                                                                    }
                                                                                })
                                                                            }
                                                                        }
                                                                    }).catch((err) => { })
                                                            }

                                                            onApprove={(data, actions) => {
                                                                actions.order.capture().then((details) => {
                                                                    this.wsOrderPayment('2', this.state.LocalPaymentID, details.id, obj.MaxCoOp == "$0.00" ? obj.SubTotal : this.state.isCheck ? obj.GrossAmount : obj.SubTotal, this.state.UseCoOp.toString(), details, this.state.OrderID)
                                                                });
                                                            }}
                                                            onCancel={(data) => { }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }) : <></>}

                            </div> : <></>}

                        {AppConstants.GetMainClassName() == "app-main" && this.state.SubscriptionList.length ?
                            <div className='two-col-sec'>

                                <h4>Subscriptions</h4>

                                {this.state.SubscriptionList.map((obj, index) => {
                                    return (
                                        <div className='cart-list-content'>
                                            <div style={{ width: '5%', margin: '0px 3% 0px 1%' }}>
                                                <img src={obj.ProductImageURL} alt='icon' />
                                            </div>
                                            <div style={{ display: 'grid', width: '100%', marginBottom: '1%' }}>
                                                <label className='cart-label-heading'>{obj.ProductName}</label>
                                                {AppConstants.GetMainClassName() == "app-main" && <>
                                                    <label className='cart-label-subdata'>Brands : {obj.BrandName}</label>
                                                    <label className='cart-label-subdata'>Campaign Budget : {obj.CampaignBudget}</label>
                                                    {/* <label className='cart-label-subdata'>Duration : {obj.Duration} months</label> */}
                                                </>}
                                                <div> <label className='cart-label-subdata-link' onClick={() => this.wsGetCampaignProducts(obj.CampaignID, '0', obj.PracticeID, obj.BrandIDs, obj.ProductID)}>View Art Proof(s)</label></div>
                                                {this.state.isLocationExpanded == false ?
                                                    <div><label className='cart-label-subdata-link' onClick={() => this.onLocationExpand(true, index)}>Show Location Details </label></div>
                                                    : <div><label className='cart-label-subdata-link' onClick={() => this.onLocationExpand(false, index)}>Hide Location Details </label></div>}
                                                {this.state.isLocationExpanded == true && this.state.isLocationExpandedIndex == index &&
                                                    JSON.parse(obj.Locations) != null ? JSON.parse(obj.Locations).map((obj, index) => {
                                                        return (
                                                            <div style={{ marginTop: '5px', display: 'flex' }}>
                                                                <LocationOnIcon style={{ fontSize: '18px' }} />&nbsp;
                                                                <label className='cart-label-location'>{obj.Location}</label>
                                                            </div>
                                                        );
                                                    })
                                                    : <></>}
                                            </div>
                                            <div style={{ display: 'grid', justifyItems: 'end' }}>
                                                <label className='cart-label-heading'>{obj.TotalAmount}</label>
                                                <div style={{ display: 'flex' }}>
                                                    <label className='cart-label-subdata-link' onClick={() => this.wsGetCampaignProducts(obj.CampaignID, '1', obj.PracticeID, obj.BrandIDs, obj.ProductID, obj.FacebookID, obj.FacebookUsername, obj.InstagramID, obj.InstagramUsername)}>Edit&nbsp;&nbsp;|&nbsp;&nbsp;</label>
                                                    <label className='cart-label-subdata-link' onClick={() => this.setState({ deleteId: obj.OrderDetailsID, isSubscriptionDelete: true, deleteDialog: true })}>Remove</label>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                                {this.state.SubscriptionList.length && this.state.SubscriptionsSummaryList.length ?
                                    this.state.SubscriptionsSummaryList.map((obj, index) => {
                                        return (
                                            <div className='cart-price-main'>
                                                <div style={{ marginLeft: '45%' }}>
                                                    {obj.MaxCoOp != "$0.00" ? <>
                                                        <div style={{ marginBottom: '1%', display: 'flex', alignItems: 'center  ' }} >
                                                            <Ic_coin />&nbsp;&nbsp;&nbsp;
                                                            <label className='cart-fund-text'>Co-Op Funds Available</label>&nbsp;&nbsp;&nbsp;
                                                            <label className='cart-fund-amount'>{obj.CoOpAmount}</label>
                                                        </div>

                                                        <div style={{ margin: '2% 0', display: 'flex' }}>
                                                            <input class="check-input-coop"
                                                                type='checkbox'
                                                                name='checkCoop'
                                                                id='checkCoop'
                                                                checked={this.state.isCheck === true}
                                                                value={this.state.isCheck}
                                                                onChange={(e) => this.setState({ isCheck: e.target.checked })} />&nbsp;&nbsp;&nbsp;
                                                            <label className='cart-fund-text'>Apply my Co-Op balance to this order</label>
                                                        </div>
                                                        <Divider style={{ width: '100%', height: '1px', borderRadius: '100px', marginTop: '1%' }} orientation="vertical" />
                                                    </> : <></>}

                                                    <div className='cart-total-div'>
                                                        <label className='cart-subtotal-text'>Subtotal :</label>
                                                        <label className='cart-subtotal-text'>{obj.SubTotal}</label>
                                                    </div>

                                                    {obj.MaxCoOp != "$0.00" && this.state.isCheck == true ?
                                                        <div className='cart-total-div' >
                                                            <label className='cart-subtotal-text'>Less Co-Op :</label>
                                                            <label className='cart-subtotal-text'>- {obj.MaxCoOp}</label>
                                                        </div> : <></>}

                                                    <Divider style={{ width: '100%', height: '1px', borderRadius: '100px', marginTop: '1%' }} orientation="vertical" />

                                                    <div className='cart-total-div'>
                                                        <label className='cart-total-text'>Gross Total :</label>
                                                        <label className='cart-total-text'>{obj.MaxCoOp == "$0.00" ? obj.SubTotal : this.state.isCheck ? obj.GrossAmount : obj.SubTotal}</label>
                                                    </div>

                                                    <div style={{ marginTop: '2%', alignItems: 'center', textAlign: 'end' }}>
                                                        <input class="red-input"
                                                            type='checkbox'
                                                            name='checkMe'
                                                            id='checkMe'
                                                            checked={this.state.TermsCheckbox === true}
                                                            value={this.state.TermsCheckbox}
                                                            onChange={(e) => this.setState({ TermsCheckbox: e.target.checked })} />&nbsp;&nbsp;&nbsp;
                                                        <label className='cart-fund-text' style={{ fontSize: '12px' }}>I agree to the</label>&nbsp;
                                                        <label className='cart-terms-conditions' onClick={() => this.setState({ isModalOpen: true })}>terms & conditions.</label>
                                                    </div>
                                                </div>

                                                <div className='cart-total-div'>
                                                    {/* <div className='button-field'>
                                                        <input type='submit' value='Back' name='Back' onClick={() => this.props.history.goBack()} />
                                                    </div> */}

                                                    {sessionStorage.setItem("PaymentAmount", obj.MaxCoOp == "$0.00" ? parseFloat(obj.SubTotal.substring(1).replace(/,/g, ''))
                                                        : this.state.isCheck ? parseFloat(obj.GrossAmount.substring(1).replace(/,/g, ''))
                                                            : parseFloat(obj.SubTotal.substring(1).replace(/,/g, '')))}
                                                    {AppConstants.SetLocalStorage("localStorageAutomationSubscriptionID", obj.OrderID)}

                                                    {this.state.SubscriptionList[0].BypassPayPalFlow ?
                                                        <div className='button-field'>
                                                            <input type='submit' value='Subscribe Now' name='SubscribeNow' onClick={() => {
                                                                this.wsActivateSubscriptionViaBypassingPayPalFlow(obj.OrderID)
                                                            }} />
                                                        </div>
                                                        : <div className='paypal-button-container'>
                                                            <PayPalButton
                                                                amount="0.01"
                                                                options={{ vault: true }}
                                                                currency="USD"
                                                                createSubscription={this.paypalSubscribe}
                                                                onApprove={this.paypalOnApprove}
                                                                catchError={this.paypalOnError}
                                                                onError={this.paypalOnError}
                                                                onCancel={this.paypalOnError}
                                                                style={{ shape: 'rect', color: 'blue', layout: 'horizontal', label: 'subscribe', Size: 'medium', height: 43, tagline: false }}
                                                            />
                                                        </div>}
                                                </div>
                                            </div>
                                        );
                                    }) : <></>}

                            </div>
                            : <></>}

                    </> : <div className="two-col-sec not_found">
                        {this.state.progress ? <CircularProgress /> : <label>Your Cart is Empty</label>}
                    </div>}

                    <Notification
                        isError={this.state.isError}
                        message={this.state.message}
                        showNotification={this.state.showNotification}
                        clearNotification={this.handleClearNotification}
                        closeNotification={this.handleClearNotification}
                    />
                    <Modal
                        show={this.state.isModalOpen}
                        handleClose={() => this.setState({ isModalOpen: false })}>
                        <TermsAndConditionDialog
                            title={"Terms & Conditions"}
                            description={<Typography style={{ fontSize: '12px', margin: '3%', textAlign: 'justify' }}>{AppConstants.TermsConditions.TermsConditionsText}</Typography>}
                            closeModal={() => this.setState({ isModalOpen: false })}
                        />
                    </Modal>
                    {this.state.deleteDialog && (
                        <Modal
                            show={this.state.deleteDialog}
                            handleClose={() => this.setState({ deleteDialog: false })}>
                            <ConfirmationDialog
                                title={"Remove Campaign"}
                                description={"Are you sure you want to remove Campaign from Cart?"}
                                deletePress={() => this.state.isSubscriptionDelete ? this.wsDeleteSubscriptionCart(this.state.deleteId) : this.wsDeleteCart(this.state.deleteId)}
                                closeModal={() => this.setState({ deleteDialog: false })}
                            />
                        </Modal>
                    )}
                </section>
            </>
        )
    }
}

export default Cart;


{/* <PayPalButton
    amount="0.01"
    // onClick={() => { alert("Transaction completed by ", this.state.TermsCheckbox) }}
    style={{
        layout: 'horizontal',
        color: 'blue',
        shape: 'pill',
        label: 'pay',
        Size: 'medium',
        height: 45,
        tagline: false,
    }}
    options={{ "orderid": obj.OrderID }}
    shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
    // onError={() => this.wsPlaceOrder(0, '')}
    onSuccess={(details, data) => {
        this.setState({ progress: true });
        this.wsPlaceOrder(1, details.payer.name.given_name)
        // OPTIONAL: Call your server to save the transaction
        return fetch("/paypal-transaction-complete", {
            method: "post",
            body: JSON.stringify({ orderID: data.orderID })
        });
    }}
/>  */}

// onApprove = {(data, actions) => {
//     actions.order.capture().then((details) => {
//         this.wsFrontEnd(details)
//     });
// }}

// createOrder = {(data, actions) => {
//     return actions.order.create({
//         purchase_units: [{
//             custom_id: obj.OrderID,
//             // invoice_id: res.object.data[0].LocalPaymentID,
//             amount: {
//                 currency_code: "USD",
//                 value: sessionStorage.getItem("PaymentAmount")
//                 // value: "0.01"
//             }
//         }],
//         application_context: {
//             shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
//         }
//     })
// }}
