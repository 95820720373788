import React from "react";
import * as AppConstants from "../../../../config/AppConstant";

const TilesForAdBuilder = (props) => {
    const { index, obj, setmodal } = props;

    const [duration, setDuration] = React.useState('');

    React.useEffect(() => {
        if (AppConstants.GetLocalStorage("localStorageMultiSelectPractice") == "0") {
            let data = AppConstants.GetLocalStorage("localStorageDuration") != '' && JSON.parse(AppConstants.GetLocalStorage("localStorageDuration"));
            setDuration(data != '' ? data[0].Duration : '')
        }
    }, []);

    return (
        <div className="content-box free-asset-content-box" style={{ width: '245px' }} key={index}>
            <div className="box-image free-asset-image-box" style={{ backgroundImage: `url(${obj.PreviewURL} )` }}>
                <div className="button-hover" style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <div className="button-field" style={{ cursor: 'pointer' }}>
                        <button onClick={() => {
                            props.history.push(AppConstants.GetLocalStorage('localStorageMenuPath'))
                            if (AppConstants.GetLocalStorage("localStorageMultiSelectPractice") == "0") {
                                AppConstants.SetLocalStorage("localStorageBudgetDetail", JSON.stringify([{ "budget": 1, "duration": duration }]))
                                AppConstants.SetLocalStorage("localStorageAutomation", "YES")
                            }

                            AppConstants.SetLocalStorage("localStorageThemesID", obj.ID)
                            AppConstants.SetLocalStorage("localStorageThemesName", obj.Name)
                            AppConstants.SetLocalStorage("localStorageFreeAssetID", "")
                        }} > {AppConstants.GetLocalStorage('localStorageShowCopyText') == 0 ? AppConstants.ButtonText.Order : AppConstants.ButtonText.Customize} </button>
                    </div>
                    <div className="button-field">
                        <button onClick={() =>
                            setmodal({
                                isModalOpen: true,
                                content: { image: obj.ImageURL },
                            })}>{AppConstants.ButtonText.Preview}</button>
                    </div>
                </div>
            </div>

            <div className="box-content-info" style={{ padding: '5px 5px' }}>
                {obj.Name != undefined && <p>
                    {obj.Name.split('®')[0]}
                    {obj.Name.match(/®/g) ? <sup>&reg;</sup> : ''}
                    {obj.Name.split('®')[1]}
                </p>}
                <h4>{obj.CampaignName}</h4>
            </div>
        </div>
    )
}
export default TilesForAdBuilder;