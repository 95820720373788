import React from "react";
import * as APIController from '../../../api/APIController.js';
import * as AppConstants from "../../../config/AppConstant";
import PageTitle from "../../../config/pageTitle";

import moment from 'moment';

import Notification from '../../../shared/components/notification/notification';
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";

import AddEditSocialPost from './AddEditSocialPost'

import ModalComponent from "../../../shared/components/modal/modal";
import ConfirmationDialog from '../../../features/ConfirmDialog/ConfirmationDialog';
import ThankyouDialog from '../../../features/ConfirmDialog/ThankyouDialog';

import layer_color from "../../../assets/images/icon/layercolor.png";
import left_arrow_blue from '../../../assets/images/left-arrow-blue.png';
import right_arrow_blue from '../../../assets/images/right-arrow-blue.png';
import left_arrow from '../../../assets/images/left-arrow.svg';
import right_arrow from '../../../assets/images/right-arrow.svg';

class SocialMediaCalendar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            message: "",
            showNotification: false,
            isError: false,
            PracticeID: AppConstants.GetLocalStorage("localStoragepracticeId"),
            AutomationSubscriptionID: AppConstants.GetLocalStorage("localStorageAutomationSubscriptionID"),
            calenderViewFromCart: AppConstants.GetLocalStorage("localStorageCalenderCart"),
            CalendarAssetsAllData: [], AssetsDataFinal: [], CalendarForPractice: [], FBPageData: [], InstaPageData: [], InstaUserData: [], BrandIDs: [],
            AutomationCalendarID: '', PracticeDetails: '', OrderByName: '',
            isLoading: false, AddCartBtn: false, isLoadingCards: true, ConfirmDialog: false, isConfirmLoading: false,

            timeRangesData: [],
            MonthNames: ["JAN", "FEB", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUG", "SEP", "OCT", "NOV", "DEC"],
            DayNames: [{ "ID": 1, "Name": "Sun" }, { "ID": 2, "Name": "Mon" }, { "ID": 3, "Name": "Tue" }, { "ID": 4, "Name": "Wed" }, { "ID": 5, "Name": "Thu" }, { "ID": 6, "Name": "Fri" }, { "ID": 7, "Name": "Sat" }],
            CurrentDate: this.getDateFragments(new Date(), 1),
            CurrentMonth: this.getDateFragments(new Date(), 2) + 1,
            CurrentYear: this.getDateFragments(new Date(), 3),

            CurrentMonthName: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][new Date().getMonth()],
            CurrentDayName: new Date().getDay(),
            PracticeMonthData: [],
            PreviewModalData: null,
            FirstDateWeek: new Date(new Date().getFullYear(), new Date().getMonth(), 1).getDay() + 1,

            FinalArray: [],

            DeleteId: '', DeleteStatusID: '',
            activeStep: 1,

            isReschedule: false, assetID: 0,

            FBCHAKE: false, IGCHAKE: false, postTimeRanges: moment(new Date(), 'ddd DD-MMM-YYYY, hh:mm A').format('hh:mm A'),
            postImageName: '', postImageData: '', baseImageData: '', description: '', ScheduledAtDate: moment(new Date()).format('YYYY-MM-DD')
        };
    }

    getDay(dt) {
        const d = new Date(dt.replace('-', '/').replace('-', '/').replace('-', '/'));
        return d.getDate();
    }

    getDateFragments(dt, typeid) {
        //const d = new Date(dt.replace('-', '/').replace('-', '/').replace('-', '/'));
        const d = dt;
        if (typeid == 1) {
            return d.getDate();
        }
        else if (typeid == 2) {
            return d.getMonth();
        }
        else if (typeid == 3) {
            return d.getFullYear();
        }
    }

    componentDidMount = () => {
        if (AppConstants.GetLocalStorage('localStorageFBPageData') != "" && AppConstants.GetLocalStorage('localStorageFBPageData') != undefined && AppConstants.GetLocalStorage('localStorageFBPageData') != null) {
            this.setState({ FBPageData: JSON.parse(AppConstants.GetLocalStorage('localStorageFBPageData')) })
        }
        if (AppConstants.GetLocalStorage('localStorageInstaPageData') != "" && AppConstants.GetLocalStorage('localStorageInstaPageData') != undefined && AppConstants.GetLocalStorage('localStorageInstaPageData') != null) {
            this.setState({ InstaPageData: AppConstants.GetLocalStorage('localStorageInstaPageData') })
        }
        if (AppConstants.GetLocalStorage('localStorageInstaUserData') != "" && AppConstants.GetLocalStorage('localStorageInstaUserData') != undefined && AppConstants.GetLocalStorage('localStorageInstaUserData') != null) {
            this.setState({ InstaUserData: JSON.parse(AppConstants.GetLocalStorage('localStorageInstaUserData')) })
        }

        this.wsGetAutomationCalendarForPractice(AppConstants.GetLocalStorage('localStorageBrandsData').length && JSON.parse(AppConstants.GetLocalStorage('localStorageBrandsData')), this.state.PracticeID, this.state.AutomationSubscriptionID)

        let Data = [];
        AppConstants.GetLocalStorage('localStorageBrandsData').length && JSON.parse(AppConstants.GetLocalStorage('localStorageBrandsData')).map((item1) => { item1.Checked && Data.push(item1.ID) })

        this.setState({ BrandIDs: Data })
    }

    wsGetAutomationCalendarForPractice(BrandsData, PracticeID, ID) {
        this.setState({ isLoading: true })
        let DATA = [], monthData = [];

        APIController.GetAutomationCalendarForPractice(PracticeID, ID)
            .then((response) => {
                if (response.object.data.length) {
                    this.setState({
                        AutomationCalendarID: response.object.data[0].CalendarID,
                        CurrentYear: response.object.data[0].CalendarYear,
                        CurrentMonth: response.object.data[0].CalendarMonth
                    })
                    const CurrentMonthData = response.object.data.filter((e) => e.CalendarMonth == this.state.CurrentMonth)

                    // BrandsData.length && BrandsData.map((item1) => {
                    //     item1.Checked && CurrentMonthData.map((item) => {
                    //         JSON.parse(item.CalendarAssets).map((itm) => {
                    //             if (item1.ID == itm.BrandID) { DATA.push(itm) }
                    //         })
                    //     })
                    // })
                    // CurrentMonthData.map((item) => {
                    //     JSON.parse(item.CalendarAssets).map((itm) => {
                    //         itm.BrandID == '0' && DATA.push(itm)
                    //     })
                    // })

                    let FinalArray = [], Counter = 0, FirstDay = 0;

                    CurrentMonthData.map((item) => {
                        JSON.parse(item.CalendarAssets).map((itm) => {
                            if (Counter == 0) {
                                FirstDay = itm.DayNo == undefined ? this.state.FirstDateWeek : itm.DayNo;
                                for (let t = 1; t < FirstDay; t++) {
                                    FinalArray.push({ DayNo: '0' })
                                }
                                Counter = FirstDay;
                            }
                            FinalArray.push({ ...itm, totalAssets: JSON.parse(item.CalendarAssets).filter((e) => e.AssetDate == itm.AssetDate).length });
                        })
                        this.setState({ FinalArray })
                    })

                    // for (let i = 0; i < 42; i++) {
                    //     if (i < pairs.length + new Date(this.state.CurrentYear, this.state.CurrentMonth - 1, 1).getDay()) {
                    //         let tmp = '', temp = []
                    //         response.object.data.map((item) => {
                    //             // JSON.parse(item.CalendarAssets).map((itm) => {

                    //             //     for (let j = 0; j < itm.DayNo; j++) {
                    //             //         tmp = {};
                    //             //     }

                    //             //     if (itm.AssetDate.split("-")[0] == this.state.CurrentYear &&
                    //             //         itm.AssetDate.split("-")[1] == this.state.CurrentMonth &&
                    //             //         itm.AssetDate.split("-")[2] == i + 1) {
                    //             //         tmp = itm
                    //             //         temp.push(itm)
                    //             //     }
                    //             // })
                    //             // this.setState({ FinalArray: JSON.parse(item.CalendarAssets) })
                    //         })
                    //         cards.push({ ID: i, AssetData: tmp, totalAssets: temp.length });
                    //     }
                    // }
                    let DummyDataInner = []
                    BrandsData.length && BrandsData.forEach(function (element) {
                        response.object.data.forEach(function (innerElement) {
                            JSON.parse(innerElement.CalendarAssets).forEach(function (item) {
                                if (element.ID == item.BrandID) {
                                    DummyDataInner.push({ "ID": item.ID, "Status": element.Checked ? item.Status : 3 })
                                }
                            })

                        })
                    });
                    this.setState({ AssetsDataFinal: DummyDataInner })

                    if (response.object.data.length > 1) {
                        response.object.data.map((item, index) => {
                            monthData.push({ DayNames: item.CalendarMonth, MonthName: this.state.MonthNames[item.CalendarMonth - 1] })
                        })
                        this.setState({
                            PracticeMonthData: monthData,
                            CalendarForPractice: response.object.data
                        })
                    }
                }
                this.setState({
                    CurrentMonthName: this.state.MonthNames[this.getDateFragments(new Date(), 2)], AddCartBtn: false, activeStep: 1,
                    CalendarAssetsAllData: response.object.data, isLoading: false, isLoadingCards: false
                })
            })
    }

    wsAddEditAutomationSubscription(ID, AutomationCalendarID, CampaignID, PracticeID, FacebookUserID, FacebookID, Facebookusername, FacebookAccess_Token,
        InstagramID, Instagramusername, InstagramAccess_Token, BrandIDs, Assets) {
        this.setState({ AddCartBtn: true })
        APIController.AddEditAutomationSubscription(ID, AutomationCalendarID, CampaignID, PracticeID.substring(PracticeID.lastIndexOf(',') + 1), FacebookUserID, FacebookID, Facebookusername, FacebookAccess_Token,
            InstagramID, Instagramusername, InstagramAccess_Token, BrandIDs, Assets).then((response) => {
                if (response.object.status == "1") {
                    this.handleNotification(response.object.message);
                    AppConstants.SetLocalStorage("localStorageAutomationSubscriptionID", response.object.data[0].ID)
                    AppConstants.SetLocalStorage("localStorageSubscriptionPracticeID", PracticeID)
                    setTimeout(() => {
                        ID != 0 ? this.props.history.push('/subscriptions') : this.props.history.push('/cart')
                        this.setState({ AddCartBtn: false })
                    }, 4000)
                } else {
                    this.handleNotification(response.object.message, true);
                    this.setState({ AddCartBtn: false })
                }
            })
    }

    handleNotification = (message, isError) => {
        this.setState({ message: message, showNotification: true, isError: isError });
        setTimeout(() => { this.handleClearNotification(); }, 4000);
    };
    handleClearNotification = () => { this.setState({ message: "", showNotification: false, isError: false, }); };

    paypalSubscribe(data, actions) {
        return actions.subscription.create({
            "plan_id": "P-9JP711594E0846536MIBGGVY",
            "custom_id": this.state.AutomationSubscriptionID,
            "subscriber": this.state.PracticeDetails
        });
    };
    paypalOnError(err) { }
    paypalOnApprove(data, detail) {
        // call the backend api to store transaction details
        this.props.history.push({
            pathname: '/paymentSuccess', state: { AutomationSubscriptionID: this.state.AutomationSubscriptionID, OrderByName: this.state.OrderByName }
        })
    };

    nextMonth(date, currentMonth, currentYear, isAdmin) {
        this.setState({ isLoadingCards: true, CurrentMonth: currentMonth + 1 })
        var current = ''
        if (currentMonth == 11) {
            current = new Date(this.getDateFragments(date, 3) + 1, -1, 1);
            this.setState({ CurrentYear: this.getDateFragments(current, 3), FirstDateWeek: current.getDay() + 1, CurrentMonthName: this.state.MonthNames[this.getDateFragments(current, 2)] })
        } else {
            current = new Date(this.getDateFragments(date, 3), currentMonth, 1);
            this.setState({ CurrentYear: this.getDateFragments(current, 3), FirstDateWeek: current.getDay() + 1, CurrentMonthName: this.state.MonthNames[this.getDateFragments(current, 2)] })
        }

        let FinalArray = [], Counter = 0, FirstDay = 0;
        const CurrentMonthData = this.state.CalendarForPractice.filter((e) => e.CalendarMonth == currentMonth + 1 && e.CalendarYear == this.state.CurrentYear)

        CurrentMonthData.map((item) => {
            JSON.parse(item.CalendarAssets).map((itm) => {
                if (Counter == 0) {
                    FirstDay = itm.DayNo == undefined ? current.getDay() + 1 : itm.DayNo;
                    for (let t = 1; t < FirstDay; t++) {
                        FinalArray.push({ DayNo: '0' })
                    }
                    Counter = FirstDay;
                }
                FinalArray.push({ ...itm, totalAssets: JSON.parse(item.CalendarAssets).filter((e) => e.AssetDate == itm.AssetDate).length });
            })
            this.setState({ FinalArray })
        })

        this.setState({ activeStep: this.state.activeStep + 1, isLoadingCards: false })
    }
    previousMonth(date, currentMonth, currentYear, isAdmin) {
        this.setState({ isLoadingCards: true, CurrentMonth: currentMonth - 1 })
        var current = ''
        if (currentMonth == 1) {
            current = new Date(currentYear, -1, 1);
            this.setState({ CurrentYear: this.getDateFragments(current, 3), FirstDateWeek: current.getDay() + 1, CurrentMonthName: this.state.MonthNames[this.getDateFragments(current, 2)] })
        } else {
            current = new Date(this.getDateFragments(date, 3), currentMonth - 2, 1);
            this.setState({ CurrentYear: this.getDateFragments(current, 3), FirstDateWeek: current.getDay() + 1, CurrentMonthName: this.state.MonthNames[this.getDateFragments(current, 2)] })
        }

        let FinalArray = [], Counter = 0, FirstDay = 0;
        const CurrentMonthData = this.state.CalendarForPractice.filter((e) => e.CalendarMonth == currentMonth - 1 && e.CalendarYear == this.state.CurrentYear)

        CurrentMonthData.map((item) => {
            JSON.parse(item.CalendarAssets).map((itm) => {
                if (Counter == 0) {
                    FirstDay = itm.DayNo == undefined ? current.getDay() + 1 : itm.DayNo;
                    for (let t = 1; t < FirstDay; t++) {
                        FinalArray.push({ DayNo: '0' })
                    }
                    Counter = FirstDay;
                }
                FinalArray.push({ ...itm, totalAssets: JSON.parse(item.CalendarAssets).filter((e) => e.AssetDate == itm.AssetDate).length });
            })
            this.setState({ FinalArray })
        })

        this.setState({ activeStep: this.state.activeStep - 1, isLoadingCards: false })
    }

    addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    render() {
        const { CurrentMonthName, CurrentYear, PracticeMonthData, CurrentMonth, activeStep, calenderViewFromCart } = this.state;
        const { FinalArray, DayNames, isLoadingCards, FirstDateWeek, AddCartBtn } = this.state;
        const { PreviewModalData, ConfirmDialog, DeleteId, DeleteStatusID } = this.state;


        return (
            <section className='content-right-main'>
                {/* CalendarHeading */}
                {/* : !CalendarAssetsData.length ? <div style={{ width: '100%', textAlign: 'center' }}> <h4>Not Currently Available</h4>  </div>  */}
                {AppConstants.GetLocalStorage("IsDirectPageURL") == "3" ?
                    <PageTitle Title={AppConstants.ContactDetais.UpdateSubscription} />
                    : <PageTitle Title={AppConstants.ContactDetais.CalendarHeading} />}
                <div className="two-col-sec digital-welcome">
                    <div style={{ width: '100%', height: "100%" }}>
                        {this.state.isLoading ? <CircularProgress />
                            : <>
                                <div style={{ display: 'flex', width: '100%', justifyContent: "space-between" }}>
                                    <div style={{ width: '33%', alignSelf: 'center' }}>
                                        <PageTitle Title={CurrentMonthName + " " + CurrentYear} />
                                    </div>

                                    <div style={{ display: 'flex', marginTop: '15px', width: '33%', justifyContent: 'space-evenly' }}>
                                        {PracticeMonthData.length != 0 && <>
                                            {PracticeMonthData.findIndex((item) => { return CurrentMonth - 1 == item.DayNames ? true : false }) == -1 ?
                                                <img src={left_arrow} alt="image" style={{ height: '24px', width: '24px', margin: "5px" }} />
                                                : <img src={left_arrow_blue} alt="image" style={{ height: '24px', width: '24px', margin: "5px", cursor: 'pointer' }}
                                                    onClick={() => { this.previousMonth(new Date(), CurrentMonth, CurrentYear, false); }} />}

                                            {PracticeMonthData.findIndex((item) => { return CurrentMonth + 1 == item.DayNames ? true : false }) == -1 ?
                                                <img src={right_arrow} alt="image" style={{ height: '24px', width: '24px', margin: "5px" }} />
                                                : <img src={right_arrow_blue} alt="image" style={{ height: '24px', width: '24px', margin: "5px", cursor: 'pointer' }}
                                                    onClick={() => this.nextMonth(new Date(), CurrentMonth, CurrentYear, false)} />}

                                        </>}
                                    </div>

                                    <div style={{ display: 'flex', margin: '15px 15px 0 0', width: '33%', justifyContent: 'flex-end' }}>
                                        <div style={{ display: 'flex', marginBottom: '20px' }}>
                                            {this.props.history.location.state.calendarBack &&
                                                <div className='button-field'>
                                                    <input type='button' name='button' value={calenderViewFromCart == '1' || calenderViewFromCart == "" ? AppConstants.ButtonText.Back : AppConstants.ButtonText.BackToCart} onClick={() => this.props.history.goBack()} />
                                                </div>}
                                            {/* <div className={`button-field ${!AddCartBtn && PracticeMonthData.length == 0 ? "" : !AddCartBtn && PracticeMonthData.length == activeStep ? "" : "disable-btn"}`}> */}
                                            {calenderViewFromCart == "1" &&
                                                <div className={`button-field`}>
                                                    <input type="button" name="add-edit-automation-subscription" value={this.state.AutomationSubscriptionID == 0 ? "Add to cart" : "Update"} onClick={() => {
                                                        this.wsAddEditAutomationSubscription(this.state.AutomationSubscriptionID == "" ? "0" : this.state.AutomationSubscriptionID, this.state.AutomationCalendarID,
                                                            AppConstants.GetLocalStorage('localStorageCampaignID'), this.state.PracticeID, AppConstants.GetLocalStorage('localStorageFacebookUserID'),
                                                            this.state.FBPageData.id, this.state.FBPageData.name, this.state.FBPageData.access_token,
                                                            this.state.InstaUserData.id, this.state.InstaUserData.username, this.state.InstaPageData, this.state.BrandIDs.toString(), this.state.AssetsDataFinal)
                                                    }} />
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>

                                <div className="step-row-wrapper">
                                    <div className="step-row">
                                        {!!PracticeMonthData.length && PracticeMonthData.length != 1 && PracticeMonthData.map((obj, index) => {
                                            return (<div className={`step-col  ${activeStep == index + 1 ? 'active' : ''}`} style={{ content: "" }}> <h3>{obj.MonthName}</h3> </div>)
                                        })}
                                    </div>
                                </div>
                                <div style={{ overflow: 'auto' }}>
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start' }}>{DayNames.map((Item) => {
                                        return (<div style={{ width: '13.5%', margin: "2px", textAlign: 'center', padding: '2px 4px', border: '1px solid #D3D3D3' }}>{Item.Name}</div>)
                                    })}
                                    </div>

                                    <div className="calendar-grid-main">

                                        {!isLoadingCards && FinalArray.map((Item, Index) =>
                                            Item.DayNo == '0' ? <div style={{ textAlign: 'center', border: '1px solid #D3D3D3', width: '13.5%', margin: '2px' }} /> :
                                                Item.AssetNo > 1 ? <></> :
                                                    <div style={{ textAlign: 'center', border: '1px solid #D3D3D3', width: '13.5%', margin: '2px' }}>
                                                        <div className={Item.DayNo == undefined ? "stripes-lines" : !(CurrentMonth != this.getDateFragments(new Date(), 2) + 1 || this.getDay(Item.AssetDate) >= this.getDateFragments(new Date(), 1)) ? "stripes-lines" : ""}>
                                                            {Item.DayNo != '0' ?
                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <div className="daygrid-day-top" style={{ display: 'flex', justifyContent: 'end', width: '50%' }}>{this.getDay(Item.AssetDate)}</div>
                                                                    {Item.totalAssets > 1 && <img src={layer_color} alt="image" style={{ height: '18px', width: '18px', margin: "5px 5px 3px" }} title="Multiple posts scheduled" />}
                                                                </div> : <></>}

                                                            <div className="content-box-calendar">
                                                                {Item.DayNo != '0' && Item.PreviewURL == undefined ?
                                                                    calenderViewFromCart == "1" &&
                                                                    <div>
                                                                        {this.state.AutomationSubscriptionID != 0 &&
                                                                            (CurrentMonth != this.getDateFragments(new Date(), 2) + 1 || this.getDay(Item.AssetDate) >= this.getDateFragments(new Date(), 1) + 10) &&
                                                                            <div className="box-image-calendar calendar-image-box">
                                                                                <div className="button-hover" style={{ padding: '5% 10px', display: 'grid', alignSelf: 'center' }}>
                                                                                    <div className="button-field">
                                                                                        <button className="calendar-hover-button" onClick={() => {
                                                                                            this.setState({
                                                                                                PreviewModalData: {
                                                                                                    isModalOpen: true,
                                                                                                    isEdit: 123,
                                                                                                    headingText: AppConstants.ButtonText.AddPost,
                                                                                                    Date: this.getDay(Item.AssetDate),
                                                                                                },
                                                                                                FBCHAKE: false, IGCHAKE: false, postTimeRanges: moment(new Date(), 'ddd DD-MMM-YYYY, hh:mm A').format('hh:mm A'),
                                                                                                description: '', postImageName: '', postImageData: '', baseImageData: '', ScheduledAtDate: moment(Item.AssetDate).format('YYYY-MM-DD')
                                                                                            });
                                                                                        }}>{AppConstants.ButtonText.AddPost}</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>}
                                                                    </div> :

                                                                    Item.DayNo != '' && Item.Status != 3 &&
                                                                    Item.AssetDate.split("-")[2] == CurrentYear &&
                                                                    Item.AssetDate.split("-")[0] == CurrentMonth &&
                                                                    Item.AssetDate.split("-")[1] == this.getDay(Item.AssetDate) &&

                                                                    // moment(Item.AssetDate).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD')

                                                                    <div className={`box-image-calendar calendar-image-box`} style={{ backgroundImage: `url(${Item.PreviewURL} )`, opacity: moment(Item.AssetDate).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD') && 0.5 }}>
                                                                        <div className="button-hover" style={{ padding: '5% 10px', display: 'grid', alignSelf: 'center' }}>
                                                                            <div className="button-field">
                                                                                <button className="calendar-hover-button" onClick={() => {
                                                                                    this.setState({
                                                                                        PreviewModalData: {
                                                                                            isModalOpen: true,
                                                                                            isEdit: false,
                                                                                            headingText: AppConstants.ButtonText.Preview + ' Post',
                                                                                            Date: this.getDay(Item.AssetDate),
                                                                                        }
                                                                                    })
                                                                                }}>{AppConstants.ButtonText.Preview}</button>
                                                                            </div>
                                                                            {calenderViewFromCart == "1"
                                                                                && moment(Item.AssetDate).format('YYYY-MM-DD') >= moment(new Date()).format('YYYY-MM-DD')
                                                                                && <>
                                                                                    {this.state.AutomationSubscriptionID != 0
                                                                                        ? <>
                                                                                            {Item.IsPersonalAsset && Item.IsFromLibrary == 0
                                                                                                ? <div className="button-field" >
                                                                                                    <button className="calendar-hover-button" onClick={() => {
                                                                                                        this.setState({
                                                                                                            PreviewModalData: {
                                                                                                                isModalOpen: true,
                                                                                                                isEdit: true,
                                                                                                                headingText: AppConstants.ButtonText.AddPost,
                                                                                                                AssetDate: Item.AssetDate,
                                                                                                                Date: this.getDay(Item.AssetDate),
                                                                                                            }
                                                                                                        })
                                                                                                    }}>{AppConstants.ButtonText.EditPost}</button>
                                                                                                </div>
                                                                                                : <div className="button-field" >
                                                                                                    <button className="calendar-hover-button" onClick={() => {
                                                                                                        this.setState({
                                                                                                            PreviewModalData: {
                                                                                                                isModalOpen: true,
                                                                                                                isEdit: true,
                                                                                                                headingText: AppConstants.ButtonText.ReschedulePost,
                                                                                                                AssetDate: Item.AssetDate,
                                                                                                                Date: this.getDay(Item.AssetDate),
                                                                                                            }
                                                                                                        })
                                                                                                    }}>{AppConstants.ButtonText.Edit}</button>
                                                                                                </div>}
                                                                                            {(CurrentMonth != this.getDateFragments(new Date(), 2) + 1 || this.getDay(Item.AssetDate) >= this.getDateFragments(new Date(), 1) + 10)
                                                                                                && <div className="button-field">
                                                                                                    <button className="calendar-hover-button" onClick={() => {
                                                                                                        this.setState({
                                                                                                            PreviewModalData: {
                                                                                                                isModalOpen: true,
                                                                                                                isEdit: 123,
                                                                                                                headingText: AppConstants.ButtonText.AddPost,
                                                                                                                AssetDate: Item.AssetDate,
                                                                                                                Date: this.getDay(Item.AssetDate),
                                                                                                            },
                                                                                                            FBCHAKE: false, IGCHAKE: false, postTimeRanges: moment(new Date(), 'ddd DD-MMM-YYYY, hh:mm A').format('hh:mm A'),
                                                                                                            description: '', postImageName: '', postImageData: '', baseImageData: '', ScheduledAtDate: moment(Item.AssetDate).format('YYYY-MM-DD')
                                                                                                        });
                                                                                                    }}>{AppConstants.ButtonText.AddPost}</button>
                                                                                                </div>}
                                                                                        </>
                                                                                        : <div className="button-field">
                                                                                            <button className="calendar-hover-button" onClick={() => { this.setState({ DeleteId: Item.ID, DeleteStatusID: Item.BrandID, ConfirmDialog: true }) }} >{AppConstants.ButtonText.Delete}</button>
                                                                                        </div>
                                                                                    }
                                                                                </>}
                                                                        </div>
                                                                    </div>}

                                                            </div>
                                                        </div>
                                                    </div>
                                        )}
                                    </div>
                                </div>
                            </>}
                    </div>

                </div>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />
                {PreviewModalData && PreviewModalData.isModalOpen && (
                    <ModalComponent show={PreviewModalData.isModalOpen} >
                        <AddEditSocialPost
                            wholeState={this.state}
                            handleClose={() => this.setState({ PreviewModalData: null, isReschedule: false })}
                            handleDelete={this.handleDelete.bind(this)}
                            handleReload={this.wsGetAutomationCalendarForPractice.bind(this)} />
                    </ModalComponent>
                )}

                {ConfirmDialog &&
                    <ModalComponent show={ConfirmDialog}
                        handleClose={() => this.setState({ ConfirmDialog: false })}>
                        <ConfirmationDialog
                            title={"Delete Asset"}
                            description={"Are you sure you want to delete this Calendar Asset?"}
                            deletePress={() => this.wsDeleteAutomationCalendarAsset(DeleteId, DeleteStatusID)}
                            closeModal={() => this.setState({ ConfirmDialog: false })} />
                    </ModalComponent>
                }
            </section>
        )
    }

    handleDelete(DeleteId, DeleteStatusID, ConfirmDialog) {
        this.setState({ DeleteId: DeleteId, DeleteStatusID: DeleteStatusID, ConfirmDialog: ConfirmDialog })
    }

    wsDeleteAutomationCalendarAsset(ID, DeleteStatusID) {
        if (DeleteStatusID == "0") {
            APIController.DeleteMyCustomPost(ID, this.state.AutomationSubscriptionID).then((response) => {
                if (response.object.status == 1) {
                    this.handleNotification(response.object.message);
                } else {
                    this.handleNotification(response.object.message, true);
                }
                this.wsGetAutomationCalendarForPractice(AppConstants.GetLocalStorage('localStorageBrandsData').length && JSON.parse(AppConstants.GetLocalStorage('localStorageBrandsData')), this.state.PracticeID, this.state.AutomationSubscriptionID)
                this.setState({ ConfirmDialog: false, PreviewModalData: null, isReschedule: false })
            })
        } else {
            let finalData = this.state.FinalArray.map((item) => item.ID !== ID ? item : { ...item, Status: 3, DayNo: undefined, PreviewURL: undefined })
            let finalDeletedData = this.state.AssetsDataFinal.map((item) => item.ID !== ID ? item : { ...item, Status: 3 })
            // this.handleRefresh(finalData)
            // this.handleAssetsData(finalData)
            this.setState({ FinalArray: finalData, AssetsDataFinal: finalDeletedData, ConfirmDialog: false, PreviewModalData: null, isReschedule: false })
        }
    }


}

export default SocialMediaCalendar;
