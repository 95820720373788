/*eslint-disable eqeqeq*/
import React from "react";
import Table from "../../../../shared/components/table/table";
import TableHead from "../../../../shared/components/table/tableHead";
import TableRow from "../../../../shared/components/table/tableRow";
import TableBody from "../../../../shared/components/table/tableBody";
import TableCell from "../../../../shared/components/table/tableCell";
import double_left_arrow from '../../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../../assets/images/right-arrow.svg';
import "react-perfect-scrollbar/dist/css/styles.css";

import PerfectScrollbar from "react-perfect-scrollbar";

import viewIcon from '../../../../assets/images/view_icon.png'
import close_icon from "../../../../assets/images/gray-close-ic.svg";
import Modal from '../../../../shared/components/modal/modal';
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";

const SupplyCalculatorList = (props) => {
	// "Patient Name", "Patient Email Address", "Purchase Type",
	const headerList = ['View', "Date", "Left Lens Type", "Right Lens Type"]
	const { currentPage, count, rowsPerPage, rowsPerPageOptions, list, to, from } = props;
	let countryList = list;
	// countryList = list.slice(from, to)

	const [LoginDialog, setLoginDialog] = React.useState(false);
	const [ImageData, setImageData] = React.useState('');

	const [PatientName, setPatientName] = React.useState('');
	const [PatientEmailAddress, setPatientEmailAddress] = React.useState('');
	const [PurchaseType, setPurchaseType] = React.useState('');
	const [LeftLenseType, setLeftLenseType] = React.useState('');
	const [RightLenseType, setRightLenseType] = React.useState('');
	const [LeadAt, setLeadAt] = React.useState('');

	const page = Math.floor(count / rowsPerPage) + 1;

	const handleChangeInCurrentPage = (value) => {
		if (value <= page && Number(parseInt(value))) {
			props.handleChangePage(Number(value), PatientName, PatientEmailAddress, PurchaseType, LeftLenseType, RightLenseType, LeadAt);
		}
	}

	const _handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			props.handleApplyFilter(PatientName, PatientEmailAddress, PurchaseType, LeftLenseType, RightLenseType, LeadAt)
		}
	}

	return (
		<>
			<div className="table-main-wrap">
				<PerfectScrollbar component='div'>
					<Table>
						<TableHead>
							<TableRow> {headerList.map((obj, index) => { return <TableCell style={index == 0 ? { width: '1%', minWidth: "40px" } : { width: '10%', minWidth: '80px' }} key={index}>{obj}</TableCell>; })} </TableRow>
							<TableRow>
								<TableCell style={{ minWidth: "40px" }}> <span><img src={viewIcon} alt="delete" /></span> </TableCell>
								{/* <TableCell style={{ minWidth: "140px" }}> <input type="text" name="" placeholder="Patient Name" onKeyDown={_handleKeyDown} onChange={(e) => setPatientName(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: "190px" }}> <input type="text" name="" placeholder="Patient Email Address" onKeyDown={_handleKeyDown} onChange={(e) => setPatientEmailAddress(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: "100px" }}>
									<select onChange={(e) => { setPurchaseType(e.target.value); props.handleApplyFilter(PatientName, PatientEmailAddress, e.target.value, LeftLenseType, RightLenseType, LeadAt) }}>
										<option value={""}>All</option>
										<option value={"1"}>New</option>
										<option value={"2"}>Existing</option>
									</select>
								</TableCell> */}
								{/* lensesTypeData */}
								<TableCell style={{ minWidth: "150px" }}> <input type="text" name="" placeholder="Date" onKeyDown={_handleKeyDown} onChange={(e) => setLeadAt(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: "260px" }}>
									<select name='LeftLenseTypeID'
										defaultValue={LeftLenseType}
										value={LeftLenseType}
										onChange={(e) => { setLeftLenseType(e.target.value); props.handleApplyFilter(PatientName, PatientEmailAddress, PurchaseType, e.target.value, RightLenseType, LeadAt) }}>
										<option value={""}>All</option>
										{props.lensesTypeData.map((obj, index) => {
											return <option key={index} selected={obj.Name} value={obj.Name} >{obj.Name}</option>
										})}
									</select>
								</TableCell>
								<TableCell style={{ minWidth: "260px" }}>
									<select name='RightLenseType'
										defaultValue={RightLenseType}
										value={RightLenseType}
										onChange={(e) => { setRightLenseType(e.target.value); props.handleApplyFilter(PatientName, PatientEmailAddress, PurchaseType, LeftLenseType, e.target.value, LeadAt) }}>
										<option value={""}>All</option>
										{props.lensesTypeData.map((obj, index) => {
											return <option key={index} selected={obj.Name} value={obj.Name} >{obj.Name}</option>
										})}
									</select>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!!countryList.length ? countryList.map((obj, idnex) => {
								return (
									<TableRow key={idnex}>
										<TableCell style={{ minWidth: "40px" }}> <span>
											<img src={viewIcon} alt="viewIcon" style={{ cursor: 'pointer', marginRight: '8px', width: '20px' }}
												onClick={() => { setImageData(obj.ImageURL); setLoginDialog(true) }} />
										</span>
										</TableCell>
										{/* <TableCell style={{ minWidth: "140px" }}>{obj.PatientName}</TableCell>
										<TableCell style={{ minWidth: "190px" }}>{obj.PatientEmailAddress}</TableCell>
										<TableCell style={{ minWidth: "100px" }}>{obj.PurchaseType}</TableCell> */}
										<TableCell style={{ minWidth: "150px" }}>{obj.LeadAt}</TableCell>
										<TableCell style={{ minWidth: "260px" }}>{obj.LeftLenseType.toString()}</TableCell>
										<TableCell style={{ minWidth: "260px" }}>{obj.RightLenseType}</TableCell>
									</TableRow>
								);
							}) : <TableRow>
								<TableCell colSpan={headerList.length} className="not_found">
									{props.progress ? <CircularProgress /> : <label>No Lead Found</label>}
								</TableCell>
							</TableRow>}
						</TableBody>
					</Table>
				</PerfectScrollbar>
			</div>

			{LoginDialog && (
				<Modal
					show={LoginDialog}
					handleClose={() => setLoginDialog(false)}>
					<div className="popup-wrap">
						<img src={ImageData} alt="image" />
						<a title="" className="gray-close-ic">
							<img src={close_icon} alt="icon" onClick={() => setLoginDialog(false)} />
						</a>
					</div>
				</Modal>
			)}

			<div className="table-pagination">
				<div className="pagination-info">
					<p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
				</div>
				<div className="pagination-control">
					<a href="#!" title="" className={`double-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(1)
						}}
					><img src={double_left_arrow} alt="left" /></a>
					<a href="#!" title="" className={`simple-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage - 1)
						}}
					><img src={left_arrow} alt="single_left" /></a>
					<span>Page</span>
					<input type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => handleChangeInCurrentPage(e.target.value)} />
					<span>of {page}</span>
					<a href="#!" title="" className={`simple-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage + 1)
						}}
					><img src={right_arrow} alt="right" /></a>
					<a href="#!" title="" className={`double-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(page)
						}}><img src={double_right_arrow} alt="single_right" /></a>
					<select onChange={(e) => props.handleChangeRowsPerPage(e.target.value, PatientName, PatientEmailAddress, PurchaseType, LeftLenseType, RightLenseType, LeadAt)} defaultValue={rowsPerPage}>
						{rowsPerPageOptions.map((obj, index) => { return <option value={obj} key={index}>{obj}</option> })}
					</select>
				</div>
			</div>
		</>
	);
};

export default SupplyCalculatorList;
