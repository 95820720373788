/*eslint-disable eqeqeq*/
import React from "react";
import Table from "../../../../../shared/components/table/table";
import TableHead from "../../../../../shared/components/table/tableHead";
import TableRow from "../../../../../shared/components/table/tableRow";
import TableBody from "../../../../../shared/components/table/tableBody";
import TableCell from "../../../../../shared/components/table/tableCell";
import double_left_arrow from '../../../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../../../assets/images/right-arrow.svg';
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

import Notification from '../../../../../shared/components/notification/notification';
import CircularProgress from "../../../../../shared/components/CircularProgress/CircularProgress";

const RegistrationsReportDownloadList = (props) => {
	const headerList = ["RegistrationDate", "ShipToNo", "PracticeName", "StreetAddress", "StreetAddressLine2",
		"City", "State", "ZipCode", "PhoneNo", "VS",
		"VSP", "WebsiteURL", "AppointmentURL", "ContactName", "EmailAddress",
		"VTM_No", "VTM_Territory"]

	const { currentPage, count, rowsPerPage, rowsPerPageOptions, tempList, to, from } = props;
	let countryList = tempList;
	// countryList = list.slice(from, to)

	const page = Math.floor(count / rowsPerPage) + 1;

	/**
	 * handleChangeInCurrentPage method is used to change page number
	 * it will check that page number does not exist last page
	 * @param {*} value 
	 */
	const handleChangeInCurrentPage = (value) => {
		if (value <= page && Number(parseInt(value))) {
			props.handleChangePage(Number(value));
		}
	}

	const [message, setMessage] = React.useState("");
	const [showNotification, setShowNotification] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const handleClearNotification = () => {
		setMessage('')
		setShowNotification(false)
		setIsError(false)
	}

	return (
		<>
			<div className="table-main-wrap">
				<PerfectScrollbar component='div'>
					<Table>
						<TableHead>
							<TableRow> {headerList.map((obj, index) => { return <TableCell style={{ minWidth: '125px', textAlign: 'center', padding: '5px' }} key={index}>{obj}</TableCell>; })} </TableRow>
						</TableHead>
						<TableBody>
							{!!countryList.length ? countryList.map((obj, idnex) => {
								return (
									<TableRow key={idnex}>

										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.RegistrationDate}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.ShipToNo}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.PracticeName}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.Address1}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.Address2}</TableCell>

										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.City}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.State}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.Postcode}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.PhoneNo}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }} className={obj.VS === "Yes" ? 'green' : 'red'}> <span>{obj.VS == "Yes" ? 'Yes' : 'No'}</span> </TableCell>

										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }} className={obj.VSP === "Yes" ? 'green' : 'red'}> <span>{obj.VSP == "Yes" ? 'Yes' : 'No'}</span> </TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '200px', padding: '5px', textAlign: "center", textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflowX: 'hidden' }}>{obj.Website}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '200px', padding: '5px', textAlign: "center", textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflowX: 'hidden' }}>{obj.AppointmentURL}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.ContactName}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '200px', padding: '5px', textAlign: "center", textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflowX: 'hidden' }}>{obj.EmailAddress}</TableCell>

										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.VTM_No}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.VTM_Name}</TableCell>

									</TableRow>
								);
							}) : <TableRow>
								<TableCell colSpan={headerList.length} className="not_found" style={{ display: 'flex' }}>
									{props.progress ? <CircularProgress /> : <label>Not Currently Available</label>}
								</TableCell>
							</TableRow>}
						</TableBody>
					</Table>
				</PerfectScrollbar>
			</div>
			<Notification
				isError={isError}
				message={message}
				showNotification={showNotification}
				closeNotification={handleClearNotification.bind(this)}
			/>

			<div className="table-pagination">
				<div className="pagination-info">
					<p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
				</div>
				<div className="pagination-control">
					<a href="#!" title="" className={`double-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(1)
						}}
					><img src={double_left_arrow} alt="left" /></a>
					<a href="#!" title="" className={`simple-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage - 1)
						}}
					><img src={left_arrow} alt="single_left" /></a>
					<span>Page</span>
					<input type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => handleChangeInCurrentPage(e.target.value)} />
					<span>of {page}</span>
					<a href="#!" title="" className={`simple-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage + 1)
						}}
					><img src={right_arrow} alt="right" /></a>
					<a href="#!" title="" className={`double-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(page)
						}}><img src={double_right_arrow} alt="single_right" /></a>
					<select onChange={(e) => { props.handleChangeRowsPerPage(e.target.value); }} defaultValue={rowsPerPage}>
						{
							rowsPerPageOptions.map((obj, index) => {
								return <option value={obj} key={index}>{obj}</option>
							})
						}
					</select>
				</div>
			</div>
		</>
	);
};

export default RegistrationsReportDownloadList;
