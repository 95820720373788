const Location = window.location.toString();

export function GetBaseURL() {
    var HostName = window.location.hostname
    var ReturnURL = '';
    if (HostName.indexOf("www.bauschreach.com") >= 0) {  //Live
        ReturnURL = 'https://api.bauschreach.com'
    }
    else if (HostName.indexOf("live.mybauschreach.com") >= 0) {  //AWS - Staging
        ReturnURL = 'https://liveapi.mybauschreach.com'
    }
    else if (HostName.indexOf("qa.mybauschreach.com") >= 0) {  //QA - Staging
        ReturnURL = 'https://qaapi.mybauschreach.com'
    }
    else if (HostName.indexOf("staging.mybauschreach.com") >= 0) {  //Hostgator - Staging
        ReturnURL = 'https://stagingapi.mybauschreach.com'
    }
    else if (HostName.indexOf("test.mybauschreach.com") >= 0) {  //Hostgator - Test
        ReturnURL = 'https://testapi.mybauschreach.com'
    }
    else if (HostName.indexOf("yomi.portal.thestaging.cc") >= 0) {  //Hostgator - Yomi
        ReturnURL = 'https://yomi.api.thestaging.cc'
    }
    else {  //Local
        ReturnURL = 'https://cobrand.api.thestaging.cc'
    }

    return ReturnURL
}

export const URLs = {
    // base: Location.indexOf('live') != -1 ? 'https://liveapi.mybauschreach.com' : Location.indexOf('staging') != -1
    //     ? 'https://stagingapi.mybauschreach.com' :  //Staging Server
    //     Location.indexOf('test') != -1
    //         ? 'https://cobrandsso.com' //Testing Server
    //         // : 'https://cobrandsso.com', //Vmobee Local Server
    //         : 'https://cobrand.api.thestaging.cc', //Vmobee Local Server
    // // ? 'https://testapi.mybauschreach.com' //Testing Server
    // // : 'http://192.168.1.50/cobrandapi', //Vmobee Local Server
    base: GetBaseURL(),

    googlekey: 'AIzaSyAii_9SU6ec6sCpwyaHIurMBvktQ8fv6JI',//google API key

    // Auth
    Gettoken: '/token',
    VerifyToken: '/api/Users/VerifyToken',
    SetPassword: '/api/Users/SetPassword',

    // Master
    AddEditcountry: '/API/Master/AddEditCountry',
    GetcountryForDropdown: '/API/Master/GetCountryForDropdown',
    Getcountry: '/API/Master/GetCountries',
    Getstate: '/API/Master/GetStates',
    GetCityByID: '/API/Master/GetCityByID',
    GetPostcodeByID: '/API/Master/GetPostcodeByID',
    GetStateByID: '/API/Master/GetStateByID',
    GetstateForDropdown: '/API/Master/GetStateForDropdown',
    Getcity: '/API/Master/GetCities',
    GetcityForDropdown: '/API/Master/GetCityForDropdown',
    Getpostcodes: '/API/Master/GetPostcodes',
    Getbrand: '/API/Master/GetBrands',
    GetBrandByID: '/API/Master/GetBrandByID',
    GetBrandForDropdown: '/API/Master/GetBrandForDropdown',
    GetProducts: '/API/Master/GetProducts',
    GetProductByID: '/API/Master/GetProductByID',
    GetProductImages: '/API/Master/GetProductImages',
    AddEditProductImage: '/API/Master/AddEditProductImage',
    DeleteProductImage: '/API/Master/DeleteProductImage',
    GetProductForDropdown: '/API/Master/GetProductForDropdown',
    Getcountrybyid: '/API/Master/GetCountryByID',
    DeleteCountry: '/API/Master/DeleteCountry',
    DeleteState: '/API/Master/DeleteState',
    DeleteCity: '/API/Master/DeleteCity',
    DeletePostcode: '/API/Master/DeletePostcode',
    DeleteBrand: '/API/Master/DeleteBrand',
    DeleteProduct: '/API/Master/DeleteProduct',
    GetProductChildren: '/API/Master/GetProductChildren',
    AddEditProductChildren: '/API/Master/AddEditProductChildren',
    DeleteProductChildren: '/API/Master/DeleteProductChildren',
    AddEditState: '/API/Master/AddEditState',
    AddEditCity: '/API/Master/AddEditCity',
    AddEditBrand: '/API/Master/AddEditBrand',
    AddEditProduct: '/API/Master/AddEditProduct',
    AddEditPostcode: '/API/Master/AddEditPostcode',
    GetCompanyBranches: '/API/Master/GetCompanyBranches',
    AddEditCompanyBranch: '/API/Master/AddEditCompanyBranch',
    DeleteCompanyBranch: '/API/Master/DeleteCompanyBranch',
    GetCompanyBranchForDropdown: '/API/Master/GetCompanyBranchForDropdown',
    GetPositions: '/API/Master/GetPositions',
    AddEditPosition: '/API/Master/AddEditPosition',
    DeletePosition: '/API/Master/DeletePosition',
    GetPositionForDropdown: '/API/Master/GetPositionForDropdown',
    GetDepartments: '/API/Master/GetDepartments',
    AddEditDepartment: '/API/Master/AddEditDepartment',
    DeleteDepartment: '/API/Master/DeleteDepartment',
    GetDepartmentForDropdown: '/API/Master/GetDepartmentForDropdown',
    AddEditSegment: '/API/Master/AddEditSegment',
    GetSegments: '/API/Master/GetSegments',
    DeleteSegment: '/API/Master/DeleteSegment',
    GetSegmentByID: '/API/Master/GetSegmentByID',
    ConfigureProduct: '/API/Master/ConfigureProduct',
    GetSegmentForDropdown: '/API/Master/GetSegmentForDropdown',
    ImportPostcodes: '/API/Master/ImportPostcodes',

    GetAreas: '/API/Master/GetAreas',
    AddEditArea: '/API/Master/AddEditArea',
    DeleteArea: '/API/Master/DeleteArea',
    GetRegions: '/API/Master/GetRegions',
    AddEditRegion: '/API/Master/AddEditRegion',
    DeleteRegion: '/API/Master/DeleteRegion',
    GetRegionByID: '/API/Master/GetRegionByID',
    GetTerritories: '/API/Master/GetTerritories',
    AddEditTerritory: '/API/Master/AddEditTerritory',
    DeleteTerritory: '/API/Master/DeleteTerritory',
    GetTerritoryByID: '/API/Master/GetTerritoryByID',
    GetAreaForDropdown: '/API/Master/GetAreaForDropdown',
    GetRegionForDropdown: '/API/Master/GetRegionForDropdown',
    GetTerritoryForDropdown: '/API/Master/GetTerritoryForDropdown',

    AddEditPaypalSubscriptionPlan: '/API/Master/AddEditPaypalSubscriptionPlan',
    GetPaypalSubscriptionPlans: '/API/Master/GetPaypalSubscriptionPlans',
    DeletePaypalSubscriptionPlan: '/API/Master/DeletePaypalSubscriptionPlan',
    GetPaypalSubscriptionPlanForDropdown: '/API/Master/GetPaypalSubscriptionPlanForDropdown',

    GetMonthlyBudget: '/API/Master/GetMonthlyBudget',
    GetMonthlyBudgetByProductID: '/API/Master/GetMonthlyBudgetByProductID',
    AddEditMonthlyBudget: '/API/Master/AddEditMonthlyBudget',
    DeleteMonthlyBudget: '/API/Master/DeleteMonthlyBudget',


    GetScreenBanners: '/API/Master/GetScreenBanners',
    AddEditScreenBanner: '/API/Master/AddEditScreenBanner',
    GetScreenBannerByID: '/API/Master/GetScreenBannerByID',
    DeleteScreenBanner: '/API/Master/DeleteScreenBanner',
    GetScreenBannerForDropdown: '/API/Master/GetScreenBannerForDropdown',

    AddEditWelcomePopup: '/API/Master/AddEditWelcomePopup',
    GetWelcomePopup: '/API/Master/GetWelcomePopup',
    GetWelcomePopupByID: '/API/Master/GetWelcomePopupByID',
    DeleteWelcomePopup: '/API/Master/DeleteWelcomePopup',

    // Users
    GetNav: '/API/Users/GetNav',
    GetUsers: '/API/Users/GetUsers',
    DeleteUser: '/API/Users/DeleteUser',
    AddEditUser: '/API/Users/AddEditUser',
    GetUserByID: '/API/Users/GetUserByID',
    ForgotPassword: '/API/Users/ForgotPassword',
    ChangePassword: '/API/Users/ChangePassword',
    VerifyOTP: '/API/Users/VerifyOTP',
    ResendOTP: '/API/Users/ResendOTP',
    GetMyProfile: '/API/Users/GetMyProfile',
    EditProfile: '/API/Users/EditProfile',
    GetNotifications: '/API/Users/GetNotifications',
    VerifySSO: '/API/Users/VerifySSO',
    GetLoginLogs: '/API/Users/GetLoginLogs',

    // ACS
    GetModuleForDropdown: '/API/ACS/GetModuleForDropdown',
    GetRights: '/API/ACS/GetRights',
    GetModules: '/API/ACS/GetModules',
    GetScreens: '/API/ACS/GetScreens',
    GetScreenForDropdown: '/API/ACS/GetScreenForDropdown',
    GetRoles: '/API/ACS/GetRoles',
    AddEditRight: '/API/ACS/AddEditRight',
    AddEditModule: '/API/ACS/AddEditModule',
    AddEditScreen: '/API/ACS/AddEditScreen',
    AddEditRole: '/API/ACS/AddEditRole',
    DeleteRight: '/API/ACS/DeleteRight',
    DeleteModule: '/API/ACS/DeleteModule',
    DeleteScreen: '/API/ACS/DeleteScreen',
    DeleteRole: '/API/ACS/DeleteRole',
    GetRoleForDropdown: '/API/ACS/GetRoleForDropdown',

    // ClinicRegistration
    VerifyClinic: '/API/Practice/VerifyPractice',
    RegisterPractice: '/API/Practice/RegisterPractice',
    SearchRepresentatives: '/API/Practice/SearchRepresentatives',
    SearchPostcodes: '/API/Practice/SearchPostcodes',
    ApprovePractice: '/API/Practice/ApprovePractice',
    RejectPractice: '/API/Practice/RejectPractice',
    GetAllLocations: '/API/Practice/GetAllLocations',
    GetLocationDetails: '/API/Practice/GetLocationDetails',

    // Practice
    GetPractices: '/API/Practice/GetPractices',
    GetPracticeCoOp: '/API/Practice/GetPracticeCoOp',
    UpdateLocation: '/API/Practice/UpdateLocation',
    EnableDisableCoOp: '/API/Practice/EnableDisableCoOp',
    EditPractice: '/API/Practice/EditPractice',
    UploadPracticeLogo: '/API/Practice/UploadPracticeLogo',
    UploadDoctorLogo: '/API/Practice/UploadDoctorLogo',
    DeleteDoctorLogo: '/API/Practice/DeleteDoctorLogo',
    AddRevokeCoOp: '/API/Practice/AddRevokeCoOp',
    GetMappingData: '/API/Practice/GetMappingData',
    AddEditPracticeAddress: '/API/Practice/AddEditPracticeAddress',

    SetPracticePassword: '/API/Practice/SetPracticePassword',
    RegistrationReport: '/API/Practice/RegistrationReport',

    //change url Master to Practice
    ImportPractices: '/API/Practice/ImportPractices',
    GetImportedPractices: '/API/Practice/GetImportedPractices',
    DeleteImportedPractice: '/API/Practice/DeleteImportedPractice',
    GetImportedPracticeByID: '/API/Practice/GetImportedPracticeByID',
    ImportPracticeAndUsers: '/API/Practice/ImportPracticeAndUsers',
    ImportVTMContactList: '/API/Practice/ImportVTMContactList',
    GetVTMContactList: '/API/Practice/GetVTMContactList',

    // Campaigns
    GetAssetTypes: '/API/Campaigns/GetAssetTypes',
    AddEditAssetType: '/API/Campaigns/AddEditAssetType',
    DeleteAssetType: '/API/Campaigns/DeleteAssetType',
    GetAssetTypeByID: '/API/Campaigns/GetAssetTypeByID',
    GetAssetSizeByID: '/API/Campaigns/GetAssetSizeByID',
    GetAssetTypeForDropdown: '/API/Campaigns/GetAssetTypeForDropdown',
    GetAssetSizes: '/API/Campaigns/GetAssetSizes',
    AddEditAssetSize: '/API/Campaigns/AddEditAssetSize',
    DeleteAssetSize: '/API/Campaigns/DeleteAssetSize',
    AddEditCampaignFreeAsset: '/API/Campaigns/AddEditCampaignFreeAsset',
    GetCampaignFreeAssetByID: '/API/Campaigns/GetCampaignFreeAssetByID',
    AddEditCampaignDirectMailAsset: '/API/Campaigns/AddEditCampaignDirectMailAsset',
    GetCampaignDirectMailAssetByID: '/API/Campaigns/GetCampaignDirectMailAssetByID',
    GetCampaignFreeAssets: '/API/Campaigns/GetCampaignFreeAssets',
    GetCampaignDirectMailAssets: '/API/Campaigns/GetCampaignDirectMailAssets',
    DeleteCampaignFreeAsset: '/API/Campaigns/DeleteCampaignFreeAsset',
    DeleteCampaignDirectMailAsset: '/API/Campaigns/DeleteCampaignDirectMailAsset',
    GetCampaigns: '/API/Campaigns/GetCampaigns',
    GetCampaignByID: '/API/Campaigns/GetCampaignByID',
    GetCampaignForDropdown: '/API/Campaigns/GetCampaignForDropdown',
    AddEditCampaign: '/API/Campaigns/AddEditCampaign',
    DeleteCampaign: '/API/Campaigns/DeleteCampaign',
    GetPostageCharges: '/API/Campaigns/GetPostageCharges',
    CalculatePostageCharges: '/API/Campaigns/CalculatePostageCharges',
    AssetDownloadReport: '/API/Campaigns/AssetDownloadReport',
    AssetSummaryReport: '/API/Campaigns/AssetSummaryReport',
    FreeAssetsReport: '/API/Campaigns/FreeAssetsReport',

    //  CampaignThemes
    GetCampaignThemes: '/API/Campaigns/GetCampaignThemes',
    GetCampaignThemesForDropdown: '/API/Campaigns/GetCampaignThemesForDropdown',
    AddEditCampaignTheme: '/API/Campaigns/AddEditCampaignTheme',
    DeleteCampaignTheme: '/API/Campaigns/DeleteCampaignTheme',

    //  CampaignMessages
    GetCampaignMessages: '/API/Campaigns/GetCampaignMessages',
    AddEditCampaignMessage: '/API/Campaigns/AddEditCampaignMessage',
    DeleteCampaignMessage: '/API/Campaigns/DeleteCampaignMessage',

    // Tile
    GetProductsForHome: '/API/Tile/GetProductsForHome',
    GetBrandsForHome: '/API/Tile/GetBrandsForHome',

    //  Asset
    GetCampaignAssets: '/API/Campaigns/GetCampaignAssets',
    AddEditCampaignAsset: '/API/Campaigns/AddEditCampaignAsset',
    DeleteCampaignAsset: '/API/Campaigns/DeleteCampaignAsset',
    GetCampaignAssetByID: '/API/Campaigns/GetCampaignAssetByID',
    GetCampaignProducts: '/API/Campaigns/GetCampaignProducts',
    DownloadFile: '/API/Campaigns/DownloadFile',
    DownloadPostcard: '/API/Campaigns/DownloadCustomizableAsset',
    DownloadCampaignAsset: '/API/Campaigns/DownloadCampaignAsset',
    ReSequenceCampaignFreeAssets: '/API/Campaigns/ReSequenceCampaignFreeAssets',
    ReportEvent: '/API/Campaigns/ReportEvent',

    GetCampaignFreeAssetsForSocialAutomation: '/API/Campaigns/GetCampaignFreeAssetsForSocialAutomation',

    //  Order
    GetCart: '/API/Order/GetCart',
    GetOrderList: '/API/Order/GetOrderList',
    DeleteCart: '/API/Order/DeleteCart',
    GetOrderDetails: '/API/Order/GetOrderDetails',
    AddEditCart: '/API/Order/AddEditCart',
    PlaceOrder: '/API/Order/PlaceOrder',
    EditCart: '/API/Order/EditCart',
    GetOrderReportIDs: '/API/Order/GetOrderReportIDs',//22-2-2021
    AddEditOrderReportIDs: '/API/Order/AddEditOrderReportIDs',//22-2-2021
    DeleteOrderReportIDs: '/API/Order/DeleteOrderReportIDs',//22-2-2021
    GetOrderListForDashboard: '/API/Order/GetOrderListForDashboard',//23-2-2021
    OrderPayment: '/API/Order/OrderPayment',//21-4-2021

    FrontEnd: '/API/PayPal/FrontEnd',//21-4-2021
    InitiateTransaction: '/API/PayPal/InitiateTransaction',//21-4-2021

    //  Reports
    GetReports: '/API/Reports/GetDashboardData',
    GetDashboard: '/API/Reports/GetDashboard',
    GetReportData: '/API/Reports/GetReportData',
    ImportReportData: '/API/Reports/ImportReportData',
    DeleteReportData: '/API/Reports/DeleteReportData',

    //  AutomationCalendar
    AddEditAutomationCalendar: '/API/AutomationCalendar/AddEditAutomationCalendar',
    GetAutomationCalendar: '/API/AutomationCalendar/GetAutomationCalendar',
    DeleteAutomationCalendar: '/API/AutomationCalendar/DeleteAutomationCalendar',
    GetAutomationCalendarByID: '/API/AutomationCalendar/GetAutomationCalendarByID',

    AddEditAutomationCalendarAsset: '/API/AutomationCalendar/AddEditAutomationCalendarAsset',
    GetAutomationCalendarAssets: '/API/AutomationCalendar/GetAutomationCalendarAssets',
    DeleteAutomationCalendarAsset: '/API/AutomationCalendar/DeleteAutomationCalendarAsset',
    GetAutomationCalendarAssetByID: '/API/AutomationCalendar/GetAutomationCalendarAssetByID',
    DeleteMyCustomPost: '/API/AutomationCalendar/DeleteMyCustomPost',

    AddEditAutomationSubscription: '/API/AutomationCalendar/AddEditAutomationSubscription',
    GetAutomationSubscriptions: '/API/AutomationCalendar/GetAutomationSubscriptions',
    GetAutomationSubscriptionByID: '/API/AutomationCalendar/GetAutomationSubscriptionByID',
    DeleteAutomationSubscription: '/API/AutomationCalendar/DeleteAutomationSubscription',
    GetScheduledPosts: '/API/AutomationCalendar/GetScheduledPosts',
    PostScheduledPost: '/API/AutomationCalendar/PostScheduledPost',

    GetAutomationCalendarForPractice: '/API/AutomationCalendar/GetAutomationCalendarForPractice',
    GetAutomationSubscriptionOrders: '/API/AutomationCalendar/GetAutomationSubscriptionOrders',
    GetAutomationSubscriptionOrderCalendar: '/API/AutomationCalendar/GetAutomationSubscriptionOrderCalendar',

    RescheduleMyCommonPost: '/API/AutomationCalendar/RescheduleMyCommonPost',
    AddEditMyCustomPost: '/API/AutomationCalendar/AddEditMyCustomPost',
    GetCustomPostAdminFeed: '/API/AutomationCalendar/GetCustomPostAdminFeed',
    FlagCustomPost: '/API/AutomationCalendar/FlagCustomPost',

    GetNegativeKeywordByID: '/API/AutomationCalendar/GetNegativeKeywordByID',
    GetNegativeKeywords: '/API/AutomationCalendar/GetNegativeKeywords',
    DeleteNegativeKeyword: '/API/AutomationCalendar/DeleteNegativeKeyword',
    AddEditNegativeKeyword: '/API/AutomationCalendar/AddEditNegativeKeyword',
    EmailFlaggedSocialPost: '/API/AutomationCalendar/EmailFlaggedSocialPost',

    //   Subscription
    ActivateSubscriptionViaBypassingPayPalFlow: '/API/Subscription/ActivateSubscriptionViaBypassingPayPalFlow',

    //   LandingPageAutomation
    GetLandingPageAutomationTemplates: '/API/LandingPageAutomation/GetLandingPageAutomationTemplates',
    AddEditLandingPageAutomationTemplate: '/API/LandingPageAutomation/AddEditLandingPageAutomationTemplate',
    DeleteLandingPageAutomationTemplate: '/API/LandingPageAutomation/DeleteLandingPageAutomationTemplate',
    GetLandingPageAutomationTemplateByID: '/API/LandingPageAutomation/GetLandingPageAutomationTemplateByID',
    GetLandingPageUsers: '/API/LandingPageAutomation/GetLandingPageUsers',
    GenerateLandingPagesForTemplate: '/API/LandingPageAutomation/GenerateLandingPagesForTemplate',
    GenerateLandingPageForPractice: '/API/LandingPageAutomation/GenerateLandingPageForPractice',
    GetLandingPageTemplateLogs: '/API/LandingPageAutomation/GetLandingPageTemplateLogs',
    GetMyLandingPageURLs: '/API/LandingPageAutomation/GetMyLandingPageURLs',
    GetLeads: '/API/LandingPageAutomation/GetLeads',
    GetLandingPageDashboard: '/API/LandingPageAutomation/GetDashboard',

    //    SupplyTools

    GetLensesTypes: '/API/SupplyTools/GetLensesTypes',
    AddEditLensesType: '/API/SupplyTools/AddEditLensesType',
    GetLensesTypeByID: '/API/SupplyTools/GetLensesTypeByID',
    DeleteLensesType: '/API/SupplyTools/DeleteLensesType',
    GetLensesTypeForDropdown: '/API/SupplyTools/GetLensesTypeForDropdown',

    AddEditBanner: '/API/SupplyTools/AddEditBanner',
    GetBanners: '/API/SupplyTools/GetBanners',
    DeleteBanner: '/API/SupplyTools/DeleteBanner',
    GetBannerByID: '/API/SupplyTools/GetBannerByID',

    GetConfigurations: '/API/SupplyTools/GetConfigurations',
    DeleteConfiguration: '/API/SupplyTools/DeleteConfiguration',
    AddEditConfiguration: '/API/SupplyTools/AddEditConfiguration',
    GetConfigurationByID: '/API/SupplyTools/GetConfigurationByID',

    GetSupplyLeads: '/API/SupplyTools/GetLeads',
    GetScreenConfigurations: '/API/SupplyTools/GetScreenConfigurations',
    AddEditLead: '/API/SupplyTools/AddEditLead',
    GetSupplyCostCalculatorLead: '/API/SupplyTools/GetSupplyCostCalculatorLead',

    AddEditSupplyCostCalculatorLead: '/API/SupplyTools/AddEditSupplyCostCalculatorLead',
    GetSupplyToolReportSummary: '/API/SupplyTools/GetSupplyToolReportSummary',

    // SupplyToolPracticePricing
    GetSupplyToolPracticePricingForDropdown: '/API/SupplyTools/GetSupplyToolPracticePricingForDropdown',
    AddEditSupplyToolPracticePricing: '/API/SupplyTools/AddEditSupplyToolPracticePricing',
    GetSupplyToolReportByPricePerBrand: '/API/SupplyTools/GetSupplyToolReportByPricePerBrand',

    //  Google Map Api
    maplatlng: "https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/geocode/json?",//google API map lat-lng
    mapaddress: "https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/textsearch/json?",//google API map address from lat-lng
    mapaddresstoken: "https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/nearbysearch/json?", //google API map address by token

}