import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import { Formik, Field } from "formik";

import * as yup from "yup";
import checkmark from "../../../assets/images/icon/checkmark.png";
import fail from "../../../assets/images/icon/fail.png";
import google_verified from "../../../assets/images/google_verified.png";

import uuid from 'react-uuid';
import "./location.css";
import MyMapComponent from "../../../shared/components/maps/google-maps";
import * as APIController from '../../../api/APIController.js';
import { URLs } from "../../../api/URLs";
import { AspectRatio } from 'react-aspect-ratio';

import TextField from "../../../shared/components/TextField/TextField";
import * as AppConstants from "../../../config/AppConstant";
import { usePlacesWidget } from "react-google-autocomplete";

// city: yup.string().required('City is required'),
// state: yup.string().required('State is required'),
// email: yup.string().required('Business email address is required'),
const validateProductForm = yup.object().shape({
	businessInfo: yup.object().shape({
		name: yup.string().required('Business Name is required'),
		address1: yup.string().required('Business Address 1 is required'),
		postCode: yup.string().required('Post Code is required'),
		businessPhone: yup.string().required('Business Phone Number is required'),
		website: yup.string().required('Business Website URL is required')
	})

});

const MyLocationValidationComponent = (props) => {

	let initialValues = props.locationDetail;

	const [finallatlng, setfinallatlng] = useState([]);
	const [selectedAddress, setselectedAddress] = useState([]);

	const [searchPostcodesList, setsearchPostcodesList] = useState([]);
	const [isPostcodeSelected, setisPostcodeSelected] = useState(true);

	const [currentCitylat, setcurrentCitylat] = useState('');
	const [currentCitylng, setcurrentCitylng] = useState('');

	const [ValueLoading, setvalueLoading] = useState(false);
	const [BrandLoading, setBrandLoading] = useState(true);
	const [BrandsList, setBrandsList] = React.useState([]);

	const [uploadImageHeight, setuploadImageHeight] = React.useState(750);
	const [uploadImageWidth, setuploadImageWidth] = React.useState(1050);

	React.useEffect(() => {
		wsGetBrand()
		if (props.locationDetail.businessInfo != "" && props.locationDetail.businessInfo != "{}") {
			setselectedAddress(props.locationDetail.businessInfo.googleaddress)
			setcurrentCitylat(props.locationDetail.businessInfo.latitude)
			setcurrentCitylng(props.locationDetail.businessInfo.longitude)
			setfinallatlng([{ lat: parseFloat(props.locationDetail.businessInfo.latitude), lng: parseFloat(props.locationDetail.businessInfo.longitude), selected: true }])
		}
	}, []);

	const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

	const wsUploadPracticeLogo = (practiceId, name, value, isPracticeLogo) => {
		APIController.UploadPracticeLogo(practiceId, name, value, isPracticeLogo)
			.then((response) => { });
	}

	const handleValidateScroll = (val) => {
		let id;
		if (val.businessInfo.name == '') {
			id = 'businessInfo.name'
		}
		else if (val.businessInfo.address1 == '') {
			id = 'businessInfo.address1'
		}
		else if (val.businessInfo.city == '') {
			id = 'businessInfo.city'
		}
		else if (val.businessInfo.state == '') {
			id = 'businessInfo.state'
		}
		else if (val.businessInfo.postCode == '' || val.businessInfo.postCode.length < 5) {
			id = 'businessInfo.postCode'
		}
		else if (val.businessInfo.businessPhone == '' || val.businessInfo.businessPhone == undefined) {
			id = 'businessInfo.businessPhone'
		}
		// else if (val.businessInfo.businessPhone.length < 12) {
		// 	id = 'businessInfo.businessPhone'
		// }
		// else if (val.businessInfo.email == '') {
		// 	id = 'businessInfo.email'
		// }
		else if (val.businessInfo.website == '') {
			id = 'businessInfo.website'
		}

		var element = document.getElementById(id);
		id != undefined && props.handleTitle(id)
		id != undefined && element.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
	}

	const formatPhoneNumber = (phoneNumberString) => {
		var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
		var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			var intlCode = (match[1] ? '' : '');
			return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
		}
		return null;
	}

	const wsSearchPostcodes = (postcode) => {
		APIController.SearchPostcodes(postcode)
			.then((response) => {
				// this.setState({ SearchPostcodes: response.object.data })
				setsearchPostcodesList(response.object.data)
			})
	}

	const handleUploadLogoSize = (image) => {
		let img = new Image()
		img.src = image
		img.onload = () => {
			setuploadImageHeight(img.height)
			setuploadImageWidth(img.width)
		}
	}

	const handleCheckList = (ID) => {
		setBrandsList(BrandsList.map((item, index) => item.ID !== ID ? item : { ...item, Checked: !item.Checked }))
	};

	const wsGetBrand = () => {
		APIController.GetBrand("1", "AutomationOrderNo", "ASC", 0)
			.then((response) => {
				if (response.error == null) {
					let Temp = response.object.data.map((item, index) => { return ({ Checked: false, ID: item.ID, Name: item.Name }) })
					let selectedIDs = props.locationDetail.businessInfo.brands != ""
						&& props.locationDetail.businessInfo.brands != undefined
						&& props.locationDetail.businessInfo.brands != null
						? JSON.parse(props.locationDetail.businessInfo.brands)
						: []

					if (selectedIDs.length) {
						selectedIDs.map((inner) => {
							Temp = (Temp.map((item, index) =>
								item.ID == inner.ID ? { ...item, Checked: true, URL: inner.URL } : { ...item }
							))
						})
					}
					setBrandsList(Temp)
				}
				setBrandLoading(false)
			})
	}

	const { ref } = usePlacesWidget({
		apiKey: URLs.googlekey,
		options: {
			componentRestrictions: { country: "us" },
			types: ["address"],
			fields: ["address_component", "formatted_address", "plus_code", "geometry", "name"],
			strictBounds: true,
		},
		onPlaceSelected: (place) => {
			setvalueLoading(true)

			let initialValuesTemp = initialValues;

			initialValuesTemp.businessInfo.address1 = ""
			initialValuesTemp.businessInfo.address2 = ""
			initialValuesTemp.businessInfo.postCode = ""
			initialValuesTemp.businessInfo.city = ""
			initialValuesTemp.businessInfo.state = ""

			initialValuesTemp.businessInfo.latitude = place.geometry.location.lat()
			initialValuesTemp.businessInfo.longitude = place.geometry.location.lng()
			initialValuesTemp.businessInfo.googleaddress = place.formatted_address

			setcurrentCitylat(place.geometry.location.lat())
			setcurrentCitylng(place.geometry.location.lng())
			setfinallatlng([{ lat: place.geometry.location.lat(), lng: place.geometry.location.lng(), selected: true }])
			for (const component of place.address_components) {
				const componentType = component.types[0];

				switch (componentType) {
					case "street_number": {
						initialValuesTemp.businessInfo.address1 = `${component.long_name}`
						break;
					} case "route": {
						initialValuesTemp.businessInfo.address1 = initialValuesTemp.businessInfo.address1 + " " + `${component.long_name}`
						break;
					} case "subpremise": {
						initialValuesTemp.businessInfo.address2 = `${component.long_name}`
						break;
					} case "postal_code": {
						initialValuesTemp.businessInfo.postCode = `${component.long_name}`
						break;
					} case "locality": {
						initialValuesTemp.businessInfo.city = `${component.long_name}`
						break;
					} case "administrative_area_level_1": {
						initialValuesTemp.businessInfo.state = `${component.short_name}`
						break;
					}
				}
			}
			initialValues = initialValuesTemp
			setvalueLoading(false)
		},
	});

	return (
		ValueLoading ? <CircularProgress /> :
			<div className='business-info-form'>
				<Formik
					initialValues={initialValues}
					validationSchema={validateProductForm}
					validateOnMount={false}
					onSubmit={(values) => {
						if (values.businessInfo.postCode.length < 5) {
							handleValidateScroll(values)
						} else if (formatPhoneNumber(values.businessInfo.businessPhone).length < 14) {
							handleValidateScroll(values)
						} else {
							let TempArray1 = { selectedAddress, Latitude: parseFloat(currentCitylat), Longitude: parseFloat(currentCitylng) }
							setselectedAddress(TempArray1)
							let tempIds = []
							values.campaignReach.selectedLocations = TempArray1
							BrandsList.filter((item) => { if (item.Checked == true) { tempIds.push(item.ID) } })
							values.businessInfo.brands = tempIds.toString()
							if (values.businessInfo.appointmenturl.includes("http://") || values.businessInfo.appointmenturl.includes("https://")) {
								props.updateLocationDetails(values)
							} else {
								values.businessInfo.appointmenturl = 'http://' + values.businessInfo.appointmenturl
								props.updateLocationDetails(values)
							}
						}
					}}>
					{({ handleSubmit, values, setFieldValue, errors }) => {
						errors.businessInfo == undefined ? props.handleTitle('Valid') : props.handleTitle('inValid')
						return (
							<form onSubmit={handleSubmit}>
								<div className='business-info-wrap'>
									<div className='white-box' style={{ width: '100%' }}>
										<div className='box-head'>
											<h4>Practice info</h4>
										</div>
										<div className='box-body' style={{ display: 'flex' }}>
											<div className='info-col-left'>
												<div id={'businessInfo.shiptono'} className={`field-full ${values.businessInfo.shiptono == '' ? 'error' : ''}`}>
													<Field disabled as={TextField}
														error={values.businessInfo.shiptono == '' ? true : false}
														label='Ship To #: (8 digits - add leading zeroes)' type='text' placeholder='Practice Ship To' name='businessInfo.shiptono' value={values.businessInfo.shiptono} />
												</div>
												<div id={'businessInfo.name'} className={`field-full ${values.businessInfo.name == '' ? 'error' : ''}`}>
													<Field as={TextField}
														error={values.businessInfo.name == '' ? true : false} maxLength="100"
														label='Practice Name' type='text' placeholder='Practice Name' name='businessInfo.name' value={values.businessInfo.name} />
												</div>

												<div id={'businessInfo.address1'} className={`field-full ${values.businessInfo.address1 == '' ? 'error' : ''}`}>
													{/* style={{ borderRadius: '8px', border: '1px solid #d8dbe0', padding: '14px 14px', marginBottom: '15px', width: '100%' }} */}
													<Field as={TextField} inputRef={ref}
														error={values.businessInfo.address1 == '' || values.businessInfo.latitude == null || values.businessInfo.longitude == null ? true : false} maxLength="100"
														label='Address Line 1' type='text' placeholder='Address Line 1' id='businessInfo.address1'
														name='businessInfo.address1' value={values.businessInfo.address1} />
												</div>
												<div id={'businessInfo.address2'} className={`field-full`}>
													<Field as={TextField} maxLength="100"
														label='Address Line 2' type='text' placeholder='Address Line 2' name='businessInfo.address2' value={values.businessInfo.address2} />
												</div>
												<div id={'businessInfo.city'} className={`field-full`}>
													<Field disabled as={TextField} error={values.businessInfo.city == '' ? true : false}
														label='City' type='text' placeholder='City' name='businessInfo.city' value={values.businessInfo.city} />
												</div>
												<div id={'businessInfo.state'} className={`field-full`}>
													<Field disabled as={TextField} error={values.businessInfo.state == '' ? true : false}
														label='State' type='text' placeholder='State' name='businessInfo.state' value={values.businessInfo.state} />
												</div>
												<div style={{ position: 'relative' }} className={`field-full ${values.businessInfo.postCode == null || values.businessInfo.postCode == '' || values.businessInfo.postCode.length < 5 ? 'error' : ''}`}>
													<div id={`businessInfo.postCode`} style={{ position: 'relative' }}>
														<Field disabled as={TextField} error={values.businessInfo.postCode == null || values.businessInfo.postCode == '' || values.businessInfo.postCode.length < 5 ? true : false}
															label='Postcode' type='text' placeholder='Postcode' name='businessInfo.postCode' value={values.businessInfo.postCode} maxLength="5"
														// onChange={(e) => {
														// 	setisPostcodeSelected(false)
														// 	if (e.target.value != '') {
														// 		setopenZIPCodeSuggestion(true);
														// 		wsSearchPostcodes(e.target.value);
														// 		setFieldValue('businessInfo.postCode', e.target.value)
														// 	} else {
														// 		setopenZIPCodeSuggestion(false);
														// 		setsearchPostcodesList([])
														// 		setFieldValue('businessInfo.city', "");
														// 		setFieldValue('businessInfo.state', "");
														// 		setFieldValue('businessInfo.postCode', '');
														// 	}
														// }} 
														/>

														{/* {openZIPCodeSuggestion ? (
																<div className='Suggetion-wrap'>
																	{searchPostcodesList.length == 0 ? <div style={{ textAlign: 'center' }}> {'No Suggetion Found'} </div>
																		: searchPostcodesList.map((item, index) => {
																			return (
																				<div style={{ cursor: 'pointer', padding: '5px' }}
																					onClick={() => {
																						setFieldValue('businessInfo.city', item.City);
																						setFieldValue('businessInfo.state', item.State);
																						setFieldValue('businessInfo.postCode', item.Postcode);
																						setopenZIPCodeSuggestion(false);
																						setisPostcodeSelected(true)
																					}} >
																					{item.Postcode}
																				</div>
																			)
																		})
																	}
																</div>
															) : null} */}
													</div>
												</div>
											</div>
											<div className='info-col-right'>
												{props.islocationverified &&
													<div className='box-head' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 0 10px' }}>
														<img style={{ height: '50px', marginRight: '20px' }} src={google_verified} alt="icon" />
														<h4>Google Verified Address</h4>
													</div>}
												<div style={{ width: '100%', height: '450px', background: '#f2f2f2' }}>
													{finallatlng.length ? <MyMapComponent
														isMarkerShown
														currentZipCode={values.businessInfo.postCode}
														locationDataSet={finallatlng}
														locationRadius={5000}
														currentCityCord={{ lat: parseFloat(currentCitylat), lng: parseFloat(currentCitylng) }}
													/> : <></>}
												</div>
											</div>

										</div>
									</div>

									<div className='info-col-left'>
										<div className='white-box'>
											<div className='box-head'>
												<h4>Additional Info</h4>
											</div>
											<div className='box-body'>

												<div id={'businessInfo.website'} className={`field-full ${values.businessInfo.website == '' ? 'error' : ''}`}>
													<Field as={TextField}
														error={values.businessInfo.website == '' ? true : false}
														label='Website URL' type='text' placeholder='Website URL' name='businessInfo.website' value={values.businessInfo.website} />
												</div>
												<div className={`field-full`}>
													<Field as={TextField}
														label='Appointment URL' type='text' placeholder='Appointment URL' name='businessInfo.appointmenturl' value={values.businessInfo.appointmenturl} />
												</div>
												<div id={'businessInfo.businessPhone'} className={`field-full ${values.businessInfo.businessPhone == '' ? 'error' : ''}`}>
													<Field as={TextField}
														error={values.businessInfo.businessPhone == '' ? true : false} maxLength="14"
														label='Practice Phone Number' type='text' placeholder='Practice Phone Number' name='businessInfo.businessPhone'
														value={formatPhoneNumber(values.businessInfo.businessPhone)} />
												</div>
												<div id={'businessInfo.firstname'} className={`field-full ${values.businessInfo.firstname == '' ? 'error' : ''}`}>
													<Field as={TextField}
														error={values.businessInfo.firstname == '' ? true : false} maxLength="50"
														label='Practice Contact First Name' type='text' placeholder='Practice Contact First Name' name='businessInfo.firstname'
														value={values.businessInfo.firstname} />
												</div>
												<div id={'businessInfo.lastname'} className={`field-full ${values.businessInfo.lastname == '' ? 'error' : ''}`}>
													<Field as={TextField}
														error={values.businessInfo.lastname == '' ? true : false} maxLength="50"
														label='Practice Contact Last Name' type='text' placeholder='Practice Contact Last Name' name='businessInfo.lastname'
														value={values.businessInfo.lastname} />
												</div>
												<div className={`field-full`}>
													<Field as={TextField} maxLength="100"
														label='Email Address' type='text' placeholder='Email Address' name='businessInfo.email2' value={values.businessInfo.email2} />
												</div>
											</div>
										</div>

										<div className='white-box'>
											<div className='box-head'>
												<h4>Brands My Practice Supports</h4>
											</div>
											<div className='box-body'>
												{BrandLoading ? <div style={{ marginBottom: '20px' }}><CircularProgress /> </div>
													: <div className="table-body" style={{ marginBottom: '20px' }}>
														{BrandsList.length && BrandsList.map((item, index) => (
															<div className='custom-checkbox' style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }} >
																<input type='checkbox'
																	name={`${item.ID}`}
																	id={`${item.ID}`}
																	defaultChecked={item.Checked}
																	onChange={(e) => { handleCheckList(item.ID); }}
																/>
																<label htmlFor={`${item.ID}`} style={{ paddingLeft: '40px' }}>
																	{item.Name.split('®')[0]}{item.Name.match(/®/g) ? <sup>&reg;&nbsp;</sup> : ''}{item.Name.split('®')[1]}
																</label>
																{item.URL != "" && item.URL != undefined && <label style={{ paddingLeft: '40px', color: '#1073c6', cursor: 'pointer' }} onClick={() => window.open(item.URL)}>View Landing Page</label>}
															</div>
														))}
													</div>}

												<div className='field-field-row-wrap'>
													<div className='field-field-col'>
														<label style={{ fontSize: '15px' }}>Vision Source Member</label>
														<div className='field-field-row-wrap'>
															<div className="custom-radio">
																<input type="radio" name="VisionSource" id={`yes-textvision`}
																	value={values.businessInfo.VisionSource}
																	defaultChecked={values.businessInfo.VisionSource == 0 ? false : true}
																	onClick={(e) => setFieldValue('businessInfo.VisionSource', 1)} />
																<label htmlFor={`yes-textvision`}>Yes</label>
															</div>
															<div className="custom-radio">
																<input type="radio" name="VisionSource" id={`no-textvision`}
																	value={values.businessInfo.VisionSource}
																	defaultChecked={values.businessInfo.VisionSource == 0 ? true : false}
																	onClick={(e) => setFieldValue('businessInfo.VisionSource', 0)} />
																<label htmlFor={`no-textvision`}>No</label>
															</div>
														</div>
													</div>
													<div className='field-field-col'>
														<label style={{ fontSize: '15px' }}>VSP Premier Member</label>
														<div className='field-field-row-wrap'>
															<div className="custom-radio">
																<input type="radio" name="VSP" id={`yes-textvsp`}
																	value={values.businessInfo.VSP}
																	defaultChecked={values.businessInfo.VSP == 0 ? false : true}
																	onClick={(e) => setFieldValue('businessInfo.VSP', 1)} />
																<label htmlFor={`yes-textvsp`}>Yes</label>
															</div>
															<div className="custom-radio">
																<input type="radio" name="VSP" id={`no-textvsp`}
																	value={values.businessInfo.VSP}
																	defaultChecked={values.businessInfo.VSP == 0 ? true : false}
																	onClick={(e) => setFieldValue('businessInfo.VSP', 1)} />
																<label htmlFor={`no-textvsp`}>No</label>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className='info-col-right'>
										<div className='white-box'>
											<div className='box-head'>
												<h4>Practice Logo</h4>
											</div>
											{handleUploadLogoSize(values.logo)}
											<div className='box-body'>
												<Grid container direction={'row'} style={{ alignSelf: 'center', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
													<Grid container direction={'row'} style={{ alignSelf: 'center', height: '200px', width: '400px', border: '2px solid #f5f7fa', justifyContent: 'center', alignItems: 'center', margin: '10px' }}>
														{values.logo ? <AspectRatio ratio="3/4" style={{ maxWidth: '400px' }} >
															<img style={{ maxHeight: '200px' }} src={values.logo} alt='preview-imag' /> </AspectRatio>
															: <p style={{ textAlign: 'center', margin: '0 10px', fontSize: '14px' }}> - Accepted files: PNG & JPG<br />
																- PNG with transparent background recommended<br />
																- Recommended logo size:<strong> 1050 pixels X 750 pixels</strong><br />
																- Smaller logos may appear pixelated in print products </p>}
													</Grid>
												</Grid>
												<div className='image-link-btn' style={{ textAlign: 'center' }}>
													{values.logo && <>
														<div className='box-head' style={{ border: 'none' }}> <h4 style={{ fontSize: '18px' }}>Uploaded Logo Size: {uploadImageWidth} pixels X {uploadImageHeight} pixels</h4> </div>
														<div style={{ display: 'flex', alignItems: 'center' }}>
															<img className="logo-icon-location" src={checkmark} alt="icon" />
															<p>Your uploaded logo size is optimized for digital (web)</p>
														</div>
														<div style={{ display: 'flex', alignItems: 'center' }}>

															<img className="logo-icon-location" src={uploadImageWidth > 1049 && uploadImageHeight > 749 ? checkmark : fail} alt="icon" />
															<div>
																<p>Your uploaded logo size is {uploadImageWidth > 1049 && uploadImageHeight > 749 ? "" : "NOT"} optimized for print.</p>
																{uploadImageWidth > 1049 && uploadImageHeight > 749 ? <></> : <p style={{ fontSize: '11px', marginBottom: '3%' }}>(Please upload a larger file if you wish to utilize the print products.)</p>}
															</div>
														</div>
													</>}
													<div className='button-field file-upload-btn'>
														<input type='file' id='upload-btn' name='files'
															onChange={(e) => {
																const file = e.target.files[0];
																if (file && (file.type.includes("png") || file.type.includes("jpeg") || file.type.includes("jpg"))) {
																	let file1 = e.target.files[0]
																	file1['preview'] = URL.createObjectURL(e.target.files[0]);

																	let img = new Image()
																	img.src = window.URL.createObjectURL(file)
																	img.onload = () => {
																		setFieldValue("logo", window.URL.createObjectURL(
																			new Blob([file], { type: file.type })))
																		setuploadImageHeight(img.height)
																		setuploadImageWidth(img.width)

																		const image2base64 = require('image-to-base64');
																		image2base64(file1.preview).then((response) => {
																			wsUploadPracticeLogo(props.practiceId, uuid() + "." + file.type.split('/')[1], response, true)
																		}).catch((error) => { })
																		return true;
																	}
																}
															}}
														/>
														<label htmlFor='upload-btn'>{AppConstants.ButtonText.Upload}</label>
													</div>
													<div className='button-field'>
														<input type="button" value={AppConstants.ButtonText.Delete} name="delete" onClick={() => setFieldValue("logo", "")} />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>


								<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<div className={errors.businessInfo == undefined && isPostcodeSelected ? `button-field confirm-update` : `disable-btn button-field confirm-update`}>
										<input type='button' name='submit' value={AppConstants.ButtonText.ConfirmUpdates}
											onClick={() => { handleSubmit(); handleValidateScroll(values) }}
										/>
									</div>
								</div>
							</form>
						);
					}}
				</Formik>

			</div>
	);
};

export default MyLocationValidationComponent;