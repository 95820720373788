import React, { useState } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import { Formik, Field } from "formik";

import * as yup from "yup";
import checkmark from "../../../assets/images/icon/checkmark.png";
import fail from "../../../assets/images/icon/fail.png";

import uuid from 'react-uuid';
import "./location.css";
import * as APIController from '../../../api/APIController.js';
import { AspectRatio } from 'react-aspect-ratio';

import TextField from "../../../shared/components/TextField/TextField";
import * as AppConstants from "../../../config/AppConstant";

import FacebookLogin from 'react-facebook-login';
import ModalComponent from "../../../shared/components/modal/modal";
import close_icon from "../../../assets/images/gray-close-ic.svg";

import { URLs } from "../../../api/URLs";
import { usePlacesWidget } from "react-google-autocomplete";

// city: yup.string().required('City is required'),
// state: yup.string().required('State is required'),
// email: yup.string().required('Business email address is required'),
const validateProductForm = yup.object().shape({
	businessInfo: yup.object().shape({
		name: yup.string().required('Business Name is required'),
		address1: yup.string().required('Business Address 1 is required'),
		postCode: yup.string().required('Post Code is required'),
		businessPhone: yup.string().required('Business Phone Number is required'),
	})
});

const LocationValidationComponent = (props) => {

	const [openZIPCodeSuggestion, setopenZIPCodeSuggestion] = useState(false);
	const [searchPostcodesList, setsearchPostcodesList] = useState([]);
	const [isPostcodeSelected, setisPostcodeSelected] = useState(true);

	const [uploadImageHeight, setuploadImageHeight] = React.useState(750);
	const [uploadImageWidth, setuploadImageWidth] = React.useState(1050);

	const [FacebookPageName, setFacebookPageName] = React.useState('');
	const [facebookPopup, setfacebookPopup] = React.useState(true);
	const [WhichSMClicked, setWhichSMClicked] = React.useState('');
	const [InstagramPageName, setInstagramPageName] = React.useState('');
	const [Loading, setLoading] = React.useState(true)
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [FacebookAccessToken, setFacebookAccessToken] = React.useState('');
	const [facebookPages, setFacebookPages] = React.useState([]);


	const [facebookverified, setfacebookverified] = React.useState(false);
	const [instagramverified, setinstagramverified] = React.useState(false);

	const [SelectFBPageID, setSelectFBPageID] = React.useState([]);

	const [BrandLoading, setBrandLoading] = useState(true);
	const [BrandsList, setBrandsList] = React.useState([]);

	let initialValues = props.locationDetail;

	React.useEffect(() => {
		wsGetBrand()
		if (AppConstants.GetLocalStorage("SocialAutomationAllLocations") != "" && AppConstants.GetLocalStorage("SocialAutomationAllLocations") != undefined && AppConstants.GetLocalStorage("SocialAutomationAllLocations") != null) {

			const AllLocations = JSON.parse(localStorage.getItem("SocialAutomationAllLocations"));
			const AllLocationsWithoutCurrentPractice = AllLocations.filter((e) => e.PracticeID == props.practiceId);
			if (AllLocationsWithoutCurrentPractice.length) {
				if (AllLocationsWithoutCurrentPractice[0].FacebookUserName != undefined) {
					setfacebookverified(true)
					setFacebookPageName(AllLocationsWithoutCurrentPractice[0].FacebookUserName)
				}
				if (AllLocationsWithoutCurrentPractice[0].InstagramUserName != undefined) {
					setinstagramverified(true)
					setInstagramPageName(AllLocationsWithoutCurrentPractice[0].InstagramUserName)
				}
			}
		}

		// window.fbAsyncInit = function () {
		// 	window.FB.init({
		// 		appId: AppConstants.facebookConfig.client_id,
		// 		cookie: false, // Enable cookies to allow the server to access the session.
		// 		xfbml: true, // Parse social plugins on this webpage.
		// 		version: 'v14.0' // Use this Graph API version for this call.
		// 	})

		// 	window.FB.getLoginStatus(function (response) { // Called after the JS SDK has been initialized.
		// 		checkFacebookLoginStatusCallback(response); // Returns the login status.
		// 	});
		// };


	}, []);

	const wsResponseFacebook = (responseFacebook) => {
		if (responseFacebook.status == "unknown") {
			setIsModalOpen(false)
			setfacebookverified(false)
		} else {
			AppConstants.SetLocalStorage("localStorageFacebookUserID", responseFacebook.id)
			APIController.GetFacebookPages(responseFacebook.accessToken)
				.then((response) => {
					let Response = []
					response.object.data.length && response.object.data.map((obj) => Response.push({ ...obj, "practiceID": props.practiceId }))
					setFacebookAccessToken(responseFacebook.accessToken)
					setFacebookPages(Response)
					setIsModalOpen(true)
				})
		}
	};

	function checkFacebookLoginStatusCallback(response) { // Called with the results from FB.getLoginStatus().
		if (response.status === 'connected') { // Logged into your webpage and Facebook.
			// getUserProfile();
			window.FB.logout(function (response) { })
		}
	}

	const wsUploadPracticeLogo = (practiceId, name, value, isPracticeLogo) => {
		APIController.UploadPracticeLogo(practiceId, name, value, isPracticeLogo)
			.then((response) => { });
	}

	const handleValidateScroll = (val) => {
		let id;
		if (val.businessInfo.name == '') {
			id = 'businessInfo.name'
		}
		else if (val.businessInfo.address1 == '') {
			id = 'businessInfo.address1'
		}
		else if (val.businessInfo.city == '') {
			id = 'businessInfo.city'
		}
		else if (val.businessInfo.state == '') {
			id = 'businessInfo.state'
		}
		else if (val.businessInfo.postCode == '' || val.businessInfo.postCode.length < 5) {
			id = 'businessInfo.postCode'
		}
		else if (val.businessInfo.businessPhone == '' || val.businessInfo.businessPhone == undefined) {
			id = 'businessInfo.businessPhone'
		}

		var element = document.getElementById(id);
		id != undefined && element.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
	}

	const formatPhoneNumber = (phoneNumberString) => {
		var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
		var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			var intlCode = (match[1] ? '' : '');
			return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
		}
		return null;
	}

	const wsSearchPostcodes = (postcode) => {
		APIController.SearchPostcodes(postcode)
			.then((response) => {
				setsearchPostcodesList(response.object.data)
			})
	}

	const handleUploadLogoSize = (image) => {
		let img = new Image()
		img.src = image
		img.onload = () => {
			setuploadImageHeight(img.height)
			setuploadImageWidth(img.width)
		}
	}

	const handleCheckList = (ID) => {
		setBrandsList(BrandsList.map((item, index) => item.ID !== ID ? item : { ...item, Checked: !item.Checked }))
		AppConstants.SetLocalStorage("localStorageBrandsData", JSON.stringify(BrandsList.map((item, index) => item.ID !== ID ? item : { ...item, Checked: !item.Checked })))
	};

	const wsGetBrand = () => {
		APIController.GetBrand("1", "AutomationOrderNo", "ASC", AppConstants.GetLocalStorage("localStorageAutomationSubscriptionID"))
			.then((response) => {
				if (response.error == null) {
					let Temp = response.object.data.map((item, index) => { return ({ Checked: true, ID: item.ID, Name: item.Name }) })
					let selectedIDs = props.locationDetail.businessInfo.brands != ""
						&& props.locationDetail.businessInfo.brands != undefined
						&& props.locationDetail.businessInfo.brands != null
						? JSON.parse(props.locationDetail.businessInfo.brands)
						: []

					if (selectedIDs.length) {
						selectedIDs.map((inner) => {
							Temp = (Temp.map((item, index) =>
								item.ID == inner.ID ? { ...item, Checked: true, URL: inner.URL } : { ...item }
							))
						})
					}
					setBrandsList(Temp)
					AppConstants.SetLocalStorage("localStorageBrandsData", JSON.stringify(Temp))
				}
				setBrandLoading(false)
			})
	}

	const [ValueLoading, setvalueLoading] = useState(false);
	const { ref } = usePlacesWidget({
		apiKey: URLs.googlekey,
		options: {
			componentRestrictions: { country: "us" },
			types: ["address"],
			fields: ["address_component", "formatted_address", "plus_code", "geometry", "name"],
			strictBounds: true,
		},
		onPlaceSelected: (place) => {
			setvalueLoading(true)

			let initialValuesTemp = initialValues;

			initialValuesTemp.businessInfo.address1 = ""
			initialValuesTemp.businessInfo.address2 = ""
			initialValuesTemp.businessInfo.postCode = ""
			initialValuesTemp.businessInfo.city = ""
			initialValuesTemp.businessInfo.state = ""

			initialValuesTemp.businessInfo.latitude = place.geometry.location.lat()
			initialValuesTemp.businessInfo.longitude = place.geometry.location.lng()
			initialValuesTemp.businessInfo.googleaddress = place.formatted_address

			// setcurrentCitylat(place.geometry.location.lat())
			// setcurrentCitylng(place.geometry.location.lng())
			// setfinallatlng([{ lat: place.geometry.location.lat(), lng: place.geometry.location.lng(), selected: true }])
			for (const component of place.address_components) {
				const componentType = component.types[0];

				switch (componentType) {
					case "street_number": {
						initialValuesTemp.businessInfo.address1 = `${component.long_name}`
						break;
					} case "route": {
						initialValuesTemp.businessInfo.address1 = initialValuesTemp.businessInfo.address1 + " " + `${component.long_name}`
						break;
					} case "subpremise": {
						initialValuesTemp.businessInfo.address2 = `${component.long_name}`
						break;
					} case "postal_code": {
						initialValuesTemp.businessInfo.postCode = `${component.long_name}`
						break;
					} case "locality": {
						initialValuesTemp.businessInfo.city = `${component.long_name}`
						break;
					} case "administrative_area_level_1": {
						initialValuesTemp.businessInfo.state = `${component.short_name}`
						break;
					}
				}
			}
			initialValues = initialValuesTemp
			setvalueLoading(false)
		},
	});

	return (
		ValueLoading ? <CircularProgress /> :
			<div className='business-info-form'>
				<Formik
					initialValues={initialValues}
					validationSchema={validateProductForm}
					validateOnMount={false}
					onSubmit={(values) => {
						if (values.businessInfo.postCode.length < 5) {
							handleValidateScroll(values)
						} else if (formatPhoneNumber(values.businessInfo.businessPhone).length < 14) {
							handleValidateScroll(values)
						} else {
							let tempIds = []
							BrandsList.filter((item) => { if (item.Checked == true) { tempIds.push(item.ID) } })
							values.businessInfo.brands = tempIds.toString()
							props.updateLocationDetails(values)
						}
					}}>
					{({
						handleSubmit,
						values,
						setFieldValue,
						errors,
						touched,
					}) => {
						errors.businessInfo == undefined ? props.handleTitle('Valid') : props.handleTitle('inValid')
						return (
							<form onSubmit={handleSubmit}>
								<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<div className={errors.businessInfo == undefined && isPostcodeSelected ? `confirm-update` : `disable-btn confirm-update`}>
										<input type='submit' name='submit' value="Save Changes"
											onClick={() => { handleSubmit(); handleValidateScroll(values) }}
										/>
									</div>
								</div>

								<div className='business-info-wrap'>
									<div className='info-col-left'>
										<div className='white-box'>
											<div className='box-head'>
												<h4>Practice info</h4>
											</div>
											<div className='box-body'>
												<div id={'businessInfo.name'} className={`field-full ${values.businessInfo.name == '' ? 'error' : ''}`}>
													<Field as={TextField}
														error={values.businessInfo.name == '' ? true : false}
														label='Business Name' type='text' placeholder='Business Name' name='businessInfo.name' value={values.businessInfo.name} />
												</div>

												<div id={'businessInfo.address1'} className={`field-full ${values.businessInfo.address1 == '' ? 'error' : ''}`}>
													{/* error={values.businessInfo.address1 == '' || values.businessInfo.latitude == null || values.businessInfo.longitude == null ? true : false} */}
													<Field as={TextField} inputRef={ref}
														error={values.businessInfo.address1 == '' ? true : false} maxLength="100"
														label='Business Address 1' type='text' placeholder='Business Address 1' id='businessInfo.address1'
														name='businessInfo.address1' value={values.businessInfo.address1} />
												</div>

												<div id={'businessInfo.address2'} className={`field-full`}>
													<Field as={TextField}
														label='Business Address 2' type='text' placeholder='Business Address 2' name='businessInfo.address2' value={values.businessInfo.address2} />
												</div>
												<div id={'businessInfo.city'} className={`field-full`}>
													<Field disabled as={TextField} error={values.businessInfo.city == '' ? true : false}
														label='City' type='text' placeholder='City' name='businessInfo.city' value={values.businessInfo.city} />
												</div>
												<div id={'businessInfo.state'} className={`field-full`}>
													<Field disabled as={TextField} error={values.businessInfo.state == '' ? true : false}
														label='State' type='text' placeholder='State' name='businessInfo.state' value={values.businessInfo.state} />
												</div>
												<div style={{ position: 'relative' }} className={`field-full ${values.businessInfo.postCode == '' || values.businessInfo.postCode.length < 5 ? 'error' : ''}`}>
													<div id={`businessInfo.postCode`} style={{ position: 'relative' }}>
														<Field as={TextField} error={values.businessInfo.postCode == '' || values.businessInfo.postCode.length < 5 ? true : false}
															label='Post Code' type='text' placeholder='Post Code' name='businessInfo.postCode' value={values.businessInfo.postCode}
															onChange={(e) => {
																setisPostcodeSelected(false)
																if (e.target.value != '') {
																	setopenZIPCodeSuggestion(true);
																	wsSearchPostcodes(e.target.value);
																	setFieldValue('businessInfo.postCode', e.target.value)
																} else {
																	setopenZIPCodeSuggestion(false);
																	setsearchPostcodesList([])
																	setFieldValue('businessInfo.city', "");
																	setFieldValue('businessInfo.state', "");
																	setFieldValue('businessInfo.postCode', '');
																}
															}} />

														{openZIPCodeSuggestion ? (
															<div className='Suggetion-wrap'>
																{searchPostcodesList.length == 0 ? <div style={{ textAlign: 'center' }}> {'No Suggetion Found'} </div>
																	: searchPostcodesList.map((item, index) => {
																		return (
																			<div style={{ cursor: 'pointer', padding: '5px' }}
																				onClick={() => {
																					setFieldValue('businessInfo.city', item.City);
																					setFieldValue('businessInfo.state', item.State);
																					setFieldValue('businessInfo.postCode', item.Postcode);
																					setopenZIPCodeSuggestion(false);
																					setisPostcodeSelected(true)
																				}} >
																				{item.Postcode}
																			</div>
																		)
																	})
																}
															</div>
														) : null}
													</div>
												</div>

												<div id={'businessInfo.businessPhone'} className={`field-full ${values.businessInfo.businessPhone == '' ? 'error' : ''}`}>
													<Field as={TextField}
														error={values.businessInfo.businessPhone == '' ? true : false}
														label='Business Phone Number' type='text' placeholder='Business Phone Number' name='businessInfo.businessPhone'
														value={formatPhoneNumber(values.businessInfo.businessPhone)} />
												</div>
											</div>
										</div>

										<div className='white-box'>
											<div className='box-head'>
												<h4>Brands To Include In My Social Posts</h4>
											</div>
											<div className='box-body'>
												{BrandLoading
													? <div style={{ display: 'flex', justifyContent: 'center', minHeight: '150px', alignItems: 'center' }}>
														<CircularProgress />
													</div>
													: <>
														<div className="table-body" style={{ marginBottom: '20px' }}>
															{BrandsList.length && BrandsList.map((item, index) => (
																<div className='custom-checkbox' style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }} >
																	<input type='checkbox'
																		name={`${item.ID}`}
																		id={`${item.ID}`}
																		defaultChecked={item.Checked}
																		onChange={(e) => { handleCheckList(item.ID); }}
																	/>
																	<label htmlFor={`${item.ID}`} style={{ paddingLeft: '40px' }}>{item.Name.split('®')[0]}{item.Name.match(/®/g) ? <sup>&reg;&nbsp;</sup> : ''}{item.Name.split('®')[1]}</label>
																</div>
															))}
														</div>
														<div className='field-field-row-wrap'>
															<div className='field-field-col'>
																<label style={{ fontSize: '15px' }}>Vision Source Member</label>
																<div className='field-field-row-wrap'>
																	<div className="custom-radio">
																		<input type="radio" name="VisionSource" id={`yes-textvision`}
																			value={values.businessInfo.VisionSource}
																			defaultChecked={values.businessInfo.VisionSource == 0 ? false : true}
																			onClick={(e) => setFieldValue('businessInfo.VisionSource', 1)} />
																		<label htmlFor={`yes-textvision`}>Yes</label>
																	</div>
																	<div className="custom-radio">
																		<input type="radio" name="VisionSource" id={`no-textvision`}
																			value={values.businessInfo.VisionSource}
																			defaultChecked={values.businessInfo.VisionSource == 0 ? true : false}
																			onClick={(e) => setFieldValue('businessInfo.VisionSource', 0)} />
																		<label htmlFor={`no-textvision`}>No</label>
																	</div>
																</div>
															</div>
															<div className='field-field-col'>
																<label style={{ fontSize: '15px' }}>VSP Premier Member</label>
																<div className='field-field-row-wrap'>
																	<div className="custom-radio">
																		<input type="radio" name="VSP" id={`yes-textvsp`}
																			value={values.businessInfo.VSP}
																			defaultChecked={values.businessInfo.VSP == 0 ? false : true}
																			onClick={(e) => setFieldValue('businessInfo.VSP', 1)} />
																		<label htmlFor={`yes-textvsp`}>Yes</label>
																	</div>
																	<div className="custom-radio">
																		<input type="radio" name="VSP" id={`no-textvsp`}
																			value={values.businessInfo.VSP}
																			defaultChecked={values.businessInfo.VSP == 0 ? true : false}
																			onClick={(e) => setFieldValue('businessInfo.VSP', 1)} />
																		<label htmlFor={`no-textvsp`}>No</label>
																	</div>
																</div>
															</div>
														</div>
													</>}
											</div>
										</div>

									</div>

									<div className='info-col-right'>

										<div className='white-box' style={!facebookverified && !instagramverified ? { border: '3px solid #FF0000' } : {}}>
											<div className='box-head'> <h4>Social Media Credentials</h4> </div>
											<div className='box-body'>
												<p style={{ fontSize: '14px' }}>Please verify your Facebook and/or Instagram credentials below so REACH can post approved Bausch + Lomb content to your BUSINESS pages on your behalf. Credential verification occurs directly with Facebook and Instagram. REACH does NOT store nor does it have access to these credentials.</p>
												<div style={{ display: 'flex', justifyContent: 'center' }}>
													<div className='table-body'>
														{AppConstants.GetLocalStorage("ShowSMAuthentication") == "1"
															&& <div style={{ display: 'grid', justifyItems: 'center', marginTop: '20px' }}>
																<FacebookLogin
																	isDisabled={facebookverified}
																	appId={AppConstants.facebookConfig.client_id}
																	fields={AppConstants.facebookConfig.fields}
																	scope={AppConstants.facebookConfig.fbscope}
																	callback={wsResponseFacebook}
																	authLoad={true}
																	onClick={() => {
																		setWhichSMClicked('1');
																		// var Test = { "practiceID": props.practiceId, "access_token": "EAAITpVtTmVYBAGTeXjxPDDTRpylF8UaWzDTFN89ZBgJpZCgjLqVHLg94RhfQxAGiRFFAMH3qWOhrZBLixSxTUtAqXorezRPbxt7eKjZBhzxwZCGxAec0yD3YzMT9LySZBlMGQkVtql0XgvLFU53MRs2vAIlsQIg6GP7Xzydk6lbSYN6YFtva7X75HRpnj87UhGCZCVQcZABJ7iZA5YN39spGG", "category": "Optometrist", "category_list": [{ "id": "192316870798061", "name": "Optometrist" }], "name": "Specialty Eye Care", "id": "111312684912574" }
																		// AppConstants.SetLocalStorage("localStorageFBPageData", JSON.stringify(Test));
																		// setfacebookverified(true)
																		// setFacebookPageName(Test.name)
																	}}
																	cssClass="my-facebook-button-class"
																	textButton="&nbsp;&nbsp;Log in with Facebook"
																	icon={<i className="fa fa-facebook-square" style={{ fontSize: '20px' }}>
																	</i>}
																/>

																{facebookverified && <div className='custom-checkbox' style={{ display: 'grid', justifyItems: 'center' }}>
																	<input type='checkbox'
																		name='facebook-verified'
																		id={"FacebookCredentials"}
																		value={facebookverified}
																		defaultChecked={facebookverified}
																		checked={facebookverified}
																		onChange={() => { setfacebookverified(false) }}
																	/>
																	<label htmlFor="FacebookCredentials"
																		style={{ paddingLeft: '40px', display: 'flex', alignContent: 'center' }}>
																		{"Facebook Credentials Verified"}</label>
																	<div>{FacebookPageName}</div>
																</div>}
															</div>}

														<div className='box-head' style={{ width: '100%' }} />

														{AppConstants.GetLocalStorage("ShowInstaAutomation") == "1"
															&& <div style={{ display: 'grid', justifyItems: 'center', marginTop: '20px' }}>
																<FacebookLogin
																	authLoad={false}
																	isDisabled={!facebookverified || instagramverified}
																	appId={AppConstants.facebookConfig.client_id}
																	fields={AppConstants.facebookConfig.fields}
																	scope={AppConstants.facebookConfig.instascope}
																	callback={wsResponseFacebook}
																	onClick={() => {
																		setWhichSMClicked('2')
																		// var Test = { "practiceID": props.practiceId, "username": "specialtyeyecarenew", "id": "17841453441439823" }
																		// AppConstants.SetLocalStorage("localStorageInstaUserData", JSON.stringify(Test));
																		// setinstagramverified(true)
																		// setInstagramPageName(Test.username)
																	}}
																	cssClass="my-instagram-button-class"
																	textButton="&nbsp;&nbsp;Log in with Instagram"
																	icon={<i className="fa fa-instagram" style={{ fontSize: '20px', color: '#FFF' }}>
																	</i>}
																/>

																{instagramverified &&
																	<div className='custom-checkbox' style={{ display: 'grid', justifyItems: 'center' }}>
																		<input type='checkbox'
																			name='instagram-verified'
																			id={"InstagramCredentials"}
																			value={instagramverified}
																			defaultChecked={instagramverified}
																			checked={instagramverified}
																			onChange={() => { setinstagramverified(false) }}
																		/>
																		<label htmlFor="InstagramCredentials"
																			style={{ paddingLeft: '40px', display: 'flex', alignContent: 'center' }}>
																			{"Instagram Credentials Verified"}</label>
																		<div>{InstagramPageName}</div>
																		{/* <label style={instagramverified ? { paddingLeft: '40px', display: 'flex', alignContent: 'center' } : { paddingLeft: '40px', display: 'flex', alignContent: 'center', color: '#f00' }}>
																			{instagramverified ? "Instagram Credentials Verified" : "Please Verify Instagram Credentials"}</label>
																		<div>{InstagramPageName}</div> */}
																	</div>}
															</div>}
													</div>
												</div>
											</div>
										</div>

										<div className='white-box'>
											<div className='box-head'>
												<h4>Practice Logo</h4>
											</div>
											{handleUploadLogoSize(values.logo)}
											<div className='box-body'>
												<Grid container direction={'row'} style={{ alignSelf: 'center', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
													<Grid container direction={'row'} style={{ alignSelf: 'center', height: '200px', width: '400px', border: '2px solid #f5f7fa', justifyContent: 'center', alignItems: 'center', margin: '10px' }}>
														{values.logo ? <AspectRatio ratio="3/4" style={{ maxWidth: '400px' }} >
															<img style={{ maxHeight: '200px' }} src={values.logo} alt='preview-imag' /> </AspectRatio>
															: <p style={{ textAlign: 'center', margin: '0 10px', fontSize: '14px' }}> - Accepted files: PNG & JPG<br />
																- PNG with transparent background recommended<br />
																- Recommended logo size:<strong> 1050 pixels X 750 pixels</strong><br />
																- Smaller logos may appear pixelated in print products </p>}
													</Grid>
												</Grid>
												<div className='image-link-btn' style={{ textAlign: 'center' }}>
													{values.logo && <>
														<div className='box-head' style={{ border: 'none' }}> <h4 style={{ fontSize: '18px' }}>Uploaded Logo Size: {uploadImageWidth} pixels X {uploadImageHeight} pixels</h4> </div>
														<div style={{ display: 'flex', alignItems: 'center' }}>
															<img className="logo-icon-location" src={checkmark} alt="icon" />
															<p>Your uploaded logo size is optimized for digital (web)</p>
														</div>
														<div style={{ display: 'flex', alignItems: 'center' }}>

															<img className="logo-icon-location" src={uploadImageWidth > 1049 && uploadImageHeight > 749 ? checkmark : fail} alt="icon" />
															<div>
																<p>Your uploaded logo size is {uploadImageWidth > 1049 && uploadImageHeight > 749 ? "" : "NOT"} optimized for print.</p>
																{uploadImageWidth > 1049 && uploadImageHeight > 749 ? <></> : <p style={{ fontSize: '11px', marginBottom: '3%' }}>(Please upload a larger file if you wish to utilize the print products.)</p>}
															</div>
														</div>
													</>}
													<div className='button-field file-upload-btn'>
														<input type='file' id='upload-btn' name='files'
															onChange={(e) => {
																const file = e.target.files[0];
																if (file && (file.type.includes("png") || file.type.includes("jpeg") || file.type.includes("jpg"))) {
																	let file1 = e.target.files[0]
																	file1['preview'] = URL.createObjectURL(e.target.files[0]);

																	let img = new Image()
																	img.src = window.URL.createObjectURL(file)
																	img.onload = () => {
																		setFieldValue("logo", window.URL.createObjectURL(
																			new Blob([file], { type: file.type })))
																		setuploadImageHeight(img.height)
																		setuploadImageWidth(img.width)

																		const image2base64 = require('image-to-base64');
																		image2base64(file1.preview).then((response) => {
																			wsUploadPracticeLogo(props.practiceId, uuid() + "." + file.type.split('/')[1], response, true)
																		}).catch((error) => { })
																		return true;
																	}
																}
															}}
														/>
														<label htmlFor='upload-btn'>{AppConstants.ButtonText.Upload}</label>
													</div>
													<div className='button-field'>
														<input type="button" value={AppConstants.ButtonText.Delete} name="delete" onClick={() => setFieldValue("logo", "")} />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</form>
						);
					}}
				</Formik>

				{isModalOpen &&
					<ModalComponent show={isModalOpen} >
						<div className="popup-box forgot-popup-box" style={{ minWidth: '700px' }} >
							<div className="box-heading">
								<h2 className="h1">{WhichSMClicked == '1' ? 'Select A Business Page' : 'Select Page Connected with Professional Instagram Account'}</h2>
								<button className="close-btn"><img src={close_icon} alt="icon" onClick={() => {
									setIsModalOpen(false);
									if (WhichSMClicked == '1') {
										setfacebookverified(false)
									} else if (WhichSMClicked == '2') {
										setinstagramverified(false)
									}

								}} /></button>
							</div>
							<div className="popup-wrap" style={{ margin: 0, padding: '0 20px', display: 'flex', flexWrap: 'wrap' }}>
								{facebookPages.length
									? facebookPages.map((item) =>
										<div className='location-inner-content' style={{ padding: '15px 0' }}>
											<div className='location-name'>
												<div className='custom-radio' >
													<input
														type={'radio'}
														name='location-fbpage'
														id={`${item.id}`}
														onChange={(e) => { setSelectFBPageID(item) }}
													/>
													<label htmlFor={`${item.id}`} style={{ fontWeight: 400 }}> {item.name} </label>
												</div>
											</div>
										</div>
									)
									: <div style={{ padding: '20px', textAlign: 'center' }}>
										<label>We did not find any Business Page associated with your Facebook Account. Please create the Facebook Business Page and Authenticate Again.</label>
									</div>}
								<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
									<div className={SelectFBPageID != "" ? `form-field button-field` : `form-field button-field disable-btn`} style={{ textAlign: 'center', margin: '20px 0' }} >
										{facebookPages.length ?
											<input type="submit" name="select-page" value="Select"
												onClick={() => {
													if (WhichSMClicked == '1') {	//Facebook Clicked
														AppConstants.SetLocalStorage("localStorageFBPageData", JSON.stringify(SelectFBPageID));
														setfacebookverified(true)
														setFacebookPageName(SelectFBPageID.name)
														setIsModalOpen(false);
													} else if (WhichSMClicked == '2') { 	//Instagram Cliked
														APIController.GetInstaAccount(FacebookAccessToken, SelectFBPageID.id).then((response) => {
															if (response != null && response.object != null) {
																setinstagramverified(true)
																localStorage.setItem("localStorageInstaPageData", FacebookAccessToken)
																localStorage.setItem("localStorageInstaUserData", JSON.stringify(response.object))
																setInstagramPageName(response.object.username)
																setIsModalOpen(false)
															} else {
																alert('We did not find any Instagram Business Account associated with your Facebook Page. Please Associate with Facebook Page and Authenticate Again.');
															}
														})
													}
												}} />
											: <input type="submit" name="close-popup" value="Close" onClick={() => { setIsModalOpen(false) }} />}
									</div>
								</div>
							</div>
						</div>
					</ModalComponent>}
			</div>
	);
};

export default LocationValidationComponent;