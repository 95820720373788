/*eslint-disable eqeqeq*/
import React from "react";
import * as APIController from '../../../../api/APIController.js';
import Table from "../../../../shared/components/table/table";
import TableHead from "../../../../shared/components/table/tableHead";
import TableRow from "../../../../shared/components/table/tableRow";
import TableBody from "../../../../shared/components/table/tableBody";
import TableCell from "../../../../shared/components/table/tableCell";
import double_left_arrow from '../../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../../assets/images/right-arrow.svg';
import "react-perfect-scrollbar/dist/css/styles.css";
import PageTitle from "../../../../config/pageTitle";
import * as AppConstant from "../../../../config/AppConstant";
import PerfectScrollbar from "react-perfect-scrollbar";

import deleteIcon from '../../../../assets/images/delete.svg'
import editIcon from '../../../../assets/images/edit.svg'
import filtericon from '../../../../assets/images/ic_feather_filter.svg'
import viewIcon from '../../../../assets/images/view_icon.png'
import settingIcon from "../../../../assets/images/settings.svg";
import logFileIcon from "../../../../assets/images/icon/logfile.png";
import close_icon from "../../../../assets/images/close_icon.svg";

import Modal from '../../../../shared/components/modal/modal';
import ConfirmtionDialog from '../../../ConfirmDialog/ConfirmationDialog';
import Notification from '../../../../shared/components/notification/notification';
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";
import AssetViewer from "./assetViewer";
import LogsPopupList from "./logsPopupList";

const MasterTemplateList = (props) => {
	const headerList = ['action', "Template Name", "Brand", "Created At", "Last Modified At", "Total Users", "Is active ?", "Is Doc Finder ?"]
	const { currentPage, count, rowsPerPage, rowsPerPageOptions, list, to, from } = props;
	let listData = list;
	listData = list.slice(from, to)
	const [openDialog, setOpenDialog] = React.useState(false);
	const [viewDialog, setViewDialog] = React.useState(false);
	const [generatePageDialog, setGeneratePageDialog] = React.useState(false);
	const [DeleteId, setDeleteId] = React.useState('');
	const [HTMLContent, setHTMLContent] = React.useState('');
	const [isLoading, setIsLoading] = React.useState(false);
	const [isLogsDialog, setIsLogsDialog] = React.useState(false);

	const page = Math.floor(count / rowsPerPage) + 1;

	/**
	 * handleChangeInCurrentPage method is used to change page number
	 * it will check that page number does not exist last page
	 * @param {*} value 
	 */
	const handleChangeInCurrentPage = (value) => {
		if (value <= page && Number(parseInt(value))) {
			props.handleChangePage(Number(value));
		}
	}

	const wsDeleteLandingPageAutomationTemplate = (ID) => {
		setIsLoading(true)
		APIController.DeleteLandingPageAutomationTemplate(ID)
			.then((response) => {
				if (response.error == null) {
					if (response.object.status == 1) {
						props.handleNotification(response.object.message);
					} else {
						props.handleNotification(response.object.message, true);
					}
					props.handlebind()
					setOpenDialog(false)
					setIsLoading(false)
				}
				else if (response.error.message && response.error.message.length > 0) {
					props.handleNotification(response.error.message)
				}
			})
	}
	const wsGenerateLandingPagesForTemplate = (ID) => {
		setIsLoading(true)
		APIController.GenerateLandingPagesForTemplate(ID)
			.then((response) => {
				if (response.error == null) {
					if (response.object.status == 1) {
						props.handleNotification(response.object.message);
					} else {
						props.handleNotification(response.object.message, true);
					}
					props.handlebind()
					setOpenDialog(false)
					setIsLoading(false)
				}
				else if (response.error.message && response.error.message.length > 0) {
					props.handleNotification(response.error.message)
				}
			})
	}

	const [message, setMessage] = React.useState("");
	const [showNotification, setShowNotification] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const handleClearNotification = () => {
		setMessage('')
		setShowNotification(false)
		setIsError(false)
	}
	return (
		<>
			<h1><PageTitle Title={AppConstant.ContactDetais.ViewMasterTemplate} /></h1>
			<div className="table-main-wrap">
				<PerfectScrollbar component='div'>
					<Table>
						<TableHead>
							<TableRow>
								{headerList.map((obj, index) => { return <TableCell style={index == 0 ? { minWidth: "10%" } : index == 1 ? { minWidth: '150px' } : { width: '20%' }} className={obj === "Is active ?" ? 'center' : ''} key={index}>{obj}</TableCell>; })}
							</TableRow>
							<TableRow>
								<TableCell style={{ minWidth: '120px' }}> <span><img src={filtericon} alt="delete" /></span> </TableCell>
								<TableCell style={{ minWidth: '100px' }}> <input type="text" name="" placeholder="Template Name" onChange={(e) => props.applyFilter('TemplateName', e.target.value, 'input')} /> </TableCell>
								<TableCell style={{ minWidth: '100px' }}> <input type="text" name="" placeholder="Brand" onChange={(e) => props.applyFilter('Brand', e.target.value, 'input')} /> </TableCell>
								<TableCell style={{ minWidth: '100px' }}> <input type="text" name="" placeholder="Created At" onChange={(e) => props.applyFilter('CreatedAt', e.target.value, 'input')} /> </TableCell>
								<TableCell style={{ minWidth: '100px' }}> <input type="text" name="" placeholder="Last Modified At" onChange={(e) => props.applyFilter('LastModifiedAt', e.target.value, 'input')} /> </TableCell>
								<TableCell style={{ minWidth: '100px' }}> <input type="text" name="" placeholder="Total Users" onChange={(e) => props.applyFilter('TotalUsers', e.target.value, 'input')} /> </TableCell>
								<TableCell>
									<select onChange={(e) => props.applyFilter('IsActiveLABEL', e.target.value, 'select')}>
										<option value={""}>All</option>
										<option value={"YES"}>Yes</option>
										<option value={"NO"}>No</option>
									</select>
								</TableCell>
								<TableCell style={{ minWidth: '150px' }}>
									<select onChange={(e) => props.applyFilter('IsDocFinder', e.target.value, 'select')}>
										<option value={""}>All</option>
										<option value={"true"}>Yes</option>
										<option value={"false"}>No</option>
									</select>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!!listData.length ? listData.map((obj, idnex) => {
								return (
									<TableRow key={idnex}>
										<TableCell style={{ minWidth: '100px' }}>
											<span>
												<img src={editIcon} alt="edit" style={{ cursor: 'pointer', marginRight: '5px' }}
													onClick={() => {
														props.history.push({
															pathname: '/masterTemplate/add',
															state: { EditID: obj.ID, EditName: obj.Name, EditIsActive: obj.IsActiveLABEL === "YES" ? 1 : 0, EditIsDocFinder: obj.IsDocFinder ? 1 : 0 }
														})
													}} />
												<img src={deleteIcon} alt="delete" style={{ cursor: 'pointer', marginRight: '5px' }}
													onClick={() => { setDeleteId(obj.ID); setViewDialog(false); setGeneratePageDialog(false); setIsLogsDialog(false); setOpenDialog(true); }} />
												<img src={viewIcon} alt="view" style={{ cursor: 'pointer', marginRight: '5px' }}
													onClick={() => { setHTMLContent(obj.HTMLContent); setDeleteId(obj.ID); setViewDialog(true); setGeneratePageDialog(false); setIsLogsDialog(false); setOpenDialog(true); }} />
												<img src={settingIcon} alt="setting" style={{ cursor: 'pointer', marginRight: '5px' }}
													onClick={() => { setDeleteId(obj.ID); setViewDialog(false); setGeneratePageDialog(true); setIsLogsDialog(false); setOpenDialog(true); }} />
												<img src={logFileIcon} alt="logs" style={{ cursor: 'pointer' }}
													onClick={() => { setDeleteId(obj.ID); setViewDialog(false); setGeneratePageDialog(false); setIsLogsDialog(true); setOpenDialog(true); }} />
											</span>
										</TableCell>
										<TableCell style={{ minWidth: '100px' }}> {obj.Name} </TableCell>
										<TableCell style={{ minWidth: '100px' }}> {obj.Brand} </TableCell>
										<TableCell style={{ minWidth: '100px' }}> {obj.CreatedAt} </TableCell>
										<TableCell style={{ minWidth: '100px' }}> {obj.LastModifiedAt} </TableCell>
										<TableCell style={{ minWidth: '100px' }}> {obj.TotalUsers} </TableCell>
										<TableCell className={obj.IsActiveLABEL === "YES" ? 'green' : 'red'}> <span>{obj.IsActiveLABEL === "YES" ? 'Yes' : 'No'}</span> </TableCell>
										<TableCell style={{ minWidth: '150px' }} className={obj.IsDocFinder ? 'green' : 'red'}> <span>{obj.IsDocFinder ? 'Yes' : 'No'}</span> </TableCell>
									</TableRow>
								);
							}) : <TableRow>
								<TableCell colSpan={headerList.length} className="not_found">
									{props.progress ?
										<CircularProgress /> :
										<label>Not Currently Available</label>}
								</TableCell>
							</TableRow>}
						</TableBody>
					</Table>
				</PerfectScrollbar>
			</div>
			<Notification
				isError={isError}
				message={message}
				showNotification={showNotification}
				closeNotification={handleClearNotification.bind(this)}
			/>
			{openDialog && (
				<Modal
					show={openDialog}
					handleClose={() => setOpenDialog(false)}>
					{viewDialog ?
						<div className='popup-wrap'>
							<AssetViewer
								title={"View Content"}
								HTMLContent={HTMLContent}
								closeModal={() => setOpenDialog(false)} />
							<img src={close_icon} alt='icon' className='gray-close-ic' onClick={() => setOpenDialog(false)} style={{ cursor: 'pointer' }} />
						</div>
						: generatePageDialog ?
							<ConfirmtionDialog
								title={"Generate Landing Page"}
								description={"Are you sure you want to Generate Landing Page?"}
								btnText={'Generate'}
								isLoading={isLoading}
								deletePress={() => wsGenerateLandingPagesForTemplate(DeleteId)}
								closeModal={() => setOpenDialog(false)} />
							: isLogsDialog ?
								<div className='popup-wrap' style={{ minWidth: '1040px' }}>
									<LogsPopupList
										title={"View Logs"}
										templateID={DeleteId}
										practiceID={0}
										closeModal={() => setOpenDialog(false)} />
									<img src={close_icon} alt='icon' className='gray-close-ic' onClick={() => setOpenDialog(false)} style={{ cursor: 'pointer' }} />
								</div>
								: <ConfirmtionDialog
									title={"Delete Master Template"}
									description={"Are you sure you want to delete this Master Template?"}
									isLoading={isLoading}
									deletePress={() => wsDeleteLandingPageAutomationTemplate(DeleteId)}
									closeModal={() => setOpenDialog(false)}
								/>}
				</Modal>
			)}
			<div className="table-pagination">
				<div className="pagination-info">
					<p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
				</div>
				<div className="pagination-control">
					<a href="#!" title="" className={`double-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(1)
						}}
					><img src={double_left_arrow} alt="left" /></a>
					<a href="#!" title="" className={`simple-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage - 1)
						}}
					><img src={left_arrow} alt="single_left" /></a>
					<span>Page</span>
					<input type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => handleChangeInCurrentPage(e.target.value)} />
					<span>of {page}</span>
					<a href="#!" title="" className={`simple-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage + 1)
						}}
					><img src={right_arrow} alt="right" /></a>
					<a href="#!" title="" className={`double-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(page)
						}}><img src={double_right_arrow} alt="single_right" /></a>
					<select onChange={(e) => props.handleChangeRowsPerPage(e.target.value)} defaultValue={rowsPerPage}>
						{
							rowsPerPageOptions.map((obj, index) => {
								return <option value={obj} key={index}>{obj}</option>
							})
						}
					</select>
				</div>
			</div>
		</>
	);
};

export default MasterTemplateList;
