import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import '../../home/container/DashboardStyle.css'
import 'react-daterange-picker/dist/css/react-calendar.css' // For some basic styling. (OPTIONAL)
import '../../home/container/DashboardStyle.css'
import { Line } from 'react-chartjs-2';
import scss from './components/tabbed-chart-widget/tabbed-chart-widget.module.scss';
import { Doughnut } from 'react-chartjs-2';

const legendOptions = {
    display: false
};
class Analytics extends React.Component {

    render() {
        return (
            <Paper style={{ padding: "15px", margin: '5px' }}>
                <Typography className={'title-main'} variant="subheading">{'Campaign Performance'}</Typography>

                <div style={{ marginBottom: '10px', marginTop: '10px', borderBottom: '2.5px solid #F5F7FA', width: '100%' }} />
                <div className='paid-chart'>
                    <div className='paid-chart-inner' style={this.props.Engagement.length > 2 ? { width: '70%' } : { width: '100%' }}>
                        <Line
                            height={90}
                            data={{
                                labels: this.props.AllWeeks,
                                datasets: [{
                                    label: "Display",
                                    fill: true,
                                    lineTension: 0.1,// The main line color
                                    borderColor: "#669999",
                                    backgroundColor: "rgb(102, 153, 153,0.5)",
                                    borderCapStyle: 'square',
                                    borderDash: [], // try [5, 15] for instance
                                    borderDashOffset: 0.0,
                                    borderJoinStyle: 'miter',
                                    borderWidth: '30px',
                                    data: this.props.GoogleData,
                                    spanGaps: true,
                                    pointBorderColor: "#669999",
                                    pointBackgroundColor: "#669999",
                                    pointBorderWidth: 5,
                                    pointHoverRadius: 0,
                                    pointHoverBackgroundColor: "#02ABAE",
                                    pointHoverBorderColor: "#02ABAE",
                                    pointHoverBorderWidth: 0,
                                    pointRadius: 1,
                                    pointHitRadius: 1,
                                }, {
                                    label: "Social",
                                    fill: true,
                                    lineTension: 0.1,
                                    borderColor: '#02ABAE',
                                    borderWidth: '30px',
                                    backgroundColor: "rgb(2, 171, 174,0.5)",
                                    borderCapStyle: 'square',
                                    borderDash: [],
                                    borderDashOffset: 0.0,
                                    borderJoinStyle: 'miter',
                                    data: this.props.SearchData,
                                    spanGaps: true,
                                    pointBorderColor: "#02ABAE",
                                    pointBackgroundColor: "#02ABAE",
                                    pointBorderWidth: 5,
                                    pointHoverRadius: 4,
                                    pointHoverBackgroundColor: "#02ABAE",
                                    pointHoverBorderColor: "#02ABAE",
                                    pointHoverBorderWidth: 0,
                                    pointRadius: 1,
                                    pointHitRadius: 1,
                                }]
                            }}
                            legend={legendOptions}
                            className="paid-chart-data"
                        />
                    </div>

                    {this.props.Engagement.length > 2 &&
                        <div className='paid-chart-tabbedCarts' style={{ padding: "10px", backgroundColor: '#F5F7FA' }}>
                            <Typography className={'title-main'} variant="subheading" >{'Top Campaign Performance'}</Typography>
                            <div className={scss['portal-doughnut-widget']}  >
                                <div className={scss['portal-doughnut-widget__chart']} style={{ paddingTop: '10px', borderTop: '2.5px solid rgb(239 241 244)', marginTop: '10px' }}  >
                                    <Doughnut
                                        height={200}
                                        data={{
                                            labels: ['Facebook          ', 'Display        '],
                                            datasets: [{
                                                data: this.props.Engagement.slice(1),
                                                borderWidth: 0,
                                                backgroundColor: ['#1073C6', '#02ABAE', '#6EC496', '#0A5C7F'],
                                                hoverBackgroundColor: '#CCCCCC'
                                            }],
                                            doughnutChartOptions: {
                                                responsive: true,
                                                maintainAspectRatio: true,
                                                tooltips: {
                                                    enabled: true,
                                                    backgroundColor: '#1588ba',
                                                    titleFontColor: '#fff',
                                                    bodyFontColor: '#fff',
                                                    xPadding: 20,
                                                    yPadding: 20,
                                                    displayColors: false
                                                }
                                            }
                                        }}
                                        options={{
                                            animateRotate: true,
                                            animateScale: false,
                                            animation: { duration: 2000 },
                                            legend: {
                                                position: "bottom", align: "center",
                                                labels: { boxWidth: 30, fontSize: 12, fontColor: '#646464' }
                                            },
                                            datalabels: { display: true, color: "white" },
                                            tooltips: { backgroundColor: "#5a6e7f" }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>}
                </div>
            </Paper>
        );
    };
}

export default Analytics;

