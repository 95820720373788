import React from 'react'
import '../../component/bioTrue.css'
import { ExcelRenderer } from 'react-excel-renderer';
import * as APIController from '../../../../api/APIController';
import { Grid, Divider } from '@material-ui/core';
import Notification from '../../../../shared/components/notification/notification';
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";
import PageTitle from "../../../../config/pageTitle";
import * as AppConstants from "../../../../config/AppConstant";

const DirectMail = (props) => {
    const [IsLoading, setIsLoading] = React.useState(true);
    const [fileName, setFileName] = React.useState("");
    const [list, setList] = React.useState(0);
    const [listCost, setListCost] = React.useState([]);
    const [subTotal, setSubTotal] = React.useState(0);
    const [tax, setTax] = React.useState(0);
    const [totalCost, setTotalCost] = React.useState('$0');
    const [CampaignDirectMailAssets, setCampaignDirectMailAssets] = React.useState([]);

    React.useEffect(() => {
        wsGetPostageCharges()
    }, [])

    const wsGetPostageCharges = () => {
        APIController.GetPostageCharges()
            .then((response) => {
                if (response.error == null) {
                    setListCost(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.object.message, true);
                }
                wsGetCampaignDirectMailAssets();
            })
    }

    const wsCalculatePostageCharges = (TotalAddresses) => {
        APIController.CalculatePostageCharges(TotalAddresses > 0 ? TotalAddresses - 1 : TotalAddresses)
            .then((response) => {
                if (response.status != "0" && response.object.data[0].status == 1) {
                    setSubTotal(response.object.data[0].SubTotal)
                    setTax(response.object.data[0].Tax)
                    setTotalCost(response.object.data[0].TotalCost)
                }
                else if (response.object.message && response.object.message.length > 0) {
                    handleNotification(response.object.message, true);
                    setTotalCost("$" + 0)
                }
            })
    }

    const wsGetCampaignDirectMailAssets = () => {
        APIController.GetCampaignDirectMailAssets()
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    setCampaignDirectMailAssets(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const fileHandler = (event) => {
        let fileObj = event.target.files[0];
        setFileName(fileObj.name)
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
            var obj = {};
            var mainobj = []

            for (var i = 1; i < resp.rows.length; i++) {
                for (var j = 0; j < resp.cols.length; j++) {
                    obj[resp.rows[0][j]] = resp.rows[i][j];
                }
                mainobj.push(obj);
                obj = {};
            }
            setList(resp.rows.length)
            wsCalculatePostageCharges(resp.rows.length)
        });
    }
    const deletefileHandler = (event) => {
        setFileName("")
        setList(0)
        setSubTotal(0)
        setTax(0)
        setTotalCost('$0')
    }
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nav: false,
        arrows: false,
        autoplay: true,
        customPaging: i => (
            <div style={{ height: 10, width: 10, borderRadius: 50, backgroundColor: '#3232' }} />
        )
    };

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }
    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    const wsAddEditCart = (Budget) => {
        setIsLoading(true)
        setTotalCost('$0')
        var data = JSON.parse(AppConstants.GetLocalStorage('localStoragePracticeIDs'));
        var PracticeIDs = data.join(",");
        // OrderDetailsID, Budget, CampaignDurationInMonths,
        //  CampaignStartDate, CampaignEndDate, 
        //  CampaignID, ProductID, BrandID,
        //  CampaignThemeID, PracticeIDs, CampaignReach, MonthlyBudget
        APIController.AddEditCart(AppConstants.GetLocalStorage('localStorageOrderDetailsID'), Budget, '1', '', '',
            AppConstants.GetLocalStorage('localStorageCampaignID'), AppConstants.GetLocalStorage('localStorageProductID'),
            AppConstants.GetLocalStorage('localStorageBrandID'), AppConstants.GetLocalStorage('localStorageThemesID'),
            PracticeIDs, JSON.parse(AppConstants.GetLocalStorage('localStorageCampaignReach')), '')
            .then((response) => {
                setIsLoading(false)
                if (response.object.status == 1) {
                    props.history.push('/cart')
                    handleNotification(response.object.message, true);
                    handleFirebaseEvents()
                } else {
                    handleNotification(response.object.message, true);
                }
            })
    }

    const handleFirebaseEvents = () => {
        var locationName = [];
        if (AppConstants.GetLocalStorage('localStorageCampaignReach')) {
            JSON.parse(AppConstants.GetLocalStorage('localStorageCampaignReach')).map((obj, index) => {
                return (locationName.push(obj.Location))
            })
        }
        AppConstants.LogEvent('Add To Cart',
            AppConstants.CreateLogEventObject("",
                AppConstants.GetLocalStorage('localStorageBrandName'),
                AppConstants.GetLocalStorage('localStorageCampaignName'),
                AppConstants.GetLocalStorage('localStorageProductName'),
                locationName.toString(),
                AppConstants.GetLocalStorage('localStorageThemesName'), "", "", "", "", "", ""))
    }

    return (
        <>
            <section className="content-right-main">
                <PageTitle Title={AppConstants.ContactDetais.Step2Upload} />
                <div className='two-col-sec digital-welcome' style={{ paddingLeft: '30px' }}>
                    {IsLoading ?
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <CircularProgress />
                        </div>
                        : <>
                            <div className="sec-left">
                                <div style={{ width: '75%' }}>
                                    <a href='/Sample Direct Mail List.csv' download>Download a Sample CSV File</a>
                                    <Grid container direction='row' justify="space-between" style={{ marginBottom: '10px', border: '1px solid #6d6e71', borderRadius: '5px', padding: '10px 5px' }} >
                                        <label className='DirectMail-file-label'>{fileName != "" ? fileName : "Upload CSV Mailing List"}</label>
                                    </Grid>
                                    <div className={totalCost != "$0" ? "button-field color-disable" : "button-field file-upload-btn"} style={{ textAlign: 'center', minWidth: '155px' }}>
                                        <input type="file" id='fileupload' name='files' accept=".csv" onChange={fileHandler.bind(this)} />
                                        <label>{AppConstants.ButtonText.Upload}</label>
                                    </div>
                                    <div className={totalCost == "$0" ? "disable-btn button-field" : "button-field"} style={{ marginLeft: "1%", minWidth: '155px' }}>
                                        <input type="button" value={AppConstants.ButtonText.Delete} name="delete" onClick={() => deletefileHandler()} />
                                    </div>

                                    <Divider style={{ marginTop: '10px', marginBottom: '15px' }} />
                                    <Grid container direction='row' justify="space-between" >
                                        <label># of Addresses</label>
                                        <label>{list == 0 ? '0' : list - 1}</label>
                                    </Grid>

                                    {!!listCost.length &&
                                        <>
                                            <Divider style={{ marginTop: '15px', marginBottom: '20px' }} />
                                            <label>Cost with Postage:</label>
                                            <div style={{ display: 'grid', marginTop: '15px', gridTemplateColumns: '65% 35%', border: '1px solid' }}>
                                                <div>
                                                    <label style={{ padding: '5px' }}>Quantity</label>
                                                    {listCost.map((obj, index) => {
                                                        return (
                                                            <div style={{ borderTop: '1px solid #6d6e71' }}>
                                                                <label style={{ padding: '4px' }}>{obj.FromCount}-{obj.ToCount}</label>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                <div style={{ borderLeft: '1px solid #6d6e71', textAlign: 'end' }}>
                                                    <label style={{ padding: '5px' }}>Price Per Piece</label>
                                                    {listCost.map((obj, index) => {
                                                        return (
                                                            <div style={{ borderTop: '1px solid #6d6e71' }}>
                                                                <label style={{ padding: '4px' }}>{obj.Cost}</label>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </>}


                                    <Divider style={{ marginTop: '15px', marginBottom: '10px' }} />
                                    <Grid container direction='row' justify="space-between">
                                        <label style={{ fontSize: '25px', color: '#000', width: '50%', textAlign: 'end' }}>Total Cost</label>
                                        <label style={{ fontSize: '25px', color: '#000', width: '50%', textAlign: 'end' }}>{totalCost}</label>
                                    </Grid>

                                </div>
                            </div>
                            <div className="sec-right" style={{ padding: '0px', margin: '0px', zIndex: '0' }}>
                                {/* <div style={{ height: "100%", }}>
                                    <Slider {...settings}>
                                        {CampaignDirectMailAssets.map((obj, index) => {
                                            return (
                                                <MyiFrame HTMLContent={obj.HTMLContent} />
                                            )
                                        })}
                                    </Slider>
                                </div> */}
                            </div>
                        </>
                    }
                </div>

                <Grid container flexDirection='row' justify='space-between' style={{ marginTop: '10px' }}>
                    <div className="button-field">
                        <input type="button" value={AppConstants.ButtonText.Back} name="Back" onClick={() => props.history.goBack()} />
                    </div>
                    <div className={totalCost == "$0" ? "disable-btn button-field" : "button-field"}>
                        {/* {AppConstants.GetMainClassName() == "app-main" ?
                            <input type="button" value={AppConstants.ButtonText.Continue} name="Next"
                                onClick={() => {
                                    props.history.push('/digital-product/selection/location-selection')
                                    AppConstants.SetLocalStorage("localStorageBudgetDetail", JSON.stringify([{ budget: totalCost.substring(1), duration: '1' }]))
                                }}
                            /> :  */}
                        <input type="button" value={AppConstants.ButtonText.AddToCart} name="Next"
                            onClick={() =>
                                wsAddEditCart(parseInt(totalCost.replace("$", '')))
                            } />
                        {/* } */}
                    </div>
                </Grid>
                <Notification
                    isError={isError}
                    message={message}
                    showNotification={showNotification}
                    clearNotification={handleClearNotification.bind(this)}
                    closeNotification={handleClearNotification.bind(this)}
                />
            </section>
        </>
    )
}

export default DirectMail